.questionnaire-v3 {
  $color01: #636363;
  $color1: #EDC55C;
  $color2: #0F1994;
  $color3: #1ECD3A;
  $color4: #1FCCF2;
  $color5: #f85c2a;
  $color6: #1FCCF2;
  $color7: #DF2C2C;
  $color8: #0157FF;
  $color9: #1ED1BC;
  $color10: #148325;
  $color11: #a2d0f9;
  $color12: #946FFF;
  $color13: #9b9b9b;

  $black-color: #000;
  $white-color: #fff;



  $font_family_1: "Ubuntu Sans", sans-serif !important;



  $font-bold: bold;
  $font-normal: normal;
  $font-w500: 500;
  $font-w600: 600;
  $font-w700: 700;

  // ======border===========

  $border-style1: solid 1px #fff;
  $border-style2: 1px solid #d3d3d3;
  $border-style3: 5px solid #011a70;

  $border-style4: 3px solid #057599;
  $border-style5: 2px #1483ce solid;

  $border-color1: #d3d3d3;
  $border-color2: #dedcdc;
  $border-color3: #000;

  font-family: "Ubuntu Sans", sans-serif !important;
  color: #000;
  margin-top: 0px !important;

  .wraper-option {
    font-size: 1.2rem;

    .option-btn {
      padding: 10px 10px;
      // background: $color3;
      border-radius: 10px;
      border: 1px solid $color01;
      padding-left: 20px;
      cursor: pointer;
      font-weight: bold;
      position: relative;

      .label-class{
        position: absolute;
        left: 0;
        width: 100%;
        display: block;
        top: 0;
        padding-left: 45px;
        padding-top: 13px;
        cursor: pointer;
        
        .btn-opacity{
          opacity: 0;
          
        }

        @media screen and (max-width: 470px) {
          font-size: 14px;
          height: 50px;
        }
      }

      &.current {
        background: $color1;
        border: 1px solid $color1;

        .check {
          border: 1px solid $color1;
          background: $black-color url(../../PCP_HDSC_V3/img/tick03.png) no-repeat center 6px;
          background-size: 12px;

        }
      }

      .check {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: inline-block;
        border: 1px solid $color01;
        margin-right: 10px;
        position: relative;
        top: 5px;

      }
    }
  }


  .hender-quest-wraper {
    min-height: 500px;

    .title {
      font-size: 22px;
    }

    .list-of-optins {
      .option-btn {


        @media screen and (max-width: 470px) {
          font-size: 16px;
        }
      }
    }

    .backBtn1 {
      background: #373636;

      span {
        color: $white-color;
        font-weight: 400;
      }

      &::after {
        background: #000;
      }
    }
  }

  .textmsg {
    font-size: 1.2rem;
    min-height: 66px;
    max-width: 276px;
    margin: 0 auto;
  }

  .pop-close {
    width: 200px;
    background: $color1;

  }

  .btn-quest{
    background-color: #6c757d;
  }
  .background-clr{
    background-color: #00000069;
  }
  .qsslide4{
    padding-bottom: 60px;

  }
  .show{
    display: block;
  }
  .hide{
    display: none;
  }

  .modal-quest{
    background: #ffffff !important;
  }

}