
.carregistration {

  .error {
    color: red;
    background-color: rgb(255, 176, 176);
    border-radius: 5px;
    padding: 2px 5px;
  }

  .material-textfield {
    position: relative;
    margin-top: 20px;
    margin-bottom: 7px;

    @media (max-width: 780px) {
      // text-align: center;
    }

    @media (max-width: 510px) {
      margin-top: 29px;
    }

    &:before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 8px;
      color: #0A0A0A;
      background: #0267C1 url(../img/gb.svg) no-repeat center center;
      -webkit-background-size: 15px 25px;
      -moz-background-size: 15px 25px;
      -o-background-size: 15px 25px;
      background-size: 15px 25px;
      border: 2px solid #FFFFFF;
      -webkit-box-shadow: 0px 0px 0px 2px #0267C1;
      -moz-box-shadow: 0px 0px 0px 2px #0267C1;
      box-shadow: 0px 0px 0px 2px #0267C1;
      width: 30px;
      height: 40px;
      content: '';
      z-index: 3;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
    }

    label {
      position: absolute;
      font-size: 16px;
      font-weight: 800;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      // background-color: white;
      color: #000000 !important;
      padding: 3px 9px 3px 44px;
      margin: 0 0.5rem;
      transition: .1s ease-out;
      transform-origin: left top;
      pointer-events: none;
      border-radius: 5px;
    }

    //label
    input {
      font-size: 19px;
      outline: none;
      border: 2px solid #afafaf;
      border-radius: 5px;
      width: 100%;
      height: 58px;
      transition: 0.1s ease-out;
      padding: 0 45px;
      text-transform: uppercase;
      font-weight: 700;
      background: #fff41f;

      &:focus {
        border-color: #afafaf;
      }

      &:focus+label {
        color: #ffffff !important;
        top: -11px;
        transform: translateY(-50%) scale(0.9);
        padding: 11px 9px 13px 9px;
        margin-left: 45px;
        font-weight: 700;
        font-size: 15px;
      }

      &:not(:placeholder-shown)+label {
        top: -11px;
        transform: translateY(-50%) scale(.9);
        padding: 3px 9px 3px 9px;
        margin-left: 45px;
        color: #fff !important;
      }

      @media (max-width: 510px) {
        width: 100%;
      }
    }

    //input
  }

  //material-textfield

  .br-30 {
    border-radius: 30px !important;
  }

  h3.carreg {
    font-weight: 600;
    text-align: center;
    font-size: 26px;
    line-height: 145%;
    color: #ffffff;
    margin: 30px 0;
  }

  .custom_height {
    height: auto !important;
    padding-bottom: 45px;
    min-height: inherit;
  }

  // .tnk_br{
  //     border-bottom: solid 1px #333533;
  // }

  .loader-wrap {
    background: url(../img/Graphic2.png) no-repeat center center;
    background-size: cover;
    height: 100vh;
    border-bottom: solid 1px #333533;
  }

  .autoh {
    height: auto !important;
  }

  .whathapns{
    color: #fff;
    p{
      margin-bottom: 0px;
      padding-bottom:5px;
      font-size: 15px;
    }
    h4{
      font-weight: 600;
      font-size: 22px;
      margin: 10px 0 20px;
    }
    .steps_col{
        padding: 15px;
        background: url(../img/steps_bg.png) no-repeat center center;
        background-size: cover;
        min-height: 200px;
        border-radius: 10px;
        display: flex !important;
        align-content: center;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center !important;
        @media (max-width: 780px) {
            min-height:160px;
            margin-bottom: 20px;
        }
        .steps{
            background-color: #000;
            border: solid 2px #f5cb5c;
            border-radius: 100%;
            width: 40px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            color: #f5cb5c;
            font-weight: bold;
            display: inline-block;
            margin-right: 10px;
        }
        p{
            font-size: 17px;
        }
    }

  }

  .display-gold-box {
    border: 1px solid #F5CB5C;
    margin-top: 10px;
    border-radius: 6px;
    padding: 15px 15px;

    .lender-text {
      margin-bottom: 0px;
      font-weight: bold;
      color: #F5CB5C;
      font-size: 17px;
    }
  }

  .first_fnt2 {
    // font-size: .10rem;
    margin-bottom: 23px;

    @media (max-width: 576px) {
      font-size: 15px !important;
      color: #ffffff !important;
    }
  }

  .modal {


    .modal-content {
      display: flex;
      border-radius: 20px 20px 20px 20px;
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
      padding: 10px;
      background: #F5CB5C;




      .modal-body {
        padding: 10px 0;


        .enter_postcode_mod {
          padding-top: 10px;
          text-align: start;

          .png_t {
            font-size: 19px;
            font-weight: 600;
          }

          label {
            font-size: 12px;
            font-weight: 700;
          }

          //label
        }

        //enter_postcode_mod
        @media (max-width: 576px) {
          .enter_postcode_mod {
            .postcode_wrap {
              display: flex !important;
              flex-wrap: wrap;
              width: 100% !important;

              .form-control {
                border-radius: 5px;
                margin-bottom: 15px;
                width: 100% !important;
              }

              //form-control

              .button {
                width: 100% !important;
                border-radius: 5px;
              }

              //button
            }

            //postcode_wrap
          }

          //enter_postcode_add
        }

        .form_drop {
          .png_t {
            font-size: 19px;
            font-weight: 600;
          }

          .choose_Y_N {
            display: flex;
            width: 100%;
            gap: 8px;
            flex-wrap: wrap;

            // margin-bottom: 30px;
            @media (max-width: 576px) {
              flex-direction: column;
              gap: 15px;
            }

            /////////////////////////// form check /////////////////////////////////
            .form-check {

              // width: 50%;
              @media (max-width: 576px) {
                width: 100% !important;
              }

              [type="radio"] {
                display: none;
              }

              // & + .form-check {
              // 	width: 50%;
              // }
              label {
                display: block;
                padding: 13px 50px;
                background: #fff;
                border: 1px solid #a3a3a3;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                border-radius: 5px;
                cursor: pointer;
                font-size: 16px;
                font-weight: 500;
                min-width: 195px;
                color: #333533;
                white-space: nowrap;
                position: relative;
                transition: 0.4s ease-in-out 0s;

                &:after {
                  height: 19px;
                  width: 19px;
                  border: 1px solid #F5CB5C;
                  left: 19px;
                  top: calc(50% - 10px);
                }

                &:before {
                  background: #F5CB5C;
                  height: 11px;
                  width: 11px;
                  left: 23px;
                  top: calc(50% - 6px);
                  transform: scale(5);
                  opacity: 0;
                  visibility: hidden;
                  transition: 0.4s ease-in-out 0s;
                }
              }

              [type="radio"]:checked {
                &~label {
                  border-color: #02575f;
                  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                  background: #F5CB5C;
                  color: #ffffff;

                  &::before {
                    background: #fff;
                    opacity: 1;
                    visibility: visible;
                    transform: scale(1);
                  }

                  &::after {
                    border: 2px solid #fff;
                  }
                }
              }
            }

            .form-check label:after,
            .form-check label:before {
              content: "";
              position: absolute;
              border-radius: 50%;
            }

            ///////////////////////// form check /////////////////////////////////
          }

          //choose_bw

          .sty_pop {
            font-size: 18px;
            font-weight: 600;
          }

        }

        //form_drop

      }

      .modal-footer {
        display: block;
        flex-shrink: 0;
        flex-wrap: wrap;
        align-items: center;
        justify-content: start;
        padding: 0;
        border-top: 0;

        .box_button {
          @media (max-width: 576px) {
            // padding: 10px 30px;
          }

          .wb_5 {
            width: 50% !important;

            @media (max-width: 576px) {
              width: 100% !important;
            }
          }

          .yes_btn {
            width: 70% !important;

            @media (max-width: 576px) {
              width: 100% !important;
            }
          }

          .button {

            display: inline-block;
            background: #10a516;
            color: #fff;
            padding: 15px 30px;
            border-radius: 50px;
            border: 0;
            font-size: 24px;
            width: 50%;
            font-weight: 600;
            box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.164);
            cursor: pointer;

            // transition: all ease-in-out 300ms;
            //animation: move5 3s infinite linear;
            @media(min-width: 1200px) {
              width: 45%;
            }

            @media (min-width: 980px) {
              width: 50%;
            }

            @media (max-width: 780px) {
              width: 40%;
            }

            @media (max-width: 576px) {
              width: 100%;
              font-size: 20px;
              background-size: 42% 45px !important;
            }

            &:hover {
              box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.144);
            }
          }

          //button
          @keyframes move5 {
            0% {
              -webkit-transform: translateY(0);
              transform: translateY(0)
            }

            50% {
              -webkit-transform: translateY(-20px);
              transform: translateY(-20px)
            }

            100% {
              -webkit-transform: translateY(0);
              transform: translateY(0)
            }
          }
        }

        //box_button
        @keyframes move5 {
          0% {
            -webkit-transform: translateY(0);
            transform: translateY(0)
          }

          50% {
            -webkit-transform: translateY(-20px);
            transform: translateY(-20px)
          }

          100% {
            -webkit-transform: translateY(0);
            transform: translateY(0)
          }
        }
      }
    }

  }
}

@media screen and (max-width: 767px) {}