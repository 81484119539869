/*
*******************************************
Template Name: PCP claim Website
Author: Anush
Date:19-04-2024
Template By: Vandalay Designs
Copyright 2021-2022 Vandalay Business Solution

* This file contains the styling for the Website, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY
04 - HEADER
05 - Questionnaie

08 - FOOTER
09 - MODAL
10 - MEDIA QUERY
11 - ANIMATION
============================================
============================================
*/

$background_color_1:#ffffff;
$background_color_2:#F5CB5C;
$background_color_3:#F5CB5C;
$background_color_4:#F5CB5C;
$background_color_5:#F5CB5C;
$background_color_6:#f9fbfc;
$background_color_7:#efefef;
$background_color_8:#f2f4f5;
$background_color_9:#cfcfcf;

$color_1:#F5CB5C;
$color_2:#F5CB5C;
$color_3: #ffff;
$color_4:#F5CB5C;
$color_5:#272727;
$font-normal:normal;


// ======border===========
$box-shadow1: 0 0 0 0.2rem #ffe95c9d;


$border-style2:4px solid #23df6a;
$border-style3:2px solid #d5d5d5;
$border-style4:1px solid #b4b4b4;
$border-style5:1px solid #2f3996;


/*================================================
	BODY STYLES
==================================================*/
*, ::after, ::before {
    box-sizing: border-box;
}

*{
	outline: none;
}

html {
	scroll-behavior: smooth;
}
a{
	cursor: pointer;
	text-decoration: none !important;
	color: unset !important;
}
a.link-style{
	text-decoration: underline !important;
}

em {
    font-style: normal;
}


body {
	margin: 0;
	padding: 0;
	min-height: 100vh;
}
.cookie2{ 
	background-color: black; 
	color: white; 
	padding: 12px;
	position: fixed;
    bottom: 0;
	z-index: 100;
	font-size: 12px;
  width: 101% !important;
  margin-left: -10px !important;
	}

	@media screen and (max-width:500px) {
	.cookie2{ 
		font-size: 10px;
	}
	}
.pcp_siteHome{
	// &:not(.pcp_siteHome_v1){
	&.v22{
		@media (max-width: 768px) {
				.lenders-page {
					#nextslideCl_002 {
						position: fixed;
						bottom: 0;
						right: 0;
						width: 70% !important;
						border-radius: 0;
						z-index: 999;
					}
					#nextslideCl_002:hover {
						transform: none !important;
					}

					.backbutton {
						position: fixed;
						left: 0;
						bottom: 0;
						background: #000 !important;
						width: 30%;
						padding: 14px;
						z-index: 9999;
					}
				}
			}
		.error-msg{
			color: red;
			background-color: rgb(255, 169, 169);
			padding-top: 5px;
			padding-bottom: 5px;
			border-radius: 5px;
			font-weight: 600;
			border: 1px solid rgb(255, 111, 111);
		}

		fieldset.scheduler-border {
			border-radius: 5px;
			border: 1px groove #f5cb5c !important;
			padding: 0 20px 5px 20px !important;
			margin: 0 0 20px 0 !important;
	}

	legend {
			margin-bottom: 0px;
			font-size: 16px !important;
			text-align: left !important;
			width: auto !important;
			float: none;
			padding: 0 10px;
			color:#f5cb5c;
			font-weight: 600;
	}

	.css-1lymv0m-control{
    background: #000 !important;
    border: solid 1px #f5cb5c !important;
    color: #f5cb5c !important;
    box-shadow: none !important;
}
.css-2b097c-container{
	color: black;
}
.css-2613qy-menu{
	z-index: 1;
	position: relative;
}
.css-1okebmr-indicatorSeparator{
    background-color: #f5cb5c !important;
}

@media only screen and (max-width:2400px) {
	.css-2613qy-menu{
		z-index: 9999 !important;
		position: relative !important;
	}
}
	}

	font-family: 'Poppins', sans-serif;

	// @media (min-width: 576px) {
	// 	.container{
	// 		max-width: 100% !important;
	// 	}
	// }

	// pre loader design
	#preloader {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgb(31, 31, 31);
		z-index: 999999;
		#loader {
			display: block;
			position: relative;
			left: 50%;
			top: 50%;
			width: 150px;
			height: 150px;
			margin: -75px 0 0 -75px;
			border-radius: 50%;
			border: 3px solid transparent;
			border-top-color: #F5CB5C;
			-webkit-animation: spin 2s linear infinite;
			animation: spin 2s linear infinite;
			&:before {
				content: "";
				position: absolute;
				top: 5px;
				left: 5px;
				right: 5px;
				bottom: 5px;
				border-radius: 50%;
				border: 3px solid transparent;
				border-top-color: #505050;
				-webkit-animation: spin 3s linear infinite;
				animation: spin 3s linear infinite;
			}
			&:after {
				content: "";
				position: absolute;
				top: 15px;
				left: 15px;
				right: 15px;
				bottom: 15px;
				border-radius: 50%;
				border: 3px solid transparent;
				border-top-color: #F5CB5C;
				-webkit-animation: spin 1.5s linear infinite;
				animation: spin 1.5s linear infinite;
			}
		}//loader
		@keyframes spin {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}//@keyframes spin
	}//preloader

	// sticky banner design
	.sticky_banner {
		.container-fluid{
			padding: 0;
			.smooth_bu {
				position: relative;
				transform: translateY(0);
				transition: transform 0.5s ease-in-out; /* Updated transition */
			}//smooth_bu
			.pos_stick {
				position: fixed;
				bottom: 0;
				left: 0;
				right: 0;
				z-index: 4;
				transition: transform 0.5s ease-in-out; /* Updated transition */
			}//pos_stick
			.news_ticker {
				background: #242423;
				color: #fff;
				overflow: hidden;
				height: 50px;
				display: flex;
				align-items: center;
				@media (max-width: 576px) {
					height: 50px;
				}
				.ticker_content {
					display: flex;
					.ligo {
						display: none;
						margin-right: 20px;
						.GuardianLogo {
							max-width: 130px;
							background:#242423;
							position: relative;
							z-index: 4;
							padding:5px;
							@media (max-width: 576px) {
								max-width: 70px;
							}
						}//GuardianLogo
						.bbcLogo {
							max-width: 134px;
							background:#242423;
							position: relative;
							z-index: 4;
							padding: 5px;
							@media (max-width: 576px) {
								max-width: 80px;
							}
						}//bbcLogo
						.telegraphLogo {
							max-width: 169px;
							background:#242423;
							position: relative;
							z-index: 4;
							padding: 5px;
							@media (max-width: 576px) {
								max-width: 100px;
							}
						}//telegraphLogo

						.contenty {
							white-space: nowrap; /* Ensures content stays in a single line */
							overflow: hidden;    /* Hides any overflowing content */
							text-overflow: ellipsis; /* Adds ellipsis (...) if content is too long */
							animation: ticker-animation 8s linear infinite;
						}//contenty

					}//ligo
					.ligo.active {
						display: flex;
						align-items: center;
						justify-content: space-between;
						width: 100%;
					}//.ligo.active
					@keyframes ticker-animation {
						from {
						  transform: translateX(100%);
						}
						to {
						  transform: translateX(-100%);
						}
					}
				}//ticker_content
			}//news_ticker
		}//container-fluid
	}//sticky_banner

	//header design
	header{
		position: fixed;
		width: 100%;
		z-index: 999;
		transition: .4s all;
		background: #000;
		box-shadow: 0 6px 16px -6px rgba(1, 1, 1, .7);
		@media screen and (max-width: 992px) {
			padding-top: 15px;
			padding-bottom: 5px;
		}

		.navbar{
			justify-content: space-between;
			@media screen and (max-width: 576px) {
				justify-content: space-between;
				display: flex;
				align-content: center;
				align-items: center;
				padding: 0 10px;
			}
			@media screen and (max-width: 992px) {
				padding: 0 20px;
			}
			.navbar-brand{
				img{
					max-height: 30px;
					@media screen and (max-width: 576px) {

					}
				}
			}//navbar-brand

			.hero_image_badge{
				text-align: center;
				p{
					margin-bottom: 3px;
					font-size: 18px;
					color: #fff;
					font-weight: 600;
					@media screen and (max-width: 576px) {
						font-size: 16px;
					}
				}
				img{
					max-width: 100px;
					@media screen and (max-width: 576px) {
						width: 100px;
					}
				}
			}//hero_image_badge
		}//navbar
	}//header

	.PCP_HDSC_V2_header{
		.topnav {
			overflow: hidden;
			background-color: #000000;
			width:100%;
			margin:auto;
			box-shadow:0px 6px 16px -6px rgba(1,1,1,0.7);
			transition:0.5s;
			// border-radius: 5px;
			// padding: 5px 10px;
			// padding-top: 30px !important;

			// @media (max-width: 957px) {
			// 	padding-top: 35px !important;
			// }
			// @media (max-width: 499px) {
			// 	padding-top: 50px !important;
			// }
			// @media (max-width: 355px) {
			// 	padding-top: 50px !important;
			// }
		}
	}

	.main_content{
		position: relative;
		padding:130px 0 70px;
		//background: url("../img/bg-dark.png") #2C2C2C no-repeat  top center;
		background:#2C2C2C url("../img/pattern-2-curve.png")  center ;
		background-size: cover;

		 .secured {
			img {
				@media screen and (max-width: 520px) {
					height: 25px;
				}
			}

		 }

		// min-height: 100vh;
		@media screen and (max-width: 992px) {
			text-align: center;
			padding-top: 90px;

		}
		@media screen and (max-width: 520px) {
			background:#2C2C2C  url("../img/bg-dark.png") no-repeat  top center !important  ;

		}
		.container{
			// position: relative;
			// overflow: hidden;
		}
		.top_dec{
			position: relative; /* Add position relative */
			z-index: 1; /* Ensure it's above other elements */
			// min-height: 85vh;
			.banner_text {
				margin-top: 10px;
				@media screen and (max-width: 1300px) {

				}
				@media screen and (max-width: 992px) {
					margin-top: 0;
					padding: 0 50px;
				}
				@media screen and (max-width: 767px) {
					/* margin-top: -30px; */
					padding: 0;
				}
				@media screen and (max-width: 576px) {
					padding: 0 0px;
				}
				h1 {
					font-size: 3.5rem;
					color: #F5CB5C;
					font-weight: 600;
					margin-bottom: 13px;
					font-family: "Playfair Display", serif;
					@media screen and (max-width: 1300px) {
						font-size: 36px;
					}
					@media screen and (max-width: 992px) {
						font-size: 32px;
					}
					@media screen and (max-width: 767px) {
						font-size: 32px;
					}
					@media screen and (max-width: 576px) {
						font-size:  2rem;
						line-height: 40px;
					}
				}//h1
				.sub_bbTXT{
					font-size: 16px;
					color: #ffffff;
					padding-bottom: 15px;
					line-height: 26px;
					span {
						color: $color_1;
					}
					@media screen and (max-width: 576px) {

						font-size: 17px;
						font-weight: 400;
						margin: auto;
					}
				}//sub_bbTXT
			}//banner_text


			.cardTextWrap{
				margin: 0 0 20px 0;
				// border: 2px solid #ffffff;
				border-radius: 18px;
				.legend_top {
					padding: 4px  0px;
					color: #ffffff;
					margin: 0 auto;

					font-size: 16px;
					font-weight: 300;
					align-items: center;
					justify-content: space-between;
					span {
						color: #F5CB5C;
					}
					hr{
						width: 28%;
						display: flex;
						height: 3px;
						background: #fff;
						z-index: 2;
						position: relative;
					}
				}//legend_top
				.card_stack{
					justify-content: space-evenly;
					padding: 10px 0px 18px;
					.card{


						align-items: center;
						border-radius:10px;
						border: none;
						text-align: center;
						justify-content: center;

						background: rgb(0,0,0);
background: linear-gradient(0deg, rgba(0,0,0,0) 5%, rgba(245,203,92,0.9473039215686274) 100%);
						.img_1spec, .img_2spec, .img_3spec{
							@media screen and (max-width: 576px) {
								width: 70px;
							}
						}

						h3{
							font-size: 24px;
							font-weight: bold;
							text-align: center;
							color: #ffffff;
							padding-top: 3px;
							padding-bottom: 0;
							margin-bottom: 0;
							@media screen and (max-width: 576px) {
								font-size: 1.3rem;
							}
							em{
								font-size: 12px;
								@media screen and (max-width: 576px) {
									font-size: 9px;
								}
							}
						}//h3
						p{
							margin: 0;

							font-size: 16px;
							color: #ffffff;
							font-weight: 400;
						}//p
					}//card
				}//card_stack
			}//cardTextWrap


		}//top_dec

		.form_dec{
			padding: 0;

			z-index: 2;
			transition: transform 0.5s ease-in-out; /* Add smooth transition */
				.pos_stick {
					right: 0;
					z-index: 4;
					transition: transform 0.5s ease-in-out; /* Updated transition */
				  }
				  .smooth_bu {
					position: relative;
					transition: transform 0.5s ease-in-out; /* Updated transition */
				  }
				.widget_mobi{

					 &.borderx{
					border: 2px solid #f5cb5c;
					border-radius: 30px;
					border-radius: 10px;

					@media (max-width: 576px) {
						margin:0 10px 3rem 10px;
					}
						}

					.slide_1Dec, .slide_2Dec, .slide_3Dec, .slide_4Dec, .slide_5Dec, .slide_6Dec{
						.first_fnt{
							@media (max-width: 576px) {
								font-size:.8rem;
								margin-bottom: 23px;
								color: #B0AEAB;
							}
						}
						.img_secure{
							img{
								height: 40px;
								padding-top: 10px;
							}
						}
						h2{
							font-size: 1rem;
							font-weight: 400;
							line-height: 130%;
							color: #ffffff;
							margin-bottom: 20px;
							padding: 0 5px;
							@media (max-width: 576px) {
								font-size: 19px;
							}
						}//h2

						.sub_quesHead{
							font-size: 20px;
							font-weight: 600;
							line-height: 130%;
							color: #ffffff;
							margin-bottom: 20px;
							@media (max-width: 576px) {
								font-size: 20px;
								margin-bottom: 15px;
							}
						}//sub_quesHead
						.box_button {
							@media (max-width: 510px) {
								// padding: 10px 30px;
							}

							.button {
								background-image: url(../img/right-chevron.svg) !important;
								background-size: 17% 42px !important;
								background-repeat: no-repeat !important;
								background-position: right !important;
								display: inline-block;
								background: #10a516;
								color: #fff;
								padding: 16px 11px;
								border-radius: 76px;
								border: 0;
								font-size: 20px;
								width: 50%;
								font-weight: 600;
								box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.4);
								cursor: pointer;
								transition: all ease-in-out 300ms;
								@media(min-width: 1200px){
									width: 45%;
								}
								@media (min-width: 980px) {
									width: 70%;
								}
								@media (max-width: 780px) {
									width: 60%;
								}
								@media (max-width: 510px) {
									width: 100%;
								}
								&:hover {
									box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
									transform: translate(0px, -10px) scale(1.1);
									-webkit-transform: translate(0px, -10px) scale(1.1);
									-moz-transform: translate(0px, -10px) scale(1.1);
									-ms-transform: translate(0px, -10px) scale(1.1);
									-o-transform: translate(0px, -10px) scale(1.1);
								}
							}

							.button--moma{
								-webkit-animation: animation-blue-flash 1.25s linear infinite normal;
								animation: animation-blue-flash 1.25s linear infinite normal;
								}
							@keyframes animation-blue-flash {
								0% {
									-moz-box-shadow: 0 0 0 0 #10a516;
									box-shadow: 0 0 0 0 #10a516;
								}
								70% {
									-moz-box-shadow: 0 0 0 10px rgba(2, 103, 193, 0);
									box-shadow: 0 0 0 15px rgba(2,103,193,0);
								}
								100% {
									-moz-box-shadow: 0 0 0 0 rgba(2, 103, 193, 0);
									box-shadow: 0 0 0 0 rgba(2,103,193,0);
								}
							}
						}//box_button

						.backbutton{
							outline: none;
							background: none;
							border: none;
							font-size: 18px;
							font-weight: 600;
							color: #ececec;
							cursor: pointer;
							text-decoration: underline;

						}

						// form style design here
						.form_drop{
							label {
								color: #fff;
								margin-bottom: 4px;
								font-size: 14px;
							}
							.select_financeProvider {
								position: relative;
								display: flex;
								width: 80%;
								height: 60px;
								box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
								line-height: 3;
								overflow: hidden;
								border-radius: 5px;
								border: 1px solid #a3a3a3;
								@media(max-width: 576px){
									width: 100%;
								}

								&::after {
									content: '\25BC';
									position: absolute;
									top: 0;
									right: 0;
									padding: 0 1em;
									background: #3e3e3e;
									color: #ffffff;
									cursor:pointer;
									height: inherit;
									pointer-events:none;
									transition:.25s all ease;
									display: flex;
									align-items: center;
								}
								&:hover::after {
									color: #ffffff;
								}
								select {
									-webkit-appearance:none;
									-moz-appearance:none;
									-ms-appearance:none;
									appearance:none;
									outline: 0;
									box-shadow: none;
									border: 0 !important;
									background: #ffffff;
									background-image: none;
									flex: 1;
									height: auto;
									padding: 0 0.5em;
									color: #7f7f7f;
									cursor: pointer;
									font-size: 17px;
									&::-ms-expand {
										display: none;
									}
									@media (max-width: 576px){
										font-size: 16px;
									}
								}//select
							}//select_financeProvider


							.select_title {
								position: relative;
								display: flex;
								width: 100%;
								line-height: 3;
								overflow: hidden;
								border-radius: 5px;
								border: 1px solid #a3a3a3;
								box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
								@media(max-width: 576px){
									width: 100%;
								}
								&::after {
									content: '\25BC';
									position: absolute;
									top: 0;
									right: 0;
									padding: 0 1em;
									background: #3e3e3e;
									color: #ffffff;
									cursor: pointer;
									height: 100%;
									pointer-events:none;
									transition:.25s all ease;
									display: flex;
									align-items: center;
								}
								&:hover::after {
									color: #ffffff;
								}

								select {
									-webkit-appearance:none;
									-moz-appearance:none;
									-ms-appearance:none;
									appearance:none;
									outline: 0;
									box-shadow: none;
									border: 0 !important;
									background: #ffffff;
									background-image: none;
									flex: 1;
									height: auto;
									padding: 15px 15px;
									font-size: 15px;
									box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
									color: #7f7f7f;
									cursor: pointer;
									&::-ms-expand {
										display: none;
									}
									@media (max-width: 576px){
										font-size: 15px;
									}
								}//select
							}//select_title

							.name_detail{
								padding-top: 20px;
								text-align: left;
								@media (max-width: 576px){
									padding-top: 10px;
								}
								.f_name{
									// margin-bottom: 10px;

									.form-control{
										border-radius: 5px;
										padding: 15px 15px;
										font-size: 15px;
										box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
										border: 1px solid #a3a3a3;
										// box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
										&::placeholder{
											color: #272727;
										}
										&:focus{
										    border-color: #cf9b00;
											outline: 0;
											box-shadow: 0 0 0 0.2rem #edac00;
										}
									}

								}//f_name

								.m_name{
									// margin-bottom: 10px;
									label{

										span{
											font-size: 9px;
											color: #ffffff;
											@media (max-width: 576px) {
												font-size: 7px;
											}
										}
									}
									.form-control{
										border-radius: 5px;
										padding: 15px 15px;
										font-size: 15px;
										box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
										border: 1px solid #a3a3a3;
										// box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
										&::placeholder{
											color: #272727;
										}
										&:focus{
										    border-color: #cf9b00;
											outline: 0;
											box-shadow: 0 0 0 0.2rem #edac00;
										}
									}//form-control

								}//m_name


								.l_name{
									margin-bottom: 10px;

									.form-control{
										border-radius: 5px;
										padding: 15px 15px;
										font-size: 15px;
										border: 1px solid #a3a3a3;
										box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
										&::placeholder{
											color: #272727;
										}
										&:focus{
											border-color: #cf9b00;
											outline: 0;
											box-shadow: 0 0 0 0.2rem #edac00;
										}
									}//form-control

								}//l_name
							}// name_detail



							// email and phone number design

							.check_email_add{
								padding-top: 10px;
								text-align: left;

								.input-group-text{
									border-right: 0;
									border-radius: 0;
									background: #fff;
								}
								.secure:after {
									position: absolute;
									top: 50%;
									right: 20px;
									width: 61px;
									height: 25px;
									transform: translateY(-50%);
									background: url(../img/secure-hybrid.png) no-repeat center center;
									-webkit-background-size: contain;
									-moz-background-size: contain;
									-o-background-size: contain;
									background-size: contain;
									content: '';
								}
								.form-control{
									border-radius: 5px;
									padding: 15px 15px;
									font-size: 15px;
									border: 1px solid #a3a3a3;
									box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
									&::placeholder{
										color: #272727;
									}
									&:focus{
										border-color: #cf9b00;
										outline: 0;
										box-shadow: 0 0 0 0.2rem #edac00;
									}//form-control
								}//form-control
							}//check_email_add

							.phone_deatail{
								text-align: start;
								.user_no{

									.input-group-text{
										border-right: 0;
										border-radius: 0;
										background: #fff;
									}
									.secure:after {
										position: absolute;
										top: 50%;
										right: 20px;
										width: 61px;
										height: 25px;
										transform: translateY(-50%);
										background: url(../img/secure-hybrid.png) no-repeat center center;
										-webkit-background-size: contain;
										-moz-background-size: contain;
										-o-background-size: contain;
										background-size: contain;
										content: '';
									}
									.form-control{
										border-radius: 5px;
										padding: 15px 15px;
										font-size: 15px;
										border: 1px solid #a3a3a3;
										box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
										&::placeholder{
											color: #272727;
										}
										&:focus{
											border-color: #cf9b00;
											outline: 0;
											box-shadow: 0 0 0 0.2rem #edac00;

										}//form-control
									}//form-control
								}//user_no
							}//phone_deatail


							// date of birth design

							.Birth_detail{
								padding-top: 10px;
								text-align: left;
								label{
									font-size: 11px;

								}
								.date_here{
									margin-bottom: 10px;

									.form-select{
										border-radius: 5px;
										padding: 15px 15px;
										font-size: 15px;
										border: 1px solid #a3a3a3;
										box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
										&::placeholder{
											color: #272727;
										}
										&:focus{
										    border-color: #cf9b00;
											outline: 0;
											box-shadow: 0 0 0 0.2rem #edac00;
										}
									}
								}//date_here

								.month_here{
									margin-bottom: 10px;


									.form-select{
										border-radius: 5px;
										padding: 15px 15px;
										font-size: 15px;
										border: 1px solid #a3a3a3;
										box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
										&::placeholder{
											color: #272727;
										}
										&:focus{
										    border-color: #cf9b00;
											outline: 0;
											box-shadow: 0 0 0 0.2rem #edac00;
										}
									}
								}//month_here

								.year_here{
									margin-bottom: 10px;

									.form-select{
										border-radius: 5px;
										padding: 15px 15px;
										font-size: 15px;
										border: 1px solid #a3a3a3;
										box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
										&::placeholder{
											color: #272727;
										}
										&:focus{
										    border-color: #cf9b00;
											outline: 0;
											box-shadow: 0 0 0 0.2rem #edac00;
										}
									}
								}//year_here
						}// Birth_detail

						.sect_bt{
							@media  (max-width: 576px){
								// padding-top: 20px;
							}
							.png_t{
								font-size: 21px;
								font-weight: 600;
								color: #000000;
								@media  (max-width: 576px){
									font-size: 19px;
								}
							}
							.box_vehiInfo{
								padding: 17px 25px;
								border-radius: 10px;
								-webkit-border-radius: 10px;
								-moz-border-radius: 10px;
								-ms-border-radius: 10px;
								-o-border-radius: 10px;
								background: #d7ecffb0;
								width: 80%;
								margin: 20px 0;
								color: #004d8b;
								@media  (max-width: 576px){
									width: 100%;
								}
								.lil_headTxt{
									font-size: 15px;
									font-weight: 500;
									padding-bottom: 5px;
									border-bottom: 1px solid #a3a3a3;
								}
								p{
									font-size: 16px;
									font-weight: 400;
								}
							}//box_vehiInfo
						}//sect_bt

						.enter_postcode_add{
							padding-top: 10px;
							text-align: start;


								.postcode_wrap {
									display: flex;
									width: 100%;
									.form-control{
										border-radius: 0;
										border-top-left-radius: 5px;
										border-bottom-left-radius: 5px;
										padding: 15px;
										border: 1px solid #a3a3a3;
										box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
										width: 55%;
										font-size: 15px;
										@media (max-width: 576px) {
											border: 1px solid #a3a3a3;
											box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
										}
										&::placeholder{
											color: #a3a3a3;
										}
										&:focus{
										    border-color: #cf9b00;
											outline: 0;
											box-shadow: 0 0 0 0.2rem #edac00;
										}//form-control
									}//form-control

									.button {
										width: 45%;
										outline: none;
										border: 0;
										background: none;
										padding: 13px 0;
										font-size: 17px;
										font-weight: 700;
										position: relative;
										display: block;
										border-top-right-radius: 5px;
										border-bottom-right-radius: 5px;
									}//button

									.bg_add_click{
										background: #10a516;
										color: #fff;
										box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
										transition: cubic-bezier(0.075, 0.82, 0.165, 1);
										&:hover {
											background: #0a6d0d;
										}
									}//bg_add_click
								}//postcode_wrap
							#selcetAdd{
								display: none;
								#addSelect{
									position: relative;
									top: 0;
								}//addSelect
								.form-select{
									border-radius: 5px;
									font-size: 15px;
									padding: 13px 10px;
									width: 100%;
									color: #000;
									border: 1px solid #a3a3a3;
									box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
									&:focus{
										border-color: #cf9b00;
											outline: 0;
											box-shadow: 0 0 0 0.2rem #edac00;
									}
									&place_dummi{
										color: #000;
									}
								}//form-select

								.ADD_detail{
									text-transform: uppercase;
									background: #cffff0b0;
									border-radius: 5px;
									border: 1px solid #bdbdbd;
									color: #000;
									display: none;
									margin: 20px 0;
									text-align: left;
									line-height: 140%;
									.ADD_na_top{
										font-size: 13px;
										font-weight: 700;
										margin-bottom: 5px;
									}//ADD_na_top
									.ADD_clr_typ{
										font-size: 12px;
										margin-bottom: 0;
										font-weight: 700;
									}//ADD_clr_typ
								}//ADD_detail

								.return_back{
									font-size: 14px;

									color: #fff;
									p{
										display: flex;
										display: flex;
										justify-content: space-between;
									}
									span{
										text-decoration: underline;
										cursor: pointer;
									}
								}///return_back
							}//selcetAdd

						}//enter_postcode_add
						@media (max-width: 576px) {
							.enter_postcode_add{
								.postcode_wrap {
									display: flex !important;
									flex-wrap: wrap;
									width: 100% !important;
									.form-control{
										border-radius: 5px;
										margin-bottom: 15px;
										width: 100% !important;}//form-control

									.button {
										width: 100% ;
										border-radius: 5px;
									}//button
								}//postcode_wrap
							}
						}//enter_postcode_add


						}//form_drop



						.tiny_note {
                            font-size: 12px;
                            color: #d7d7d7;
                            margin-top: 10px;
							text-align: start;

                        }//tiny_note
					}
				}//widget_mobi

		}//form_dec


	}//main_content



	.modal{
		background: rgba(0, 0, 0, 0.86);
		// padding-right: 0 !important;
		.modalContent_sml{
			min-height: 35vh !important;
		}
		.modal-content{
			display: flex;
			border-radius: 20px 20px 20px 20px;
			box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.15);
			padding: 10px;
			background:  $background_color_5;
			min-height: 60vh;
			.tiny_note {
				font-size: 12px;
			}
			.modal-header{
				border: 0;
				justify-content: center;
				.modal-title{
					img{
						width: 220px;
					}
				}
			}

			.modal-body{
				padding: 10px 0;

				.cont_flex{
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					.gif_img{
						width: 130px
					}
					p{
						font-size: 22px;
						font-weight: 600;
						margin: 0;
						@media (max-width: 576px) {
							font-size: 20px;
						}
					}
					.mobi_cut{
						color: #F5CB5C;
					}
				}
				.enter_postcode_mod{
					padding-top: 10px;
					text-align: start;
					.png_t{
						font-size: 19px;
						font-weight: 600;
					}

						label {
							font-size: 12px;
							font-weight: 700;
						}//label

						.postcode_wrap {
							display: flex;
							flex-wrap: wrap;
							width: 100%;
							.form-control{
								border-radius: 5px;
								padding: 15px;
								margin-bottom: 15px;
								width: 100%;
								font-size: 13px;
								box-shadow: rgba(0,0,0,0.24) 0px 3px 8px;
								@media(max-width: 576px){
									border: 1px solid #a3a3a3;
									box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
								}
								&::placeholder{
									color: $color_5;
								}
								&:focus{
									border-color: #cf9b00;
											outline: 0;
											box-shadow: 0 0 0 0.2rem #edac00;
								}//form-control
							}//form-control

							.button {
								width: 100%;
								outline: none;
								border: 0;
								background: none;
								padding: 13px 0;
								font-size: 15px;
								font-weight: 700;
								position: relative;
								display: block;
								border-radius: 5px;
							}//button

							.bg_mod_click{
								background: #303030;
								color: $background_color_1;
								transition: cubic-bezier(0.075, 0.82, 0.165, 1);

							}//bg_add_click
						}//postcode_wrap


					#selcetMod{
						display: none;
						#ModSelect{
							position: relative;
							top: 0;
						}//addSelect
						.form-select{
							border-radius: 5px;
							font-size: 13px;
							padding: 13px 10px;
							width: 100%;
							margin-bottom: 15px;
							border: 1px solid #a3a3a3;
							box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
							&:focus{
								border-color: $background_color_3;
								outline: 0;
								box-shadow: $box-shadow1;
							}
							&place_dummi{
								color: $color_5;
							}
						}//form-select

						.Mod_detail{
							text-transform: uppercase;
							background: #d7ecffb0;
							color:  $color_5;
							display: none;
							margin: 20px 0;
							text-align: left;
							line-height: 140%;
							.ADD_na_top{
								font-size: 13px;
								font-weight: 700;
								margin-bottom: 5px;
							}//ADD_na_top
							.ADD_clr_typ{
								font-size: 12px;
								margin-bottom: 0;
								font-weight: 700;
							}//ADD_clr_typ
						}//ADD_detail

						.return_back{
							font-size: 14px;

							p{
								display: flex;
								display: flex;
								justify-content: space-between;
							}
							span{
								text-decoration: underline;
							}
						}///return_back
					}//selcetAdd

				}//enter_postcode_mod
				@media (max-width: 576px) {
					.enter_postcode_mod{
						.postcode_wrap {
							// display: flex !important;
							flex-wrap: wrap;
							width: 100% !important;
							.form-control{
								border-radius: 5px;
								margin-bottom: 15px;
								width: 100% !important;}//form-control

							.button {
								width: 100% !important;
								border-radius: 5px;
							}//button
						}//postcode_wrap
				}//enter_postcode_add
				}

				.form_drop{
						.png_t{
							font-size: 19px;
							font-weight: 600;
						}
					.choose_Y_N{
						display: flex;
						width: 100%;
						gap: 8px;
						// margin-bottom: 30px;
						@media (max-width: 576px){
							flex-direction: column;
							gap: 15px;
						}
					/////////////////////////// form check /////////////////////////////////
					.form-check {
						// width: 50%;
						@media (max-width: 576px){
							width: 100% !important;
						}
						[type="radio"] {
							display: none;
						}
						// & + .form-check {
						// 	width: 50%;
						// }
						label {
							display: block;
							padding: 13px 60px;
							background: #fff;
							border: 1px solid #a3a3a3;
							box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
							border-radius: 5px;
							cursor: pointer;
							font-size: 16px;
							font-weight: 500;
							min-width: 195px;
							color: #333533;
							white-space: nowrap;
							position: relative;
							transition: 0.4s ease-in-out 0s;
							&:after{
								height: 19px;
								width: 19px;
								border: 1px solid #F5CB5C;
								left: 19px;
								top: calc(50% - 10px);
							}
							&:before {
								background: #F5CB5C;
								height: 11px;
								width: 11px;
								left: 23px;
								top: calc(50% - 6px);
								transform: scale(5);
								opacity: 0;
								visibility: hidden;
								transition: 0.4s ease-in-out 0s;
							}
						}
						[type="radio"]:checked {
							& ~ label {
								border-color: #02575f;
								box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
								background: #F5CB5C;
								color: #ffffff;
								&::before {
									background: #fff;
									opacity: 1;
									visibility: visible;
									transform: scale(1);
								}
								&::after{
									border: 2px solid #fff;
								}
							}
						}
					}
					.form-check label:after,
					.form-check label:before {
						content: "";
						position: absolute;
						border-radius: 50%;
					}
					///////////////////////// form check /////////////////////////////////
					}//choose_bw

					.sty_pop{
						font-size: 18px;
						font-weight: 600;
					}

				}//form_drop

			}
			.modal-footer {
				display: block;
				flex-shrink: 0;
				flex-wrap: wrap;
				align-items: center;
				justify-content: start;
				padding: 0;
				border-top: 0;
				.box_button {
					@media (max-width: 576px) {
						// padding: 10px 30px;
					}
					.wb_5{
						width: 50% !important;
						@media (max-width: 576px) {
							width: 100% !important;
						}
					}
					.yes_btn{
						width: 70% !important;
						@media (max-width: 576px) {
							width: 100% !important;
						}
					}

					.button {

						display: inline-block;
						background: #10a516;
						color: #fff;
						padding: 15px 30px;
						border-radius: 50px;
						border: 0;
						font-size: 24px;
						width: 50%;
						font-weight: 600;
						box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.164);
						cursor: pointer;
						// transition: all ease-in-out 300ms;
						//animation: move5 3s infinite linear;
						@media(min-width: 1200px){
							width: 45%;
						}
						@media (min-width: 980px) {
							width: 50%;
						}
						@media (max-width: 780px) {
							width: 40%;
						}
						@media (max-width: 576px) {
							width: 100%;
							font-size: 20px;
							background-size: 42% 45px !important;
						}
						&:hover {
							box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.144);
						}
					}//button
					@keyframes move5 {
						0% {
							-webkit-transform: translateY(0);
							transform: translateY(0)
						}

						50% {
							-webkit-transform: translateY(-20px);
							transform: translateY(-20px)
						}

						100% {
							-webkit-transform: translateY(0);
							transform: translateY(0)
						}
					}
				}//box_button
				@keyframes move5 {
					0% {
						-webkit-transform: translateY(0);
						transform: translateY(0)
					}

					50% {
						-webkit-transform: translateY(-20px);
						transform: translateY(-20px)
					}

					100% {
						-webkit-transform: translateY(0);
						transform: translateY(0)
					}
				}
			}
		}

	}

/*================================================
	featured STYLES
==================================================*/

	.featured {
		position: relative;
		padding-top: 30px;
		h2{
			text-align: center;
			font-size: 20px;
			font-weight: 600;
			color: 600;
		}
		.badges_dec{
			.jb_cont{
				background-color: #ff0000;
				padding: 15px 0;
				align-items: center;
				justify-content: center;
				.col-4{
					text-align: center;
					img{
						width: 120px;
						@media (max-width: 576px) {
							width: 90px;
						}
					}
				}
			}//jb_cont
		}//badges_dec
	}//featured


/*================================================
	claim-sec STYLES
==================================================*/

	.claim-sec {


		 display: flex;
		 align-items: center;
		 height: 100%;

		 @media (min-width: 768px) {
			background: url(../img/Graphic2.png) no-repeat center right;
		 }


		.container {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			max-width: 2000px !important;
			// margin: 40px 0 0;


			.card {
				position: relative;
				min-width: 360px;
				height: 350px;
				border: none !important;
				border-radius: 15px;
				margin: 20px;
				background: transparent;
				transition: 0.5s;
				&:nth-child(1) .box .content{
					background: transparent;
				}
				&:nth-child(2) .box .content{
					background: transparent;
				}
				&:nth-child(3) .box .content{
					background: transparent;
				}

				.box {
					position: absolute;
					top: 20px;
					left: 20px;
					right: 20px;
					bottom: 20px;
					border-radius: 15px;
					display: flex;
					justify-content: center;
					align-items: center;
					overflow: hidden;
					transition: 0.5s;
					&:hover {
						transform: translateY(-20px);
					}
					&:before {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						width: 50%;
						height: 100%;
						background: rgba(255, 255, 255, 0);
					}
					.content {
						padding: 20px;
						text-align: center;
						h3 {
							font-size: 1rem;
							color: #ebba37;
							z-index: 1;
							font-weight: 600;
							transition: 0.5s;
							margin-bottom: 30px;
							margin-top: 16px;
						}
						.click-find{
							padding: 18px 22px;
						}
						a {
							.sml-find{
								font-size: 13px;
								letter-spacing: normal;
							}
						}
						img{
							width: auto;
							height: 170px;
						}

						// Button spl effect
							.cta {
								position: relative;
								margin:5px 0;
								padding: 12px 22px;
								transition: all .2s ease;
							}

							.cta:before {
								content: "";
								position: absolute;
								top: 0px;
								left: 0;
								display: block;
								border-radius: 28px;
								background: rgba(245,203,92,0.9);
								width: 50px;
								height: 50px;
								transition: all .3s ease;
							}

							.cta span {
								position: relative;
								font-size: 13px;
								line-height: 18px;
								font-weight: 900;
								letter-spacing: .25em;
								text-transform: uppercase;
								vertical-align: middle;
							}

							.cta svg {
								position: relative;
								top: 0;
								margin-left: 10px;
								fill: none;
								stroke-linecap: round;
								stroke-linejoin: round;
								stroke: #0a0e0c;
								stroke-width: 2;
								transform: translateX(-5px);
								transition: all .3s ease;
							}

							.cta:hover:before {
								width: 100%;
								background: rgba(#F5CB5C, 1);
							}
							.cta:hover span{
								color: #fff;
							}
							.cta:hover svg{
								stroke: #fff;
							}

							.cta svg:hover {
								transform: translateX(0);
							}

							.cta:active {
								transform: scale(.96);
							}
					}//content
				}//box
			}//card
		}//container
	}//claim-sec

/*--------------------------------------------------------------
10. accordion
----------------------------------------------------------------*/
.sec_3Disgn{
    background: #e8e8e8;
    // font-family: "Beausite Slick Trial Light";
    .acco_desin{
        margin: 40px 0;
        a{
          text-decoration: none !important;
        }
          .accordion {
            width: 100%;
            h2{
                font-size: 26px;
                @media screen and (max-width: 576px) {
                    font-size: 22px;
                }
            }
              .accordion-item {
				margin-bottom: 10px;
                background: #F5CB5C;
                input[type="checkbox"] {
                  display: none;
                }
				h2{
				color: #333533;
				font-weight: 600;
				margin: 0;
				font-size: 16px !important;
					.accordion-button{
						background: #F5CB5C;
						font-weight: 600;
						font-size: 16px !important;
					}
				}
			}
			.accordion-body{
				background: #fff;
				color: gray;
				border: 1px solid #eee;
				font-size: 16px !important;
			}


                .content {
                  width: 100%;
                  transition: all 0.3s ease;
                  opacity: 0;
                  pointer-events: none;
                  padding: 8px 16px;
                  position: relative;
                  z-index: 1;
                  padding-left: 20px;
                  max-height: 0;
                  margin-top: -38px;
                  color: #717171;
                  padding: 25px 25px 25px 25px;
                  border: 1px solid #eee;
                  font-size: 16px !important;
                  &.show {
                    margin: 0px 0 13px;
                    max-height: fit-content;
                    opacity: 1;
                    pointer-events: all;
                    background: #fff;
                  }
                  p {
                    margin-bottom: 8px;
                    color: gray;
                  }
                }

          }
      }
}


/* ==================================
    FOOTER
================================== */

footer{
    padding: 25px 0;
    background: #262626 !important;
    .ftr_cntnt {
        padding: 30px 0 15px;
        // border-bottom: 1px solid #343434!important;
    }
    p{
        color: #ffffff!important;
        font-size: 14px;

        @media screen and (max-width: 767px) {
            margin: 7px 0 20px;
            padding: 0;
            font-size: 13px;
            color: #fff;
            font-weight: 300;
            text-align: center;
        }
    }
    ul{
        padding: 0;
        margin-top: 15px;
        @media screen and (max-width: 767px){
            text-align: center;
            padding: 0;
        }
        li {
        list-style: none;
        border-right: 1px solid #ffffff!important;
        padding: 0 10px;
        display: inline;
        color: #ffffff!important;
            &:first-child {
                padding-left: 0;
            }
			&:last-child {
				border-right: none !important;
            }
            a {
                font-size: 14px;
                text-decoration: none;
                color: #ffffff!important;
                cursor: pointer;
                @media screen and (max-width: 767px){
                    font-size: 13px;
                }
            }
        }
    }
}//footer


@keyframes pulse-animationhome {
    0% {
      box-shadow: 0 0 0 0px rgba(238, 255, 7, 0.644);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }
  .pulsehome {
    animation: pulse-animationhome 1.5s infinite;
  }

//cs_hero_text
.img_float{
	display: flex;
	align-items: flex-end;
	justify-content: center;
	position: relative;
		.cs_hero_img {
			position: relative;
			z-index: 1;
			height: max-content;
			max-width: 100%;
			@media only screen and (max-width: 1400px) and (min-width: 993px) {
				bottom: 0;
			}
			@media (max-width: 991px) {
				position: relative;
				margin-top: 20px;
				max-width: 87%;
			}
			@media (max-width: 780px) {
				max-width: 85%;
			}
			@media (max-width: 510px) {
				margin-top: 0px;
				// display: none;
			}
		}//cs_hero_img
		.cs_hero_float{
			position: absolute;
			top: 22px;
			left: 67px;
			width: 206px;
			z-index: 2;
			@media (max-width: 510px) {
				width: 139px;
			}
		}

	}//img_float

	.cs_hero_title {
		color: #fff;
		font-size: 48px;
		font-weight: 600;
		line-height: 130%;
		margin-bottom: 20px;
	}
	.cs_hero_subtitle {
		max-width: 700px;
		font-size: 20px;
		font-weight: 400;
		color: #ecd030;
		margin-bottom: 29px;
		strong {
			position: relative;
			font-size: 23px;
			@media (max-width: 430px) {
				font-size: 21px;
			}
		  }

		  strong::before {
			// background: #ba4545;
			// background: linear-gradient(
			//   45deg,
			//   hsla(264, 100%, 50%, 1) 0%,
			//   hsla(189, 100%, 50%, 1) 25%,
			//   hsla(156, 100%, 50%, 1) 50%,
			//   hsla(60, 100%, 50%, 1) 75%,
			//   hsla(325, 100%, 50%, 1) 100%
			// );
			content: "";
			// position: absolute;
			width: 0;
			height: 60%;
			left: -2px;
			bottom: 0;
			transform: rotate(-2deg);
			animation: 0.8s highlight 0.8s 1 normal forwards;
			transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
			filter: blur(16px);
			z-index: -1;
		  }

		  strong::after {
			background: hsla(264, 46%, 50%, 1);
			background: linear-gradient(
			  45deg,
			  #ff0000 0%,
			  #ff0000 25%,
			  #ff0000 50%,
			  #ff0000 75%,
			  rgb(255, 0, 0) 100%
			);
			content: "";
			position: absolute;
			width: 0;
			height: 7%;
			left: -2px;
			bottom: 7%;
			transform: rotate(-1deg);
			animation: 0.8s highlight 0.8s 1 normal forwards;
			transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
			filter: blur(0px) saturate(200%);
			mix-blend-mode: plus-lighter;
			border-radius: 7px;
			z-index: 1;
		  }

		  @-webkit-keyframes highlight {
			from {
			  width: 0;
			  opacity: 0;
			  border: 0;
			}

			to {
			  width: -webkit-calc(100% + 4px);
			  width: calc(100% + 4px);
			  opacity: 1;
			  border: 0;
			}
		  }
		}

// option-select



#style-6::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-6::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

#style-6::-webkit-scrollbar-thumb
{
	background-color: #F90;
	background-image: -webkit-linear-gradient(45deg,
	                                          rgba(255, 255, 255, .2) 25%,
											  transparent 25%,
											  transparent 50%,
											  rgba(255, 255, 255, .2) 50%,
											  rgba(255, 255, 255, .2) 75%,
											  transparent 75%,
											  transparent)
}

.scrollbar
{   	height:  500px;
	float: left;
	overflow-y: scroll;
	margin-bottom: 25px;
	@media (max-width: 768px) {
		height:  auto;
		overflow-y: inherit;
		margin-bottom: 0px;
	}
}

.force-overflow
{
	min-height: 450px;
	padding-right: 15px;
	@media (max-width: 768px) {
		min-height: inherit;
		height:  auto;
		padding-right: 0px;
	}
}

#wrapper
{
	text-align: center;
	margin: auto;
}


.cust_modal{
	background: #0f0f0f !important;
    padding: 20px !important;
    border: solid 1px #F5CB5C !important;
	padding-top: 10px !important;
	padding-bottom: 20px !important;
	@media (max-width: 768px) {
		padding-bottom: 60px !important;
	}
	.modal-header{
		margin: 0px;
		padding: 0px;
	}
	.btn-close{
			background-color: #F5CB5C;
			color: #fff;
			border-radius: 100%;
			position: relative;
			top: -15px;
			right: -20px;
			z-index: 9999;
			opacity: 99;
			@media (max-width: 768px) {
				position: fixed;
				top: 9px;
				right: 9px;
				background-size: 10px;
				padding: .25em .25em !important;
			}
	}
	.btn-pop{
		@media (max-width: 768px) {
			position: fixed;
			bottom: 0;
			left: 0;
			border-radius: 0px;
			z-index: 999;
		}

				background-image: url(../img/right-chevron.svg) !important;
				background-size: 17% 42px !important;
				background-repeat: no-repeat !important;
				background-position: right !important;
				display: inline-block;
				background: #10a516;
				color: #fff;
				padding: 16px 11px;
				border-radius: 76px;
				border: 0;
				font-size: 20px;
				width: 50%;
				font-weight: 600;
				box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.4);
				margin-top: 15px;
				cursor: pointer;
				transition: all ease-in-out 300ms;
				@media(min-width: 1200px){
					width: 45%;
				}
				@media (min-width: 980px) {
					width: 70%;
				}
				@media (max-width: 780px) {
					width: 60%;
				}
				@media (max-width: 510px) {
					width: 100%;
				}
				&:hover {
					box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
					transform: translate(0px, -10px) scale(1.1);
					-webkit-transform: translate(0px, -10px) scale(1.1);
					-moz-transform: translate(0px, -10px) scale(1.1);
					-ms-transform: translate(0px, -10px) scale(1.1);
					-o-transform: translate(0px, -10px) scale(1.1);
				}





	}
}
.selectoptions {
  input[type='checkbox'] + label {
    background-repeat: no-repeat;
    background-position: 15px center;
    background-size: 20px;
    border: 1px solid #f5cb5c;
    border-radius: 7px;
    color: #f5cb5c;
    cursor: pointer;
    display: inline-block;
    height: auto;
    padding: 10px 0;
    width: 100%;
    text-align: center;
  }

  label.selected-true {
    background-image: url(../img/check.png);
  }

  label.selected-false {
    background-image: url(../img/uncheck.png);
  }

  label {
    float: left;
  }

  input[type='checkbox'] {
    display: none;
  }
}

.option {
	float: left;
    height: auto;
	margin: 0 0 7px 0;
    width: 100%;
}
.option_title {
	color: #fff;
    float: left;
    font-size: 14px;
    font-weight: 400;
    height: auto;
    line-height: 18px;
    margin-left: 45px;
    margin-top: 0;
    position: relative;
    text-align: left;
    text-decoration: none;
    top: 0;
    z-index: 120;
}

.selectoptions-custom{
	.option_title{
		color: black;
	}
	label{
		border: 1px solid gold;
		background-color: white;
		margin-bottom: 5px;
	}
}

@media screen and (max-width: 958px){
	.pcp_siteHome .logo {

	width: 199px;

	position: static;

	padding: 14px 16px 0;
	}}


// }
}//pcp_siteHome
