@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.pcp_siteHome{
$background_color_1:#ffffff;
$background_color_2:#18478b;
$background_color_3:#18478b;
$background_color_4:#18478b;
$background_color_5:#18478b;
$background_color_6:#f9fbfc;
$background_color_7:#efefef;
$background_color_8:#f2f4f5;
$background_color_9:#cfcfcf;

$color_1:#18478b;
$color_2:#18478b;
$color_3: #ffff;
$color_4:#18478b;
$color_5:#979797;
$font-normal:normal;


// ======border===========
$box-shadow1: 0 0 0 0.2rem #5ca8ff9d;


$border-style2:4px solid #23df6a;
$border-style3:2px solid #d5d5d5;
$border-style4:1px solid #b4b4b4;
$border-style5:1px solid #2f3996;
/*========================================= */
// header{
//     position: absolute;
//     width: 100%;
//     z-index: 2;
//     transition: .4s all;
//     // border-bottom: 1px solid rgba(255, 255, 255, .2);
//     @media screen and (max-width: 992px) {
//         // padding-top: 15px;
//         padding-bottom: 5px;
//     }
//     .navbar{
//         justify-content: space-between;
//         @media screen and (max-width: 576px) {
//             justify-content: space-between;
//             display: flex;
//             align-content: center;
//             align-items: center;
//             padding: 0 10px;
//         }
//         @media screen and (max-width: 992px) {
//             padding: 0 20px;
//         }
//         .navbar-brand{
//             img{
//                 max-height: 30px;
//                 @media screen and (max-width: 576px) {

//                 }
//             }
//         }//navbar-brand

//         .hero_image_badge{
//             text-align: center;
//             p{
//                 margin-bottom: 3px;
//                 font-size: 18px;
//                 color: #fff;
//                 font-weight: 600;
//                 @media screen and (max-width: 576px) {
//                     font-size: 16px;
//                 }
//             }
//             img{
//                 max-width: 100px;
//                 @media screen and (max-width: 576px) {
//                     width: 100px;
//                 }
//             }
//         }//hero_image_badge
//     }//navbar
// }//header
.sign_content{
    .sign-below{
        background: #f5cb5c;
        display: inline-block;
        padding: 5px 10px;
        font-size: 13px;
        border-radius: 10px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    margin-top: 25px;
    .signature-validation{
        color:white;
    }
    position: relative;
    // padding-top: 60px !important;
    background: url(../img/Graphic2.png) no-repeat center center;
    background-size: cover;
    min-height: 100vh;
    @media screen and (max-width: 992px) {
        text-align: left !important;
        padding-top: 60px !important;
    }
    .sign_dis_paGe{

        .box_button {
            @media (max-width: 576px) {
                // padding: 10px 30px;
            }
            .wb_5{
                width: 50% !important;
                @media (max-width: 576px) {
                    width: 100% !important;
                }
            }
            .yes_btn{
                width: 70% !important;
                @media (max-width: 576px) {
                    width: 100% !important;
                }
            }

            .msf-nav-button.clear{
                background: #323232;
                background-image: none !important;
                @media (max-width : 576px){
                    width: 30% !important;
                    background: #323232;
                }
            }

            .button {

                display: inline-block;
                background: #10a516;
                color: #fff;
                padding: 12px 8px;
                border-radius: 1px;
                border: 0;
                font-size: 20px;
                width: 50%;
                font-weight: 600;
                box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.4);
                cursor: pointer;
                transition: all ease-in-out 300ms;
                @media(min-width: 1200px){
                    width: 49%;
                }
                @media (min-width: 980px) {
                    width: 49%;
                }
                @media (max-width: 780px) {
                    width: 49%;
                }
                @media (max-width: 576px) {
                    width: 70%;
                }
                &:hover {
                    box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
                    // transform: translate(0px, -10px) scale(1.1);
                    // -webkit-transform: translate(0px, -10px) scale(1.1);
                    // -moz-transform: translate(0px, -10px) scale(1.1);
                    // -ms-transform: translate(0px, -10px) scale(1.1);
                    // -o-transform: translate(0px, -10px) scale(1.1);
                }
            }

        }//box_button

        .slide_7Dec{
            .plateThree_anim{
                padding: 20px 0px 20px 0px;
                @media (max-width: 1200px) {
                    padding: 0px 0px
                }
                @media (max-width: 576px) {
                    padding: 30px 0px
                }

                .lts_add_sm{
                    font-weight: 800;
                    text-align: center;
                    font-size: 38px;
                    line-height: 145%;
                    color: #ffffff;
                    margin-bottom: 30px;
                    @media (max-width: 1200px) {
                        font-size: 36px;
                    }
                    @media (max-width: 980px) {
                        font-size: 32px;
                    }
                    @media (max-width: 768px) {
                        font-size: 28px;
                    }
                    @media (max-width: 576px) {
                        font-size: 22px;
                    }
                    @media (max-width: 440px) {
                        font-size: 19px;
                    }
                    span{
                        display: inline-block;
                        position: relative;
                    }
                    .clr-dif{
                        color: #ffe96e;
                        position: relative;
                        padding: 0 10px;
                        &::after {
                            content: "";
                            position: absolute;
                            top: auto;
                            left: 0;
                            bottom: 15%;
                            width: 100%;
                            height: 32%;
                            z-index: -1;
                            background: rgba(227, 255, 65, 0.231);
                        }
                    }
                }

                .sign-here{
                    border-radius: 5px;
                    padding: 0 0;
                    border: 0;
                    height: auto;
                    // box-shadow: rgba(100, 100, 111, 0.38) 0px 6px 20px 0px;
                    .sign-note{
                        background: #e7e7e7;
                        padding: 15px 25px;
                        border-radius: 30px;
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                        img{
                            // width: 10px;
                        }
                        p{
                            padding: 0;
                            margin: 0;
                        }
                        .sign-title{
                            font-size: 16px;
                        }
                        .sml-note{
                            font-size: 12px;
                            padding: 0;
                            margin: 0;
                        }
                    }
                    .sign-pad{
                        background: #fff;
                        border: 1px solid #b9b9b9;
                    }
                    canvas#canvas_signature {
                        width: 100%;
                        height: 400px;
                    }
                }// signature

                .sign_Btn_paGe{
                    @media (max-width: 576px) {
                        position: fixed;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        z-index: 9999;
                    }
                    input{
                        @media (max-width: 576px) {
                            border-radius: 0 !important;
                        }
                    }
                }

                .clear-btn{
                    background: rgb(231, 231, 231);
                    border-radius: 30px;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    padding: 10px 25px;
                    height: auto;
                    /* The container */
                    .non_check{
                        a{
                            text-decoration: underline !important;
                        }
                        position: relative;
                        top: -15px;
                        margin-left: 33px;
                        font-size: 11px;
                        text-align: justify;
                        .lil_edit{
                            color: #4285f4;
                            font-size: 12px;
                            font-weight: 600;
                            font-style: normal;
                            text-decoration: underline;
                            cursor: pointer;
                            &:hover{
                                color: #0f5edf;
                            }
                            img{
                                width: 13px;
                                margin-top: -8px;
                            }
                        }
                    }
                    .bt_points{
                        position: relative;
                        top: -23px;
                        p{
                            padding: 0;
                            margin: 0;
                            display: inline-flex;
                            align-items: flex-start;
                            gap: 16px;
                            img{
                                width: 12px;
                                padding-top: 4px;
                            }
                        }
                        .sml-note{
                            font-size: 12px;
                            padding: 0;
                            margin: 0;
                        }
                    }
                    .container-checkbox {
                        display: block;
                        position: relative;
                        padding-left: 28px;
                        margin-bottom: 12px;
                        cursor: pointer;
                        font-size: 12px;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                    }

                    /* Hide the browser's default checkbox */
                    .container-checkbox input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                    }

                    /* Create a custom checkbox */
                    .container-checkbox .checkmark {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 19px;
                        width: 19px;
                        background-color: #ffffff;
                    }

                    /* On mouse-over, add a grey background color */
                    .container-checkbox:hover input ~ .checkmark {
                        background-color: #ccc;
                    }

                    /* When the checkbox is checked, add a blue background */
                    .container-checkbox input:checked ~ .checkmark {
                        background-color: #f5cb5c;
                    }

                    /* Create the checkmark/indicator (hidden when not checked) */
                    .container-checkbox .checkmark:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }

                    /* Show the checkmark when checked */
                    .container-checkbox input:checked ~ .checkmark:after {
                        display: block;
                    }

                    /* Style the checkmark/indicator */
                    .container-checkbox .checkmark:after {
                        left: 7px;
                        top: 3px;
                        width: 6px;
                        height: 11px;
                        border: solid white;
                        border-width: 0 2px 2px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }

                }
            }

        }
        .modal{
            background: rgba(0, 0, 0, 0.86);
            // padding-right: 0 !important;
            .modalContent_sml{
                min-height: 35vh !important;
            }
            .modal-content{
                display: flex;
                border-radius: 20px 20px 20px 20px;
                box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.15);
                padding: 10%;
                background-image: url(../img/bubble_bg.png);
                background-position: center right;
                background-repeat: no-repeat;
                background-size: contain;
                min-height: 60vh;
                .modal-header{
                    border: 0;
                    justify-content: center;
                    .modal-title{
                        img{
                            width: 220px;
                        }
                    }
                }

                .modal-body{
                    padding: 10px 0;
                    // display: flex;
                    // align-items: flex-start;

                    .form_drop{
                            .png_t{
                                font-size: 19px;
                                font-weight: 600;
                            }
                        .choose_Name{
                            display: flex;
                            width: 100%;
                            flex-direction: column;
                            gap: 8px;
                            // margin-bottom: 30px;
                            @media (max-width: 576px){
                                flex-direction: column;
                                gap: 15px;
                            }
                        /////////////////////////// form check /////////////////////////////////
                        .form-check {
                            // width: 50%;
                            @media (max-width: 576px){
                                width: 100% !important;
                            }
                            [type="radio"] {
                                display: none;
                            }
                            // & + .form-check {
                            // 	width: 50%;
                            // }
                            label {
                                display: block;
                                padding: 13px 60px;
                                background: #fff;
                                border: 1px solid #a3a3a3;
                                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                                border-radius: 5px;
                                cursor: pointer;
                                font-size: 16px;
                                font-weight: 500;
                                width: 100%;
                                color: #002448;
                                white-space: nowrap;
                                position: relative;
                                transition: 0.4s ease-in-out 0s;
                                &:after{
                                    height: 19px;
                                    width: 19px;
                                    border: 1px solid #18478b;
                                    left: 19px;
                                    top: calc(50% - 10px);
                                }
                                &:before {
                                    background: #004d8b;
                                    height: 11px;
                                    width: 11px;
                                    left: 23px;
                                    top: calc(50% - 6px);
                                    transform: scale(5);
                                    opacity: 0;
                                    visibility: hidden;
                                    transition: 0.4s ease-in-out 0s;
                                }
                            }
                            [type="radio"]:checked {
                                & ~ label {
                                    border-color: #004d8b;
                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                                    background: #4285f4;
                                    color: #ffffff;
                                    &::before {
                                        background: #fff;
                                        opacity: 1;
                                        visibility: visible;
                                        transform: scale(1);
                                    }
                                    &::after{
                                        border: 2px solid #fff;
                                    }
                                }
                            }
                        }
                        .form-check label:after,
                        .form-check label:before {
                            content: "";
                            position: absolute;
                            border-radius: 50%;
                        }
                        ///////////////////////// form check /////////////////////////////////
                        }//choose_bw

                        .sty_pop{
                            font-size: 18px;
                            font-weight: 600;
                        }


                        label[for="format2"]{
                            font-size: 12px;
                            font-weight: 700;
                        }
                        .select_title {
                            position: relative;
                            display: flex;
                            width: 100%;
                            line-height: 3;
                            overflow: hidden;
                            border-radius: 5px;
                            border: 1px solid #a3a3a3;
                            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                            @media(max-width: 576px){
                                width: 100%;
                            }
                            &::after {
                                content: '\25BC';
                                position: absolute;
                                top: 0;
                                right: 0;
                                padding: 0 1em;
                                background: #4285f4;
                                color: #ffffff;
                                cursor: pointer;
                                height: 100%;
                                pointer-events:none;
                                transition:.25s all ease;
                                display: flex;
                                align-items: center;
                            }
                            &:hover::after {
                                color: #ffffff;
                            }

                            select {
                                -webkit-appearance:none;
                                -moz-appearance:none;
                                -ms-appearance:none;
                                appearance:none;
                                outline: 0;
                                box-shadow: none;
                                border: 0 !important;
                                background: #ffffff;
                                background-image: none;
                                flex: 1;
                                height: auto;
                                padding: 15px 15px;
                                color: #7f7f7f;
                                cursor: pointer;
                                font-size: 13px;
                                &::-ms-expand {
                                    display: none;
                                }
                                @media (max-width: 576px){
                                    font-size: 13px;
                                }
                            }//select
                        }//select_title

                        .name_detail{
                            padding-top: 20px;
                            text-align: left;
                            @media (max-width: 576px){
                                padding-top: 10px;
                            }
                            .f_name{
                                margin-bottom: 10px;
                                label{
                                    font-size: 12px;
                                    font-weight: 700;
                                }
                                .form-control{
                                    border-radius: 5px;
                                    padding: 15px 15px;
                                    font-size: 13px;
                                    border: 1px solid #a3a3a3;
                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                                    &::placeholder{
                                        color: $color_5;
                                    }
                                    &:focus{
                                        border-color: #18478b;
                                        outline: 0;
                                        box-shadow: $box-shadow1;
                                    }
                                }
                            }//f_name

                            .m_name{
                                margin-bottom: 10px;
                                label{
                                    font-size: 12px;
                                    font-weight: 700;
                                    span{
                                        font-size: 6px;
                                        color: #8b8b8b;
                                        @media (max-width: 576px) {
                                            font-size: 9px;
                                        }
                                    }
                                }
                                .form-control{
                                    border-radius: 5px;
                                    padding: 15px 15px;
                                    font-size: 13px;
                                    border: 1px solid #a3a3a3;
                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                                    &::placeholder{
                                        color: $color_5;
                                    }
                                    &:focus{
                                        border-color: #18478b;
                                        outline: 0;
                                        box-shadow: $box-shadow1;
                                    }
                                }//form-control
                            }//m_name


                            .l_name{
                                margin-bottom: 10px;
                                label{
                                    font-size: 12px;
                                    font-weight: 700;
                                }
                                .form-control{
                                    border-radius: 5px;
                                    padding: 15px 15px;
                                    font-size: 13px;
                                    border: 1px solid #a3a3a3;
                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                                    &::placeholder{
                                        color: $color_5;
                                    }
                                    &:focus{
                                        border-color: #18478b;
                                        outline: 0;
                                        box-shadow: $box-shadow1;
                                    }
                                }//form-control
                            }//l_name

                            .tiny_note {
                                font-size: 15px;
                                color: #979797;
                                margin-top: 3px;
                            }//tiny_note
                            .validate{
                                background: red;
                                color: white;
                                font-size: 12px;
                                padding-left: 5px;
                                border-radius: 4px;
                                width: 100%;
                                margin-top: 0 !important;
                                margin: 0;
                                // margin-left: 22px;
                                @media (max-width: 510px) {
                                    width: 100%;
                                    margin-left: 0px;
                                }
                            }
                        }// name_detail

                        .Birth_detail{
                                padding-top: 10px;
                                text-align: left;
                                label{
                                    font-size: 12px;
                                    font-weight: 700;
                                }
                                .date_here{
                                    margin-bottom: 10px;
                                    label{
                                        font-size: 11px;
                                        font-weight: 400;
                                        color: $color_5;
                                    }
                                    .form-select{
                                        border-radius: 5px;
                                        padding: 15px;
                                        font-size: 13px;
                                        border: 1px solid #a3a3a3;
                                        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                                        &::placeholder{
                                            color: $color_5;
                                        }
                                        &:focus{
                                            border-color: #18478b;
                                            outline: 0;
                                            box-shadow: $box-shadow1;
                                        }
                                    }
                                }//date_here

                                .month_here{
                                    margin-bottom: 10px;
                                    label{
                                        font-size: 11px;
                                        font-weight: 400;
                                        color: $color_5;
                                    }
                                    .form-select{
                                        border-radius: 5px;
                                        padding: 15px;
                                        font-size: 13px;
                                        border: 1px solid #a3a3a3;
                                        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                                        &::placeholder{
                                            color: $color_5;
                                        }
                                        &:focus{
                                            border-color: #18478b;
                                            outline: 0;
                                            box-shadow: $box-shadow1;
                                        }
                                    }
                                }//month_here

                                .year_here{
                                    margin-bottom: 10px;
                                    label{
                                        font-size: 11px;
                                        font-weight: 400;
                                        color: $color_5;
                                    }
                                    .form-select{
                                        border-radius: 5px;
                                        padding: 15px;
                                        font-size: 13px;
                                        border: 1px solid #a3a3a3;
                                        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                                        &::placeholder{
                                            color: $color_5;
                                        }
                                        &:focus{
                                            border-color: #18478b;
                                            outline: 0;
                                            box-shadow: $box-shadow1;
                                        }
                                    }
                                }//year_here

                                .tiny_note {
                                    font-size: 11px;
                                    color: #979797;
                                    margin-top: 3px;
                                }//tiny_note
                        }// Birth_detail
                    }//form_drop

                }
                .modal-footer {
                    display: block;
                    flex-shrink: 0;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: start;
                    padding: 0;
                    border-top: 0;
                }
            }

        }
    }
}


@keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(238, 255, 7, 0.644);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }
  .pulse {
    animation: pulse-animation 1.5s infinite;
  }
}