.preview {
	.form-check-input:checked {
    background-color: #20242b;
}
.form-check-input {
    width: 20px;
    height: 20px;
    border: solid 2px #000 !important;
    border-radius: 0px !important;
}
	background-color: #525659;

	@page {
		margin: 0cm;
		padding: 0cm;
	}

	body {
		background-color: #525659;
		color: #000;
		padding: 0;
		margin: 5px 0;

		section {
			&>.loa {
				padding: 40px 90px;
				letter-spacing: 0.1px;
				font-size: 14px;
				font-family: "Noto Sans", sans-serif;
			}

			&>.tc {
				padding: .4cm 1.2cm .4cm 1.2cm;
				font-size: 12px;
				line-height: 10px;
				letter-spacing: 0.1px;
			}

			&>.cd {
				padding: 1cm .9cm;
				font-size: 12px;
				letter-spacing: 0.1px;
				font-family: "Noto Sans", sans-serif;
			}
		}
	}

	table {
		width: 100%;
		font-family: Arial, Helvetica, sans-serif;

		tbody {
			width: 100%;
		}
	}

	.page-break {
		page-break-after: always;
	}

	td {
		vertical-align: top;
	}

	.text-left {
		text-align: start;
	}

	.text-center {
		text-align: center;
	}

	.m-0 {
		margin: 0;
	}

	.mb-0 {
		margin-bottom: 0;
	}

	.mb-1 {
		margin-bottom: 4px;
	}

	.mb-5 {
		margin-bottom: 32px;
	}

	.pl-30 {
		padding-left: 30px;
	}

	.pl-25 {
		padding-left: 25px;
	}

	.pl-20 {
		padding-left: 20px;
	}

	.pl-15 {
		padding-left: 15px;
	}

	.pl-10 {
		padding-left: 10px;
	}

	.h-36 {
		height: 36px;
	}

	.wd-3 {
		width: 30%;
	}

	.wd-7 {
		width: 70%;
	}

	.wd-5 {
		width: 50%;
	}

	.WD-1_5 {
		width: 15%;
	}

	.under-line {
		text-decoration: underline;
	}

	.my-15 {
		margin: 15px 0;
	}

	.body_all {
		max-width: 800px;
		margin: 0 auto 10px;
		padding: 40px 90px;
		background: white;
		box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.75);
	}

	.loa {
		a {
			text-decoration: none;
			color: #000;
		}

		p {
			line-height: 20px;
			font-size: 13px;
		}
	}

	.p_style {
		margin: 0px;
		padding: 0px;
	}

	.p-head {
		font-size: 14px;
		margin-bottom: 0;
		margin-top: 18px;
	}

	.tc {
		ul {
			padding-left: 25px !important;

			li {
				list-style-type: square;
			}
		}

		p {
			font-size: 12px !important;
			line-height: 15px;
			margin: 0;
		}

		li {
			font-size: 10px;
			padding-left: 0;
			margin: 0;
		}
	}

	.tc-table-1 {
		width: 90%;
		border-collapse: collapse;
	}

	.tc-table-3 {
		width: 90%;
		border-collapse: collapse;
		margin-top: 10px;
	}

	.tc-table-2 {
		width: 93%;
		border-collapse: collapse;
		margin-top: 10px;
	}

	.tc-td-1 {
		border: 1px solid #000;
		text-align: center;
	}

	.tc-td-1,
	.tc-td-2,
	.tc-td-3 p {
		font-size: 12px !important;
	}

	.tc-td-2 {
		border: 1px solid #000;
		padding-left: 5px;
		text-align: center;
		vertical-align: text-top;
	}

	.tc-td-3 {
		border: 1px solid #000;
		padding-left: 10px;
		height: 30px;
		vertical-align: middle;
		font-size: 12px;

		p {
			line-height: 30px;
		}
	}

	.cd {
		.cd-table-1 {
			width: 100%;
			border-collapse: collapse;
			text-align: center;
			margin-bottom: 15px;

			p {
				line-height: 40px;
			}
		}

		p {
			line-height: 25px;
			margin: 0;
			font-size: 12px;
		}

		.cd-table-2 {
			width: 100%;
			border-collapse: collapse;
			margin-bottom: 30px;

			p {
				line-height: 40px;
			}
		}
	}

	.cd-table-2 {
		width: 100%;
		border-collapse: collapse;
		margin-bottom: 30px;
	}

	.cd-footer {
		font-size: 10px;
		text-align: center;
		line-height: 12px;
		border-top: 1px solid #000;
		padding-top: 10px;
	}

	.cd-td-2 {
		border: 1px solid #000;
		padding-left: 5px;
		text-align: center;
		vertical-align: text-top;
	}

	.cd-td {
		border: 1px solid #000;
		padding-left: 10px;
	}

	.dot-write {
		width: 100%;
		margin: 20px 0;
		border-top: 2px dotted #000;
		border-bottom: 2px dotted #000;
		height: 30px;
	}

	.check {
		border: 2px solid #000;
		width: 18px;
		height: 15px;
	}
}