.id-upload-V3{
  font-family:  "Ubuntu Sans", sans-serif !important;
  color:#000;
  margin-top: 0px !important;
  
  
  /*
*******************************************
Template Name: TMS Legal
Author: SHAREEF
Date: 18-01-2024
Template By: Vandalay Designs
Copyright 2020-2024 Vandalay Business Solution

* This file contains the styling for Free Debt Assessment, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - RANGE SLIDER 
06 - form-part 
07 - MEDIA QUERY  

============================================
============================================

*/

/*================================================
01 - Sass Variables
==================================================*/

// =======background colors========

$color1: #EDC55C;
$color2: #0F1994;
$color3: #1ECD3A;
$color4: #1FCCF2;
$color5: #f85c2a;
$color6: #1FCCF2;
$color7: #DF2C2C;
$color8: #0157FF;
$color9: #1ED1BC;
$color10: #148325;
$color11: #a2d0f9;
$color12: #946FFF;
$color13: #9b9b9b;

$black-color: #000;
$white-color: #fff;



$font_family_1: "Ubuntu Sans", sans-serif !important;



$font-bold: bold;
$font-normal: normal;
$font-w500: 500;
$font-w600: 600;
$font-w700: 700;

// ======border===========

$border-style1: solid 1px #fff;
$border-style2: 1px solid #d3d3d3;
$border-style3: 5px solid #011a70;

$border-style4: 3px solid #057599;
$border-style5: 2px #1483ce solid;

$border-color1: #d3d3d3;
$border-color2: #dedcdc;
$border-color3: #000;

/*================================================
02 - Sass Mixins
==================================================*/

@mixin radius_18 {
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  border-radius: 18px;
}

@mixin shadow_1 {
  -webkit-box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

@mixin shadow_2 {
  -webkit-box-shadow: 0px 11px 16px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 11px 16px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 11px 16px -10px rgba(0, 0, 0, 0.75);
}

@mixin shadow_in {
  -moz-box-shadow: inset 0 2px 5px 1px rgba(0, 0, 0, 0.15),
    0 1px 0px 0px rgba(230, 230, 230, 0.9), inset 0 0 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 2px 5px 1px rgba(0, 0, 0, 0.15),
    0 1px 0px 0px rgba(230, 230, 230, 0.9), inset 0 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 2px 5px 1px rgba(0, 0, 0, 0.15),
    0 1px 0px 0px rgba(230, 230, 230, 0.9), inset 0 0 1px 1px rgba(0, 0, 0, 0.2);
}

@mixin commButton {
  align-items: center;
  background-clip: padding-box;
  background-color: $color1;
  border: 1px solid transparent;
  border-radius: 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 20px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3.8rem;
  padding: calc(1rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;

  &:focus {
    background-color: $color2;
    box-shadow: rgba(0, 0, 0, 0.2) 0 4px 12px;
  }

  &:hover {
    background-color: $color2;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    transform: translateY(-2px);
  }

  &:active {
    background-color: $color2;
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    transform: translateY(0);
  }

  i {
    margin-left: 10px;
  }
}

@mixin commbg1 {
  background-image: linear-gradient(to bottom, #e1f2fd, #ebf5fe, #f3f7ff, #fafbff, #ffffff);
  background-repeat: no-repeat;
  background-position: bottom center;

}

/*================================================
03 - BODY STYLES
==================================================*/
.com-button {
  @include commButton;

}
.error_msg{
  color:red;
  margin-top: 5px;
  margin-bottom: 2px;
}
.hide{
	display:none;
}
.show{
	display:block;
}
.pcpv3-home {
  .modal {
    .modal-content {
      .modal-footer .box_button .button {
        display: inline-block;
        background: #10a516;
        color: #fff;
        padding: 15px 30px;
        border-radius: 50px;
        border: 0;
        font-size: 24px;
        width: 50%;
        font-weight: 600;
        box-shadow: 0 17px 10px -10px rgba(0,0,0,.164);
        cursor: pointer;
      }
      .modal-body {
        .enter_postcode_mod {
          .Mod_detail2 {
            text-transform: uppercase;
            background: rgba(215,236,255,.6901960784313725);
            border-radius: 5px;
            color: #272727;
            display: none;
            margin: 20px 0;
            text-align: left;
            line-height: 140%;
            .ADD_clr_typ {
              font-size: 12px;
              margin-bottom: 0;
              font-weight: 700;
            }
          }
          .postcode_wrap {
            .bg_mod_click  {
              background: #303030;
              color: #fff;
              transition: cubic-bezier(.075, .82, .165, 1);
            }
            .form-control{
              border-radius: 5px;
              padding: 15px;
              margin-bottom: 15px;
              width: 100%;
              font-size: 13px;
              box-shadow: 0 3px 8px rgba(0,0,0,.24);
            }
            .button {
              width: 100%;
              outline: none;
              border: 0;
              padding: 13px 0;
              font-size: 15px;
              font-weight: 700;
              position: relative;
              display: block;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}
.pcpv3-home .modal .modal-content {
  display: flex;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0 0 15px 0 rgba(0,0,0,.15);
  padding: 10px;
  background: #f5cb5c;
  min-height: 60vh;
}
.pcpv3-home .modal .modalContent_sml {
  min-height: 35vh!important;
}
.pcpv3-home .modal {
  background: rgba(0,0,0,.86);
}
.form_drop{
  .png_t{
    font-size: 19px;
    font-weight: 600;
  }
.choose_Y_N{
  display: flex;
  width: 100%;
  gap: 8px;
  @media (max-width: 576px){
    flex-direction: column;
    gap: 15px;
  }
.form-check {
  @media (max-width: 576px){
    width: 100% !important;
  }
  [type="radio"] {
    display: none;
  }
  label {
    display: block;
    padding: 13px 60px;
    background: #fff;
    border: 1px solid #a3a3a3;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    min-width: 220px;
    color: #333533;
    white-space: nowrap;
    position: relative;
    transition: 0.4s ease-in-out 0s;
    &:after{
      height: 19px;
      width: 19px;
      border: 1px solid #F5CB5C;
      left: 19px;
      top: calc(50% - 10px);
    }
    &:before {
      background: #F5CB5C;
      height: 11px;
      width: 11px;
      left: 23px;
      top: calc(50% - 6px);
      transform: scale(5);
      opacity: 0;
      visibility: hidden;
      transition: 0.4s ease-in-out 0s;
    }
  }
  [type="radio"]:checked {
    & ~ label {
      border-color: #02575f;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      background: #F5CB5C;
      color: #ffffff;
      &::before {
        background: #fff;
        opacity: 1;
        visibility: visible;
        transform: scale(1);
      }
      &::after{
        border: 2px solid #fff;
      }
    }
  }

}
.form-check label:after,
.form-check label:before {
  content: "";
  position: absolute;
  border-radius: 50%;
}
///////////////////////// form check /////////////////////////////////
}//choose_bw

.sty_pop{
  font-size: 18px;
  font-weight: 600;
}

}
.btn-comm {
  background: $color8;
  padding: 20px 40px;
  border-radius: .4rem;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  color: $white-color;
  font-size: 18px;
  position: relative;
  overflow: hidden;

  &.next-btn {
    &::after {
      content: " Next >>";
      background: #f03583;
      transform: translateX(-100%);
      transition: all ease-in 0.3s;
    }
  }

  &.back-btn {
    &::after {
      content: "<< Back";
      background: #000;
      transform: translateX(100%);
      transition: all ease-in 0.3s;
    }
  }

  &::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    border-radius: 24px;
    padding-top: 8px;
  }

  span {
    top: 0px;
    transition: all ease-in 0.3s;
  }

  &:hover {
    &:hover {
      color: $white-color;
    }

    &::after {
      transform: translateX(0%);
      transition: all ease-in 0.3s;
    }

    span {
      position: relative;
      top: 30px;
      transition: all ease-in 0.3s;
    }
  }
}

$color_1: #fff;
$background-color_1: #663dff;

/* 15 */
.btn-comm2 {
  background: $color1;
  padding: 20px 20px;
  border: none;
  position: relative;
  z-index: 1;
  color: $black-color;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;

  span {
    position: relative;
    z-index: 4;
    font-size: 1.3rem;
  }

  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    background-color: #dfb74d;
    transition: all 0.3s ease;
    z-index: 3;
    border-radius: 10px;
  }

  &:hover {

    //color: $color_1;
    &:after {
      left: 0;
      width: 100%;

    }
  }

  &:active {
    top: 2px;
  }

  &.clear {
    background: $color13
  }
}

.btn-comm3 {
  @extend .btn-comm2;
  background: $color5;

  &:after {
    background: #f35624;
  }
}

.skipbtn {
  @extend .btn-comm2;
  background: $black-color;
  font-weight: initial;
  span {
    color:$white-color;
    font-size: 1.2rem;
  }
}



html {
  scroll-behavior: smooth;
  font-size: 1rem;

}


// body {
//   font-family: $font_family_1;
//   color: $black-color;

//   margin: 10px;

// }

ul,
li {
  list-style-type: none;
}

a {
  text-decoration: none !important;

}

/*================================================
04 - HEADER 
==================================================*/



.strip {
  background: $color7;

  img {
    height: 40px;
  }
}

.header-wrap {
  background: $color1;
  color: $white-color;

  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 999;


}

.home-top {
  margin-top: 73px;
}

.home-banner {


  .bnr-text {
    .main-text {
      max-width: 800px;
      margin: 0 auto;

      h1 {
        font-weight: bold;
        font-size: 4rem;
        line-height: 4rem;


        background: linear-gradient(to right, rgb(0, 0, 0), $color1, #000000);
        background-size: 200% 200%;
        animation: pretty 5s ease-in-out infinite;
        background-clip: text;
        -webkit-background-clip: text;
        transition: color 1s ease-in-out;
        color: rgba(0, 0, 0, 0);
      }

      @keyframes pretty {
        0% {
          background-position: right
        }

        50% {
          background-position: left
        }

        100% {
          background-position: right
        }
      }

      p {
        font-size: 1.2rem;
      }
    }

    .payout-wrap {
      position: relative;

      &::after {
        content: "";
        width: 100%;
        height: 1px;
        left: 0;
        background: $color1;
        position: absolute;
        z-index: 1;
        top: 10px;
      }

      .payout-inner {
        max-width: 185px;
        display: inline-block;
        background: $white-color;
        padding: 2px;
        position: relative;
        z-index: 2;

        .payout {
          font-size: 1rem;
          font-weight: 600;

          .amount {
            position: relative;

            font-size: 1.5rem;

            svg {
              position: absolute;
              top: -46px;
              left: 6px;
            }
          }
        }

      }

    }

  }

}

.arrowicon {
  animation: move6 2s infinite both;
  display: inline-block;
  img {
    height: 20px;
  }
}
@keyframes move6 {
  0% {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
    opacity:1;
  }
  
 
  50% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
    opacity: 0;
  }
}

.btn-start {
  font-size: 1.5rem;
  font-weight: 600;
  width: 400px;
}

.highlight-section {
  margin-top: 4rem;

  .highlights {
    img {
      height: 70px;

    }

    h2 {
      font-size: 1.3rem;
      font-weight: 600;
    }
  }
}

.lendersection {
  .bg-inner {
    background: #FFEFC3 !important;
  }

  .lender-list {
    li {
      &.current {
        .name {

          .circle {
            background: $black-color url(../../img/tick02.png) no-repeat center center;
            border-color: $black-color;
          }
        }

      }

      .name {
        border: 1px solid $color1;
        padding: 7px 15px;
        display: block;
        margin-bottom: 6px;
        border-radius: 10px;
        font-weight: bold;
        line-height: 30px;
        cursor: pointer;


        .circle {
          border: 1px solid $color1;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          display: inline-block;
          margin: 0px 8px 0px 0;
          position: relative;
          top: 5px;

        }
      }
    }

    &.lender-list-two {
      li {
        .name {
          border: 0;
          border-radius: 0;
          border-bottom: 1px solid $color1;
          padding: 4px 0;
        }
      }
    }
  }

  .more-lenders {
    cursor: pointer;
  }
  .lenderbutton{
    position: sticky;
    bottom: 0;
  }
}



.form-style {

  fieldset {
    border: 1px solid $color1;
    border-radius: 10px;


    legend {
      float: none;
      display: inline-block;
      background: transparent;
      width: initial;
      padding: 0 10px;
      text-align: left;
      margin-bottom: 0;
      font-size: 1.2rem;
    }
  }


  .drop-down-multi {
    .choices__list--multiple {
      .choices__item {
        background: $color1;
        border-color: #d19e18;
      }
    }

    box-shadow: none;

    .choices__inner {
      padding: 0 10px;
      border-radius: 10px;
      background: $white-color;
      border: 1.5px solid $color1;

      .choices__input {
        background: transparent;
      }

      input {
        border: none;
        padding: 0;
        height: 55px;
        margin-bottom: 0;

        &:focus {
          box-shadow: none;
        }
      }
    }

  }

  &.form-body {
    padding: 100px;
  }

  .title {
    font-size: 1.3rem;
    font-weight: 600;
  }

  input,
  select {
    border: 1.5px solid $color1;
    font-size: 1.2rem;
    height: 55px;
    border-radius: 10px;
    padding: 10px 15px;

    &:focus {
      box-shadow: rgba(247, 176, 44, 0.3) 0px 0px 0px 3px;
    }
  }

  .contactinfo {
    input {
      background: $white-color url("../../img/guaranteedprivacy.png") no-repeat right center;
      background-size: 90px;
    }
  }

  .sign-sec {
    h5 {
      font-size: 16px;
    }

    .clearbutton {
      background:  $color1;
      border: none;
      outline: 0;
      font-size: 14px;
      padding: 6px 15px;
      border-radius: 5px;
    }


    // .signbox {

    //   canvas {
    //     max-width: 600px;
    //     height: 200px;
    //     border: 1px solid #d5d5d5;
    //     border-bottom: 2px dotted $black-color;
    //     background: $white-color url(../../img/signhere.png) no-repeat center center;
    //   }



    // }


    .signbox {
      max-width: 600px;
      margin: 0 auto;
    
      canvas {
        width: 100%;
        height: 200px;
        border: 1px solid #d5d5d5;
        border-bottom: 2px dotted #000; /* Use a specific color code */
        background: #fff url('../../img/signhere.png') no-repeat center center; /* Ensure the image path is correct */
      }
    }
    
    

  }

  .sign-check {
    .checkinput {
      width: 20px;
      height: 20px;
      position: relative;
      top: 6px;

    }

    label {
      font-size: 1rem;
    }

    text-align: justify;
  }

  p {
    font-size: 13px;
  }

  .list-point {
    li {
      padding-bottom: 6px;
      font-size: 13px;
      position: relative;
      padding-left: 26px;

      &::before {
        content: "";
        background: url("../../img/arrows_more_up.png")no-repeat;
        background-size: 18px;
        content: "";
        display: block;
        width: 25px;
        height: 25px;
        position: absolute;
        left: 0;

      }
    }
  }
}

.lookup-btn {
  height: 55px;
  background: #2C2C2C;

  span {
    color: $white-color;
    font-size: 1.2rem;
  }
}

/* animation class and keyframes */
.overflow-hidden {
  overflow: hidden;
}

.drop-in {
  animation: drop-in 1s ease 200ms backwards;
}

.drop-in-2 {
  animation: drop-in 1200ms ease 500ms backwards;
}

.drop-in-3 {
  animation: drop-in 2000ms ease 100ms backwards;
}

@keyframes drop-in {
  from {
    opacity: 0;
    transform: translateY(100px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.circle {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: stroke 5s ease-out infinite forwards;
}

.question {
  h3 {
    font-size: 1.5rem;
  }

  .btn-option {
    .option_but {
      background: $color7;
      display: inline-block;
      font-size: 1.2rem;
      color: $color_1;
      border-radius: 30px;
      cursor: pointer;

      position: relative;
      overflow: hidden;
      text-align: center;
      padding: 16px 15px;

      span {
        display: inline-block;
        vertical-align: middle;
      }




    }

    input {
      position: absolute;
      display: none;
    }

    input:checked+label {
      background: #bb1717;
    }
  }

  .slect_bank_item {

    .bank_name {
      margin-bottom: .6rem;
      text-align: left;
      background: #D6EBFB;
      padding: 9px 3px;
      border-radius: 5px;

      &:hover {
        background: #c9e2f5;
        border-radius: 5px;

        label {
          span {
            font-weight: 600;
          }
        }
      }

    }

    label {
      transition: 0.2s ease-in;
      font-size: 16px;
      line-height: 20px;
      background: url(../../img/check_circle-blank.png) no-repeat;
      background-position: 5px 5px;
      color: #004477;
      display: block;
      cursor: pointer;

      span {
        padding-left: 42px;
        display: inline-flex;
        height: 35px;
        align-items: center;
      }
    }

    .hiden_chekbox {
      visibility: hidden;
      opacity: 0;
      position: absolute;
    }

    font-size: 0.8rem;

    .hiden_chekbox:checked~.bank_name {
      label {
        background: url(../../img/check_circle.png);
        color: #004477;
        background-repeat: no-repeat;
        background-position: 5px 5px;
        transition: 0.2s ease-in;

        span {
          font-weight: 600;
        }

      }
    }
  }
}

.backbtn {
  outline: none;
  background: none;
  border: none;
}






@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}



.slide-top {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-top {
  0% {

    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(80);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

/****************************************************/



.slide-right {
  -webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}







.featured {
  background: $color1;

  .box-featured {
    background: $color1;
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    border: 3px solid $color_1;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    img {
      height: 40px;
    }
  }
}


.faqsection {
  background: #262626;

  h2 {
    color: $color_1;
  }

  .accordion-button {
    font-weight: bold;
    background-color: #262626;
    color: $white-color;

    &::after {
      background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>");
      ;
    }

    &:not(.collapsed) {
      background: $color1;
      color: $black-color;
    }
  }

  .accordion-item {
    background: #262626;
    color: $white-color;
  }

}


.uploads-section {
  margin-top: 62px;


  .uploads {
    border: 2px solid $black-color;
    padding: 15px;
    border-radius: 5px;
    font-size: 1.3rem;
  }

  .id_upLoad{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff !important;
    padding: 13px;
  }
  .upl-btn {
    padding: 10px 2px;
    background: $color1;
    border: 0;
    border-radius: 5px;
    position: relative;
    font-size: 1.2rem;
    input {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      right: 0;
       opacity: 0;
    }


  }
  .up-wrap {
    border: 3px dashed  $color1;
    border-radius: 20px;
    height: 210px;
    position: relative;
    text-align: center;
    display: flex;
    font-size: 3rem;
     justify-content: center;
     align-items: center;
     .upload-text {
      font-size: 1.2rem;
     }
     img {
      border-radius: 10px;
     }
    .imgloader {
      position: absolute;
      background: #ffffff57;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
       
      .progress {
        width: 80%;
        margin-top: 9rem;
        
        
        .progress-bar {
          animation: profill 2s ease-in 100ms  both  ;
        }
        @keyframes profill {
          0% {
            width: 0%;
          }
        
          50% {
            width: 100%;
          }
        }
      }

      
      

    }
  }
}


.vehicle-srtop{
  @extend .uploads-section;
}



.logo-two-wrap {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;

  .logohp {

    img {
      height: 40px;
    }
  }

  .iconmenu {
    color: $white-color;
    font-size: 1.3rem;

  }

}


.wrap-app {
  max-width: 700px;
  margin: 0 auto;
}

.dashboard-menu-wrap {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;

  .dashmenu-title {
    font-size: 1.5rem;
    margin-bottom: 26px;
  }

  .dash-menu-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0;

    li {
      margin-bottom: 50px;

      &:nth-child(2) {
        .icon {
          background: #FFD4D4;
        }
      }

      &:nth-child(3) {
        .icon {
          background: #BAF9FF;
        }
      }

      &:nth-child(4) {
        .icon {
          background: #F0C3FF;
        }
      }

      &:nth-child(5) {
        .icon {
          background: #FFE39B;
        }
      }

      &:nth-child(6) {
        .icon {
          background: #FFD9B7;
        }
      }

      &:nth-child(8) {
        .icon {
          background: #6CD86A;

          svg {
            color: red;
          }
        }
      }
    }
  }

  a {
    color: #464646;

    &:hover {

      color: $black-color;

    }
  }

  .dash-menu {
    background: $white-color;
    border-radius: 10px;
    cursor: pointer;
    position: relative;

    .pending {
      position: absolute;
      background: #FFE8E4;
      color: #F74020;
      top: -23px;
      line-height: 0;
      padding: 10px;
      border-radius: 10px;
      margin: 0 auto;
      left: 0;
      right: 0;
      width: 70px;
      font-size: 10px;
      text-align: center;
    }

    &.menu-two {
      .icon {
        background: #DADEFF;

      }


    }

    &.menu-three {
      .icon {
        background: $color4;
      }
    }

    &.menu-four {
      .icon {
        background: $color5;
      }
    }

    .icon {
      width: 100px;
      height: 100px;
      border-radius: 10px;
      background: #DADEFF;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 10px auto;
      position: relative;
      box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

      svg {
        width: 60px;
        height: 60px;
      }

      .complt-status {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid $color3;
        display: block;
        background: $color3 url('../../img/icon-menu.png') center;
        background-size: 18px;
        position: absolute;
        top: -7px;
        right: -4px;

        &.active {
          background: url(../../img/tick.png);
          background-position: center center;
        }
      }

    }


    h4 {
      font-size: 1rem;
      font-weight: 400;
      text-align: center;
    }

    .sub-text {
      font-size: .8rem;
    }

  }
}





$color_1: #999;

@keyframes animate1 {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes animate2 {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(100%);
  }
}

@keyframes animate3 {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes animate4 {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(-100%);
  }
}

.btn-eff {
  position: relative;
  overflow: hidden;
  border-radius: 10px;

  small {
    &:nth-child(1) {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 4px;
      background: linear-gradient(to right, #e4e4e418, $color10);
      animation: animate1 2s linear infinite;
    }

    // &:nth-child(2) {
    // 	position: absolute;
    // 	top: 0;
    // 	right: 0;
    // 	height: 100%;
    // 	width: 2px;
    // 	background: linear-gradient(to bottom, #e4e4e418,  $color10);
    // 	animation: animate2 2s linear infinite;
    // 	animation-delay: 1s;
    // }
    &:nth-child(3) {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 4px;
      background: linear-gradient(to left, #e4e4e418, $color10);
      animation: animate3 2s linear infinite;
    }

    // &:nth-child(4) {
    // 	position: absolute;
    // 	top: 0;
    // 	left: 0;
    // 	height: 100%;
    // 	width: 2px;
    // 	background: linear-gradient(to top, #e4e4e418,  $color10);
    // 	animation: animate4 2s linear infinite;
    // 	animation-delay: 1s;
    // }
  }
}


.connect-account {
  padding-bottom: 30px;

  .btn-comm2 {
    max-width: 350px;
    display: block;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

    span {
      font-size: 1.2rem;
    }
  }

  .title {
    font-size: 1.5rem;
  }


}

.loader {
  height: 100vh;
  text-align: center;
  background: $color1;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;

  .inner {
    max-width: 800px;
    margin: 0 auto;
    color: $color_1 ;
    font-size: 1.3rem;

    h2 {
      font-size: 1.5rem;
      color: #fff;
      line-height: 2.3rem;
    }
  }

}







.form-section {
  .form-control {
    height: 55px;
    border-color: $color11;
  }

  .title {
    font-size: 1.2rem;

  }

  fieldset {
    border: 1px solid $color11;
    border-radius: 10px;
    background: $white-color;


    legend {
      float: none;
      display: inline-block;
      background: transparent;
      width: initial;
      padding: 0 10px;
      text-align: left;
      margin-bottom: 0;
      font-size: 1.3rem;
    }
  }

  .btn-confrm {
    max-width: initial;
  }

  .poscode {
    .btn-postcode {
      background: $color3;
      color: $white-color;
      max-width: 250px;
    }
  }

  .car-regnumber {
    background: url("../../img/gb.png") no-repeat;
    background-size: 40px;
    padding-left: 60px;


  }

  .btn-question {
    max-width: initial;
  }

  .option-type {


    cursor: pointer;

    input {
      position: absolute;
      opacity: 0;

      &:checked+label {
        background: $color8;
        color: $white-color;
      }
    }

    label {
      background: #c9edff;
      box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
      padding: 20px 10px;
      font-size: 1.2rem;
      font-weight: bold;
      width: 100%;
      cursor: pointer;
      border-radius: 5px;

    }
  }

  .drop-down-multi {
    position: relative;
    text-align: left;
    z-index: 8;

    .choices__input {
      background: transparent;
    }

    .choices__inner {
      background: $white-color;
      border-radius: 10px;
      padding: 10px;
      border-color: #a2d0f9;
    }

    .choices__list--multiple {
      .choices__item {
        font-size: 14px;
        background: $color8;

      }
    }
  }

}

.id-uploads {
  .body-id {
    border: 2px solid #cacaca;
  }

  .btn {
    font-size: 1rem;
  }

  .accordion-item {
    .accordion-button {
      background: #35C6F4;
      border-radius: 5px !important;
      color: $white-color;
      font-weight: 600;
      font-size: 1.2rem;

      &.btn-licence {
        background: $color9;
      }

      &.btnpassport {
        background: $color12;
      }
    }

    .btn-uploads {
      .btn {
        font-size: 1.1rem;

        i {
          font-size: 1.4rem;
        }
      }
    }
  }

  .brows {
    position: relative;

    input[type="file"] {
      position: absolute;
      opacity: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

  }

}

.loader-logo {
  img {
    height: 60px;
  }
}

.kycupload {
  .text-start {
    font-size: 1.2rem;
    line-height: 22px;
  }

  .id-uploads {

    .accordion {
      --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }

  }

  .btn-comm2 {
    display: inline-block;
  }
}


.back-btn {
  font-size: 1.2rem;
  cursor: pointer;
}


 


.credimodal {
  max-width: 400px !important;
  margin: 0 auto !important;
}

.welc-text {
  background: #DFE6FF;
  margin-top: 3rem;

  h2 {
    color: $color2;
    font-size: 1.3rem;
  }

  p {
    line-height: 18px;
    color: #213067;


  }
}

.popup-dailog-box {
  position: relative;

  .text-info-details {
    font-size: 1rem;
  }

  .title {
    font-size: 1.2rem;
  }

  .btn-close {
    position: absolute;
    right: 14px;
    top: 14px;
    z-index: 5;
  }

  .bi {
    font-size: 1.5rem;
  }
}



.signcheck {

  font-size: 13px;

  .checker {
    width: 20px;
    height: 20px;
    display: block;
  }
}

.vehiclenumber {
  .btn-carnumber {
    
    @extend .lookup-btn;
   span {
    font-size: 1.5rem   ;
   }
  }
 

  .bg-inner {
    background: #FFEFC3 url(../../img/bg-building.png) no-repeat bottom center;

    .wrap-number {
      transform: translate(0px, -48px);
      margin-bottom: 4rem;

      .car-img {
        img {
          height: 120px;
        }
      }
    }

    h1 {
      color: #E3740D;
      font-size: 1.6rem;
      position: relative;
      z-index: 1;
    }
    .h4 {
      font-size: 1.3rem;
    }

    .number-plate {
      border: 2px solid $black-color;
      border-radius: 10px;
      background: $white-color;

      .flag {
        width: 15%;
        background: #2145DD url(../../img/flag.png) no-repeat center center;
        border-radius: 8px 0px 0px 8px;
        background-size: 20px;
      }

      .input-wrap {
        width: 70%;

        input {
          height: 55px;
          border-radius: 0 10px 10px 0px;
          font-size: 1.2rem;
          font-weight: bold;
          border: none;
          box-shadow: none;
        }
      }

      .add-more {
        width: 15%;
        background: $black-color;
        color: $white-color;
        text-align: center;
        font-size: 1rem;
        border-radius: 5px;
        height: 32px;
        margin-top: 10px;
        line-height: 33px;
        margin-right: 6px;
        cursor: pointer;
      }

    }

    .num-plate-list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      li {

        width: 32%;

        .uk-numberplate {
          background: $white-color url("../../img/flag-2.png") left center no-repeat;
          background-size: 20px;
          border: 1px solid $black-color;
          padding: 4px 10px;
          font-weight: bold;
          border-radius: 5px;
          padding-left: 30px;

          text-align: center;

        }
      }
    }


  }
}

.bg-form {
  background: #ffefc3;
}






/*================================================
 HEADER END
==================================================*/

/*================================================
04 - BANNER
==================================================*/




.arrows path {
  stroke: #2994D1;
  fill: transparent;
  stroke-width: 3px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0
  }

  40% {
    opacity: 1
  }

  80% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

@-webkit-keyframes arrow

/*Safari and Chrome*/
  {
  0% {
    opacity: 0
  }

  40% {
    opacity: 1
  }

  80% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s;
  /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s;
  /* Safari 和 Chrome */
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
  /* Safari 和 Chrome */
}


.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 175, 14, 0.947);
  }

  50% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
  }
}


.forms-fieldset {
  background: rgb(247, 247, 255);

  .headline {
    font-size: 18px;
    font-weight: 700;
  }
}

.list-style {
  li {
    list-style-type: disc !important;
  }
}

.notesec {
  font-size: 13px;
}

ol li {
  list-style-type: decimal;
}

.bg-gray {
  background-color: #f0f0ff;
}

.anchor {
  scroll-margin-top: 100px;
}

.top-head {

  h2,
  .h2 {
    font-size: 2.2rem;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
}

.secondlabel {
  max-width: 300px;
  margin: 0 auto;
  font-size: 1rem;
}

.agree-scroll {
  width: 100%;
  height: 180px;
  overflow-y: scroll;
  padding: 20px;
  background-color: #fff;
}


.stickyBtn {
  position: fixed;
  width: 100%;
  z-index: 238;
  bottom: 0;
  background: #000;
  padding: 20px 0;
}

/*================================================
footer start
==================================================*/
footer {
  background: #1B1B1B;
  color: $color_1;
  font-size: 1rem;

  .ft-logo {
    img {
      height: 40px;
    }
  }

  ul {
    li {
      display: inline-block;

      a {
        color: #9b9b9b;
        padding: 0 15px 0 0;
      }
    }
  }
}


/*================================================
footer end
==================================================*/



.flip-vertical-fwd {
  -webkit-animation: flip-vertical-fwd 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: flip-vertical-fwd 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

.quastion-area {
  margin-top: 80px;
  margin-bottom: 80px;

  .accordion-item {
    border: 1px solid #000;

    &:not(:first-of-type) {
      border: 1px solid #000;
    }
  }

  .accordion-button {
    font-size: 20px;
    color: $black-color;
    text-align: center;

    &::after {
      background: url(../../img/down-arrow.png) no-repeat center;
      width: 26px;
      background-size: 15px;
    }

    &:not(.collapsed) {
      color: $white-color;
      background: $color1;

      &::after {
        background: url(../../img/down-arrow.png) no-repeat center;
        width: 26px;
        background-size: 15px;
      }
    }
  }

  .accordion-body {
    p {
      font-size: 18px;
    }
  }
}

.review-section {
  background: #ebf0ff;
  padding: 80px 0 40px 0;

  .swiper-wrapper {
    padding: 10px 30px 30px 0px;
  }

  .feefo-review {
    background: $color1;
    border-radius: 10px;
    padding: 20px;
  }

  .review-box {
    border: 1px solid #eee;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: $color1;
    border-radius: 10px;
    height: 180px;
    transition: all ease-in 0.2s;

    &:hover {
      border-bottom: 5px solid $color1;
      transform: rotate(-1deg);
      transition: all ease-in 0.2s;
    }

    h3 {
      font-size: 16px;
    }

    .starimg {
      height: 25px;
    }
  }
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  .loader-box {
    .loader {
      -webkit-animation: rotate-center 0.8s linear infinite both;
      animation: rotate-center 0.8s linear infinite both;
      transform-origin: center;
      width: 80px;
      height: 80px;
    }
  }
}

// Progress Circle

$borderWidth: 10px;
$animationTime: 0.5s;
$border-color-default: #eaeaea;
$border-color-fill: $color2;
$size: 120px;
$howManySteps: 100; //this needs to be even.



// End Progress

@-webkit-keyframes flip-vertical-fwd {
  0% {
    -webkit-transform: translateZ(160px) rotateY(180deg);
    transform: translateZ(160px) rotateY(180deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
    opacity: 1;
  }
}

@keyframes flip-vertical-fwd {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(160px) rotateY(180deg);
    transform: translateZ(160px) rotateY(180deg);
  }

  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.fade-in {
  -webkit-animation: fade-in 0.4s ease-in both;
  animation: fade-in 0.4s ease-in both;
}

.shake-vertical {
  -webkit-animation: shake-vertical 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
  animation: shake-vertical 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
}

/*================================================
Keyframe
==================================================*/

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes shake-vertical {

  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }

  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px);
  }

  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px);
  }
}

@keyframes shake-vertical {

  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }

  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px);
  }

  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px);
  }
}

/*================================================
07 - MEDIA QUERY 
==================================================*/
@media screen and (max-width: 1800px) {}

@media screen and (max-width: 1200px) {}

@media screen and (max-width: 991px) {

  .wrap-loader {
    .panel-banner {
      max-width: 700px;
    }
  }


}

@media screen and (max-width: 760px) {

  .form-style {
    &.form-body {
      padding: 30px;
    }

    .title {
      font-size: 1.5rem;
    }

    input,
    select {
      height: 55px;
    }

    .sign-sec {
      .signbox {
        canvas {
          max-width: 100%;
        }
      }
    }
  }

  .highlight-section {
    .highlights {
      img {
        height: 50px;
      }
    }
  }
}

@media screen and (max-width: 590px) {}

@media screen and (min-device-width: 470px) and (max-device-width: 768px) {}





@media screen and (max-width: 470px) {

  html {
    font-size: .9rem;
  }

  .strip {
    font-size: .8rem;

    img {
      height: 32px;
    }
  }

  .home-top {
    margin-top: 65px;
  }

  .header-wrap {
    .logo {
      img {
        height: 35px;
      }
    }


  }

  .home-banner {
    .bnr-img {
      text-align: center;

      img {
        height: 180px;
      }
    }

    .bnr-text {
      max-width: 380px;
      margin: 0 auto;

      .payout-wrap {
        .payout-inner {
          max-width: 160px;
        }
      }

      .payout {
        font-size: 1.4rem;

        .amount {
          svg {
            width: 118px;
            top: -54px;
            left: -4px;
          }
        }
      }

      .main-text {
        h1 {
          font-size: 3rem;
          line-height: 3.1rem;
        }

      }

      p {
        font-size: 1rem;
      }
    }
  }

  .btn-start {
    width: 100%;

  }

  .highlights {
    img {
      height: 44px;
    }
  }



  .question {
    .slect_bank_item {
      label {
        padding: 8px 0;

        span {
          height: initial;
        }
      }
    }
  }

  .loader {
    .inner {
      h2 {
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }
  }

  .featured {
    .box-featured {
      height: 100px;
    }
  }

  .vehiclenumber {
    .bg-inner {
      .num-plate-list {
        li {
          width: 48%;
        }
      }
    }
  }

  .form-style {
    fieldset {
      legend {font-size: 1.5rem;}
    }
  }

  
}

@media only screen and (max-width: 790px) and (min-width: 540px) {}

@media only screen and (max-width: 540px) and (min-width: 470px) {}
}