.PCP_HDSC_V5 {
  .signature-section {
    h1 {
      color: #515151;
    }
.underline-text{
  text-decoration: underline !important;
}
    .sign-sec {
      background-image: url(../img/bg8588.png);
      background-position: center bottom;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      border-radius: 20px;

      h5 {
        font-size: 16px;
      }

      .clearbutton {
        background: #bfbfbf;
        border: none;
        outline: 0;
        font-size: 14px;
        padding: 6px 30px;
        ;
        border-radius: 5px;
      }

      .signbox {
        canvas {
          width: 100%;
          height: 300px;
          border: 1px solid #d5d5d5;
          border-bottom: 2px dotted black;
          background: white url(../img/signhere.png) no-repeat center center;
          border-radius: 10px;
        }
      }

      .signature-footer {
        p {
          font-size: 12px;
        }

        a {
          color: black;
        }
      }
    }

  }



  .shine {
    position: relative;
    overflow: hidden;

    &::before {
      position: absolute;
      top: 0;
      left: -500px;
      content: "";
      width: 120px;
      height: 500px;
      background: rgba(255, 255, 255, 0.6);
      transform: skew(-50deg);
      /* transition: 1s; */
      animation: shine 3s ease infinite;
    }

    @keyframes shine {
      from {
        left: -100px;
      }

      to {
        left: 655px;
      }
    }

  }


  .pulse {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 #f39d56;
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }


  .confettiCanvas {
    #confettiCanvas {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .is_sticky {
    position: sticky;
    bottom: 10px;
    z-index: 999;
  }

  .text-cicle {
    position: relative;

    svg {
      position: absolute;
      fill: transparent;
      left: 0;
      width: 150px;
      height: 150px;
      font-size: 12px;
      z-index: 99;
    }
  }
}