@import "node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Sans:ital,wght@0,100..800;1,100..800&display=swap');
// @import "./assets/css/ADV1_Contact.scss";
// @import "./assets/css/ADV_1.scss";
// @import "./assets/css/documentview.scss";
// @import "./assets/css/followup.scss";
// @import "./assets/css/Idupload.scss";
// @import "./assets/css/loa.scss";
// @import "./assets/css/NotFound.scss";
// @import "./assets/css/Questionnaire.scss";
// @import "./assets/css/split_1.scss";
// @import "./assets/Signature/css/Signature.scss";
// @import "./assets/Thankyou/css/Thankyou.scss";
// @import './assets/css/Modal.scss';

body {
  font-family: Titillium Web, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #000;
}



