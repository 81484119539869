.PCP_HDSC_V5 {
  $white-color : white;
  $black : black;
  $color1 : #c4941c;
  $color2 : #EDC55C;
  $color3 : rgb(170, 170, 170);
  $shdow1 : rgba(0, 0, 0, 0.24) 0px 3px 8px;

  /*================================================
02 - Sass Mixins
==================================================*/
  @mixin commButton {
    align-items: center;
    background-clip: padding-box;
    background-color: $color2;
    border: 1px solid transparent;
    border-radius: .5rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: black;
    cursor: pointer;
    display: inline-flex;
    font-size: 1.2rem;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 3.8rem;
    padding: calc(1rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;

    &:focus {
      background-color: $color1;
      box-shadow: rgba(0, 0, 0, 0.2) 0 4px 12px;
    }

    &:hover {
      background-color: $color1;
      box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
      transform: translateY(-2px);
    }

    &:active {
      background-color: $color1;
      box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
      transform: translateY(0);
    }

    i {
      margin-left: 10px;
    }
  }

  @mixin commbg1 {
    background-image: linear-gradient(to bottom, #e1f2fd, #ebf5fe, #f3f7ff, #fafbff, #ffffff);
    background-repeat: no-repeat;
    background-position: bottom center;
  }

  /**/
  ///////////////////////////////////////////////////////////////////////////
  .com-button {
    @include commButton;

    &.skipbtn {
      background: $white-color;
      color: $black;
      border: 1px solid $color1;
      font-size: 1rem;
    }
  }

  .car-regi-middle {
    h1 {}

    h2 {
      font-size: 1.2rem;
    }

    .car-box {
      box-shadow: $shdow1;
      border: 1px solid $color1;
      border-radius: 10px;
      background: $white-color;

      .text1 {
        font-size: .8rem;
      }
    }

    .numb-plate-wrap {
      display: flex;
      gap: 10px;

      .number-plate {
        width: 100%;
        border-radius: 10px;
        background: white;

        .flag {
          width: 50px;
          background: #2145DD url(../img/flag.png) no-repeat center center;
          border-radius: 8px 0px 0px 8px;
          background-size: 20px;
        }

        .input-wrap {
          border: 1px solid rgb(143, 143, 143);
          border-radius: 0 10px 10px 0px;

          input {
            height: 55px;
            border-radius: 0 10px 10px 0px;
            font-size: 1.2rem;
            font-weight: bold;
            border: none;
            box-shadow: none;

            &::placeholder {
              font-weight: 400;
              font-size: 1rem;
            }
          }
        }
      }

      .add-more {
        width: 100px;
        background: black;
        color: white;
        text-align: center;
        font-size: 1rem;
        border-radius: 10px;
        line-height: 33px;
        border: none;
        cursor: pointer;
      }
    }
  }

  .wraper-option {
    font-size: 1.2rem;
    position: relative;

    .loader {
      background: #ffffffbf;
      align-items: center;
    }

    &.bank-home {
      display: flex;
      flex-wrap: wrap;
      gap: 4px 14px;

      .option-btn {
        background: $white-color;
        width: 24%;
        padding-left: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
      }
    }

    .option-btn {
      padding: 10px 10px;
      // background: $color3;
      position: relative;
      border-radius: 10px;
      border: 1px solid #cf9b00;
      padding-left: 20px;
      cursor: pointer;
      text-align: center;
      background-color: white;

      &.bg-btn {
        background: $color1;
        width: 50%;
      }
      .carreglabel{
        margin-left: 34px;
        margin-top: 9px;
      }
      label {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        input {
          opacity: 0;
        }

        &:hover {
          cursor: pointer;
        }
      }

      &.current {
        background: $color1;
        border: 1px solid $color1;
        color: $white-color;
        font-weight: bold;

        .check {
          border: 1px solid $color1;
          background: $black url(../img/tick03.png) no-repeat center 6px;
          background-size: 12px;
        }
      }

      .check {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: inline-block;
        border: 1px solid $color1;
        margin-right: 10px;
        position: relative;
        top: 5px;
      }
    }

    &.banklist {
      .option-btn {
        font-size: 16px;
        text-align: start;
        display: flex;
        align-items: center;

        .check {
          top: 0;
        }

        .textcont {
          width: 80%;
        }
      }
    }
  }

  .title {
    font-size: 1.3rem;
  }

  .loader-div {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    left: 0;
    top: 0;
    z-index: 99;
    background: #ffffffb6;
    display: flex;
    align-items: center;
    justify-content: center;

    &.loader-fix {
      position: fixed;
    }
  }

  .fade-scale {
    transform: scale(0);
    -webkit-transform: scale(0);
    -o-transform: scale(0);
    opacity: 0;
    -webkit-transition: all .25s linear;
    -o-transition: all .25s linear;
    transition: all .25s linear;

    &.show {
      opacity: 1;
      transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
    }
  }

  .upload-box {
    cursor: pointer;
    @extend .car-box;

    .icon-circle {
      background: $color1;
      height: 40px;
      width: 40px;
      text-align: center;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 1.4rem;
        color: $white-color;
      }
    }

    &.updated {
      &.nation-card {
        .icon-circle {
          background: $color1;
          color: $white-color;
        }
      }

      &.license {
        .icon-circle {
          background: $color1;
          color: $white-color;
        }
      }

      &.passport {
        .icon-circle {
          background: $color1;
          color: $white-color;
        }
      }
    }

    h3 {
      font-size: 1.3rem;
    }

    .click-upload {
      background: $color1;
      color: $white-color;
      border-radius: 30px;
      text-align: center;
      font-size: 1rem;
      display: inline-block;
    }

    .fileupload {
      @extend .click-upload;
      background: #b0f3b7;
      color: #0C9D1B;
    }

    .forward-angle {
      margin-left: auto;
      font-size: 1.4rem;
      margin-right: 15px;
    }
    .svg-x{
      background: none;
      border: 0px;
    }
  }

  .file-uploads-box {
    background: #e5e5e5;
    border-radius: 15px;
    border: 1px dashed $color1;
    position: relative;

    .iput-file {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
    }

    i {
      font-size: 4rem;
      color: #a7a7a7;
    }

    .choose-file {
      background: $color1;
      border-radius: 10px;
      color: $white-color;
      font-size: 1.2rem;
    }

    .file-text {
      font-size: 1rem;
    }
  }

  .file-upload-body {
    .close-btn {
      margin-left: auto;

      &:hover {
        i {
          transform: rotate(180deg);
          transition: ease-in 0.3s;
          transform-origin: center;
        }
      }
    }

    .com-button {
      font-size: 1.1rem;
      min-height: inherit;

      &.takephoto {
        padding: 13px;
      }

      i {
        font-size: 1.2rem;
      }
    }

    .progress {
      width: 100%;

      .progress-bar {
        animation: profill 2s ease-in 100ms both;
      }

      @keyframes profill {
        0% {
          width: 0%;
        }

        50% {
          width: 100%;
        }
      }
    }
  }

  .upload-sigo {
    h6 {
      font-size: 1.3rem;
    }
  }

  .success-box {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
    background: $white-color;
  }

  .sub-text2 {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  .clearfix::after {
    content: "";
    display: table;
    clear: both;
  }


  //  Mediquery
  @media screen and (max-width: 490px) {
    .sub-text2 {
      font-size: 1.1rem;
      line-height: 1.3rem;
    }

    .upload-box {
      h3 {
        font-size: 1rem;
      }

      .click-upload {
        font-size: .8rem;
      }
    }

    .car-regi-middle {
      h2 {
        font-size: 1rem;
      }
    }

    .title2 {
      font-size: 1.5rem;
    }

    .car-regi-middle {
      .numb-plate-wrap {
        .number-plate {
          .flag {
            background-size: 17px;
          }

          .input-wrap {
            input {
              height: 45px;
            }
          }
        }
      }
    }

    .upload-sigo {
      img {
        height: 30px;
      }

      h6 {
        font-size: .8rem;
      }
    }

    .loader-div {
      img {
        height: 80px;
      }

      h4 {
        font-size: 1rem;
      }
    }

    .success-box {
      min-height: 240px;

      img {
        height: 80px;
      }
    }

    .secure-text {
      font-size: .8rem;
    }


    .img-fluid {
      &.banner-img {

        height: 180px;

      }
    }
  }
}