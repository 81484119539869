/*
*******************************************
Template Name: ClaimLion Law
Author: Anush
Date: 08-02-2024
Template By: Vandalay Designs
Copyright 2020-2024 Vandalay Business Solution
* This file contains the styling for Free Debt Assessment, this
is the file you need to edit to change the look of the
site.
This files contents are outlined below >>>>
*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************
*/

/*========================================= */
/* --------------------------------
Primary style
-------------------------------- */
// @import "animate.min";
// *, *::after, *::before {
//     box-sizing: border-box;
// }
// html {
//     scroll-behavior: smooth;
// }
// body {
//     padding: 0;
//     margin: 0;
//     box-sizing: border-box;
// }
// a {
//     color: #283040;
//     text-decoration: none;
// }
/* --------------------------------
Patterns - reusable parts of our design
-------------------------------- */
/* --------------------------------
Main Components
-------------------------------- */
.PCP_HDSC_V5 {
    .css-26l3qy-menu{
        position: sticky;
    }

    .__PCPtcg {
        .cursor_pointer {
            cursor: pointer;
        }

        $background_color_1: #ffffff;
        $background_color_2: #EDC55C;
        $background_color_3: #18478b;
        $background_color_4: #18478b;
        $background_color_5: #18478b;
        $background_color_6: #f9fbfc;
        $background_color_7: #efefef;
        $background_color_8: #f2f4f5;
        $background_color_9: #cfcfcf;
        $color_1: #18478b;
        $color_2: #18478b;
        $color_3: #ffff;
        $color_4: #18478b;
        $color_5: #979797;
        $font-normal: normal;
        // ======border===========
        $box-shadow1: 0 0 0 0.2rem #5ca8ff9d;
        $border-style2: 4px solid #23df6a;
        $border-style3: 2px solid #d5d5d5;
        $border-style4: 1px solid #b4b4b4;
        $border-style5: 1px solid #2f3996;

        font-family: 'Poppins',
        sans-serif;

        .show {
            display: block;
        }

        .hide {
            display: none;
        }

        .secur-ul {

            align-items: center;
            gap: 10px;

            @media (max-width: 510px) {
                font-size: 13px;
            }

            li {
                list-style-type: none;

                //width: 50%;

                &:last-child {
                    // border-left: 1px solid #4d4d4d;

                }
            }
        }

        .error_msg {
            background-color: #df0707d7;
            color: white !important;
            display: block;
            padding: 0px 10px;
            border-radius: 5px;
            margin: 5px 0px;
            font-size: 14px;
            text-align: center;
            font-weight: 500;
        }

        .eligibi-claim-loader {
            min-height: 650px;
            display: flex;
            align-items: center;
            justify-content: center;

            .inner {
                max-width: 600px;
                margin-bottom: auto;

                @media (max-width: 510px) {
                    margin-bottom: inherit;
                }
            }
        }

        .leder-section {
            .title {
                font-size: 1.5rem;
            }
        }


        .slider {
            background-color: #ececec;
            display: flex;
            justify-content: center;
            min-height: 100vh;
            align-items: center;
        }

        .content_bG {
            opacity: 1;
            transition: opacity 0.3s ease-in-out;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            height: 100%;

            .pic_slide {
                width: 285px;

                @media (max-width: 510px) {
                    width: 185px;
                }
            }
        }

        .cover {
            position: fixed;
            height: 100vh;
            width: 100%;
            top: 0;
            left: -100vw;
            z-index: 8;
        }

        .cover.cover3 {
            position: absolute;
            height: 100vh;
            width: 100%;
            top: 0;
            left: -100vw;
            z-index: 8;
        }

        .transition.slide .cover1 {
            background-color: skyblue;
            animation: slide 1s cubic-bezier(0.42, 0, 0.58, 1) forwards;
        }

        .transition.slide .cover2 {
            background-color: #004d8b;
            animation: slide 1s cubic-bezier(0.42, 0, 0.58, 1) forwards;
            animation-delay: 0.3s;
        }

        .transition.slide .cover3 {
            background-color: #fff;
            animation: slide 1s cubic-bezier(0.42, 0, 0.58, 1) forwards;
            animation-delay: 0.6s;
        }

        .transition.slide .cover3 .content {
            opacity: 0;
        }

        @keyframes slide {
            from {
                left: -100vw;
            }

            to {
                left: 0;
            }
        }

        .bg_lol {
            background-image: linear-gradient(to left top, #ffffff, #fffbf0, #fff9e8, #fff6df, #fff6e0);
            transition: cubic-bezier(0.075, 0.82, 0.165, 1);
            position: relative;

        }

        @media (max-width: 760px) {
            .bg_lol {
                &::after {
                    display: none;
                }
            }
        }

        .col_grade_fix {
            background-color: #fff;
            background-image: linear-gradient(180deg, #EFF8FF 0%, #EFF8FF00 95%);

            .bg_sign {
                background: url(../img/Mobile-App-Hero-BG.png);
                background-position: 0px 30%;
                background-repeat: no-repeat;
                background-size: contain;
                opacity: 1;
                transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
                position: relative;

                @media (max-width: 1024px) {
                    background-position: 0px 50%;
                }

                @media (max-width: 767px) {
                    background-position: 0px 0%;
                }
            }

            //bg_sign
        }

        //col_grade_fix
        /*TOP NAVIGATION*/
        .sticky-header {
            &.sticky {
                background: white;
                top: 0;

                .topnav {
                    padding: 4px 0;

                    .logo {
                        height: 34px;
                    }
                }
            }
        }



        header {
            position: fixed;
            top: 1.2rem;
            width: 100%;
            z-index: 5;
            transition: 0.5s;
        }

        .topnav {
            //overflow: hidden;
            //width:80%;
            margin: auto;
            position: relative;
            transition: 0.5s;
            border-radius: 5px;
        }

        .topnav {
            display: flex;
            align-items: center;

            a {
                text-decoration: none;
            }

            .nav-menu {
                margin: 0;
                padding: 0;
                margin-left: auto;

                li {
                    display: inline-block;

                    a {
                        text-decoration: none;
                        display: block;
                        color: #000;
                        text-align: center;
                        padding: 10px;
                        margin: auto 1rem;
                        text-decoration: none;
                        font-size: 17px;
                        position: relative;
                    }
                }
            }

            &.responsive {
                .nav-menu {
                    display: block;
                }
            }

            @media (max-width: 768px) {
                .nav-menu {
                    display: none;
                    position: absolute;
                    top: 50px;
                    background: $background_color_1;
                    width: 100%;

                    li {
                        display: block;
                        width: 100%;

                        a {
                            text-align: start;
                            margin: 0;
                        }
                    }
                }
            }
        }

        .logo {
            height: 40px;
            border-radius: 5px;

            //padding: 14px 16px;
            @media (max-width: 768px) {
                // width: 130px;
                height: 30px;
            }
        }

        //logo
        #icon {
            float: right;
        }

        #active {
            display: flex;
            font-weight: bold;
        }

        #active:hover {
            color: #fff;
        }

        .topnav .icon {
            display: none;
        }

        .topnav a:hover,
        .dropdown:hover .dropbtn {
            color: $color_1;
        }

        .dropdown-content a:hover {
            background-color: #ddd;
            color: black;
        }

        .dropdown:hover .dropdown-content {
            display: block;
        }

        @media screen and (max-width: 1120px) {
            .logo {
                position: static;
            }

            .dropdown .dropbtn {
                padding: 14px 16px;
            }

            .topnav a:not(:first-child),
            .dropdown .dropbtn {
                display: none;
            }

            .topnav a.icon {
                float: right;
                display: block;
            }

            #active {
                display: none;
            }
        }

        @media screen and (max-width: 1120px) {
            .topnav.responsive {
                position: relative;
            }

            .topnav.responsive .icon {
                position: absolute;
                right: 0;
                top: 0;
            }

            .topnav.responsive a {
                float: none;
                display: block;
                text-align: left;
                // padding: 14px 16px;
            }

            .topnav.responsive .dropdown {
                float: none;
            }

            .topnav.responsive .dropdown-content {
                position: relative;
            }

            .topnav.responsive .dropdown .dropbtn {
                display: block;
                width: 100%;
                text-align: left;
            }
        }

        .cs_hero.cs_style_1.section_design1 {
            height: 100%;
            // background-color: #fff;
            // padding-bottom: 115px;
            position: relative;

            .cs_hero_wrap.cs_hero_wrap2 {
                height: 100%;
                width: 100%;
                padding: 90px 0 60px;
                position: relative;

                @media (max-width: 1300px) {
                    padding: 90px 0 60px;
                }

                @media (max-width: 1199px) {
                    padding: 90px 0;
                }

                @media (max-width: 980px) {
                    padding: 100px 0 40px;
                }

                @media (max-width: 780px) {
                    padding: 100px 0 40px;
                }

                @media (max-width: 510px) {
                    padding: 100px 0 45px;
                    width: 95%;
                    margin: 0 auto;
                }

                @media (min-width: 1730px) {
                    // height: 620px;
                    display: flex;
                    align-items: center;
                }

                @media only screen and (max-width: 1730px) and (min-width: 1198px) {
                    // height: 620px;
                    display: flex;
                    align-items: center;
                }

                @media only screen and (max-width: 1730px) and (min-width: 1398px) {
                    padding: 150px 0 20px;
                }

                @media (max-width: 1730px) {
                    // height: 620px;
                    display: flex;
                    align-items: center;
                }
            }

            .cs_hero_wrap {
                height: 100%;
                width: 100%;
                padding: 150px 0 60px;
                position: relative;

                @media (max-width: 1300px) {
                    padding: 150px 0 60px;
                }

                @media (max-width: 1199px) {
                    padding: 150px 0;
                }

                @media (max-width: 980px) {
                    padding: 80px 0 40px;
                }

                @media (max-width: 780px) {
                    padding: 80px 0 40px;
                }

                @media (max-width: 510px) {
                    padding: 80px 0 45px;
                    width: 95%;
                    margin: 0 auto;
                }

                @media (min-width: 1730px) {
                    // height: 620px;
                    display: flex;
                    align-items: center;
                }

                @media only screen and (max-width: 1730px) and (min-width: 1198px) {
                    // height: 620px;
                    display: flex;
                    align-items: center;
                }

                @media only screen and (max-width: 1730px) and (min-width: 1398px) {
                    padding: 150px 0 20px;
                }

                @media (max-width: 1730px) {
                    // height: 620px;
                    display: flex;
                    align-items: center;
                }

                // select options
                .selectoptions {

                    input[type=checkbox]+label,
                    input[type=radio]+label {
                        background: url(../img/uncheck.png) no-repeat left center;
                        background-position: 15px;
                        background-size: 20px;
                        border-radius: 3px;
                        background-color: $background_color_1;
                        color: #383838;
                        cursor: pointer;
                        border: 1px #e7b222 solid;
                        cursor: pointer;
                        display: inline-block;
                        // height: 40px;
                        height: auto;
                        padding: 15px 0;
                        width: 100%;
                        border-radius: 7px;
                        text-align: center;
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                        @media (min-width: 769px) {
                            width: 48%;
                            margin: 5px 1%;
                            float: left;
                        }


                    }

                    &.optionfull {

                        input[type=checkbox]+label,
                        input[type=radio]+label {
                            width: 100%;
                        }
                    }

                    input[type=checkbox]:checked+label,
                    input[type=radio]:checked+label {
                        background: url(../img/6586f16a1c68373142fe092b6f79903f.png) no-repeat left center;
                        background-position: 15px;
                        background-size: 20px;
                        border: 1px $background_color_2 solid;
                        cursor: pointer;
                        display: inline-block;
                        background-color: $background_color_2;
                        color: black;
                    }

                    label {
                        // float: left;
                    }

                    input[type=checkbox],
                    input[type=radio] {
                        display: none;
                    }
                }

                .option {
                    float: left;
                    height: auto;
                    margin: 0 0 7px 0;
                    width: 100%;
                    padding: 0 10px;
                }

                .option_title {
                    float: left;
                    font-size: 14px;
                    font-weight: 400;
                    height: auto;
                    line-height: 18px;
                    margin-left: 45px;
                    margin-top: 0;
                    position: relative;
                    text-align: left;
                    text-decoration: none;
                    top: 0;
                    // z-index: 120;
                }

                // select option end
                .select_financeProvider {
                    position: relative;
                    display: inline-flex;
                    width: 99%;
                    height: 60px;
                    margin: 15px 1%;
                    line-height: 3;
                    overflow: hidden;
                    border-radius: 5px;
                    border: 1px solid #ced4da;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                    @media(max-width: 510px) {
                        width: 100%;
                    }

                    &::after {
                        content: '\25BC';
                        position: absolute;
                        top: 0;
                        right: 0;
                        padding: 0 1em;
                        background: $background_color_2;
                        color: #ffffff;
                        cursor: pointer;
                        height: inherit;
                        pointer-events: none;
                        transition: .25s all ease;
                        display: flex;
                        align-items: center;
                    }

                    &:hover::after {
                        color: #ffffff;
                    }

                    select {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        -ms-appearance: none;
                        appearance: none;
                        outline: 0;
                        box-shadow: none;
                        border: 0 !important;
                        background: #ffffff;
                        background-image: none;
                        flex: 1;
                        height: auto;
                        padding: 0 0.5em;
                        color: #7f7f7f;
                        cursor: pointer;
                        font-size: 17px;

                        &::-ms-expand {
                            display: none;
                        }

                        @media (max-width: 510px) {
                            font-size: 15px;
                        }
                    }

                    //select
                }

                //select_financeProvider
                .choose_Y_N {
                    display: flex;
                    width: 100%;
                    gap: 8px;
                    margin-bottom: 10px;

                    @media (max-width: 768px) {
                        justify-content: center;
                    }

                    /////////////////////////// form check /////////////////////////////////
                    .form-check {

                        // width: 50%;
                        @media (max-width: 510px) {
                            width: 100% !important;
                        }

                        [type="radio"] {
                            display: none;
                        }

                        // & + .form-check {
                        // 	width: 50%;
                        // }
                        label {
                            display: block;
                            padding: 13px 60px;
                            background: #EDC55C;
                            border: 1px solid #EDC55C;
                            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                            border-radius: 30px;
                            cursor: pointer;
                            font-size: 16px;
                            font-weight: 500;
                            //max-width: 180px;
                            color: black;
                            white-space: nowrap;
                            position: relative;
                            transition: 0.4s ease-in-out 0s;

                            &:after {
                                height: 19px;
                                width: 19px;
                                border: 1px solid #a5832c;
                                left: 19px;
                                top: calc(50% - 10px);
                            }

                            &:before {
                                background: #ffffff;
                                height: 11px;
                                width: 11px;
                                left: 23px;
                                top: calc(50% - 6px);
                                transform: scale(5);
                                opacity: 0;
                                visibility: hidden;
                                transition: 0.4s ease-in-out 0s;
                            }

                            @media (max-width: 790px) {
                                font-size: 22px;
                            }
                        }

                        [type="radio"]:checked {
                            &~label {
                                border-color: #EDC55C;
                                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                                background: #c79e32;
                                color: #ffffff;

                                &::before {
                                    background: #fff;
                                    opacity: 1;
                                    visibility: visible;
                                    transform: scale(1);
                                }

                                &::after {
                                    border: 2px solid #fff;
                                }
                            }
                        }
                    }

                    .form-check label:after,
                    .form-check label:before {
                        content: "";
                        position: absolute;
                        border-radius: 50%;
                    }

                    ///////////////////////// form check /////////////////////////////////
                }

                //choose_bw
                .png_t {
                    font-size: 28px;
                    font-weight: 600;

                    @media (max-width: 790px) {
                        font-size: 20px;
                    }
                }

                .cs_hero_img_overlap {
                    width: 100%;
                    overflow: hidden;
                    position: absolute;
                    top: 0;
                    height: inherit;
                }

                //cs_hero_img_overlap
                >.container-md {
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .home_plate_001 {
                        position: relative;
                        z-index: 4;

                        .cs_hero_text {
                            position: relative;
                            z-index: 1;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .cs_hero_title {

                                color: #615433;
                                font-size: 48px;
                                font-weight: 800;
                                line-height: 50px;
                                margin-bottom: 20px;



                                @media (max-width: 1700px) {
                                    font-size: 48px;
                                }

                                @media (max-width: 1200px) {
                                    font-size: 48px;
                                }

                                @media (max-width: 1199px) {
                                    font-size: 43px;
                                }

                                @media (max-width: 1199px) {
                                    margin-bottom: 5px;
                                }

                                @media (max-width: 510px) {
                                    font-size: 26px;
                                    text-align: center;
                                    font-weight: 800;
                                    padding-top: 40px;
                                    line-height: 33px;
                                }
                            }

                            //cs_hero_title
                            .cs_hero_subtitle {
                                max-width: 700px;
                                font-size: 20px;
                                font-weight: 400;
                                color: #004d8b;
                                margin-bottom: 29px;

                                //   margin-bottom: 110px;
                                @media (max-width: 1700px) {
                                    max-width: 600px;
                                }

                                @media (max-width: 1199px) {
                                    max-width: 580px;
                                }

                                @media (max-width: 510px) {
                                    font-size: 17px;
                                    text-align: center;
                                    margin-bottom: 0px;
                                    line-height: 140%;
                                }
                            }

                            //cs_hero_subtitle
                            .banner-subtext {
                                font-size: 1.5rem;

                                @media (max-width: 510px) {
                                    font-size: 1rem;
                                    text-align: center;
                                }
                            }

                            .mob_view {
                                display: none;

                                @media (max-width: 510px) {
                                    display: block;
                                    padding-top: 10px;
                                    text-align: center;

                                }
                            }
                        }

                        //cs_hero_text
                        .img_float {
                            display: flex;
                            align-items: flex-end;
                            justify-content: center;
                            position: relative;

                            .cs_hero_img {
                                position: relative;
                                z-index: 1;
                                height: max-content;
                                max-width: 100%;

                                @media only screen and (max-width: 1400px) and (min-width: 993px) {
                                    bottom: 0;
                                }

                                @media (max-width: 991px) {
                                    position: relative;
                                    margin-top: 20px;
                                    max-width: 87%;
                                }

                                @media (max-width: 780px) {
                                    max-width: 85%;
                                }

                                @media (max-width: 510px) {
                                    // display: none;
                                }
                            }

                            //cs_hero_img
                            .cs_hero_float {
                                position: absolute;
                                top: 22px;
                                left: 67px;
                                width: 206px;
                                z-index: 2;

                                @media (max-width: 510px) {
                                    width: 139px;
                                }
                            }
                        }

                        //img_float
                        .box_button {
                            @media (max-width: 510px) {
                                // padding: 10px 30px;
                            }

                            .button {
                                background-image: url(../img/right-chevron.svg) !important;
                                background-size: 35% 50px !important;
                                background-repeat: no-repeat !important;
                                background-position: right !important;
                                display: inline-block;
                                background: $background_color_2;
                                color: black;
                                padding: 16px 30px;
                                border-radius: 5px;
                                border: 0;
                                font-size: 20px;
                                width: 50%;
                                font-weight: 600;
                                box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.4);
                                cursor: pointer;
                                transition: all ease-in-out 300ms;

                                @media(min-width: 1200px) {
                                    width: 45%;
                                }

                                @media (min-width: 980px) {
                                    width: 50%;
                                }

                                @media (max-width: 780px) {
                                    width: 40%;
                                }

                                @media (max-width: 510px) {
                                    width: 100%;
                                }

                                &:hover {
                                    box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
                                    transform: translate(0px, -10px) scale(1.1);
                                    -webkit-transform: translate(0px, -10px) scale(1.1);
                                    -moz-transform: translate(0px, -10px) scale(1.1);
                                    -ms-transform: translate(0px, -10px) scale(1.1);
                                    -o-transform: translate(0px, -10px) scale(1.1);
                                }
                            }

                            .button--moma {
                                -webkit-animation: animation-blue-flash 1.25s linear infinite normal;
                                animation: animation-blue-flash 1.25s linear infinite normal;
                            }

                            @keyframes animation-blue-flash {
                                0% {
                                    -moz-box-shadow: 0 0 0 0 #4285f4;
                                    box-shadow: 0 0 0 0 #4285f4;
                                }

                                70% {
                                    -moz-box-shadow: 0 0 0 10px rgba(2, 103, 193, 0);
                                    box-shadow: 0 0 0 15px rgba(2, 103, 193, 0);
                                }

                                100% {
                                    -moz-box-shadow: 0 0 0 0 rgba(2, 103, 193, 0);
                                    box-shadow: 0 0 0 0 rgba(2, 103, 193, 0);
                                }
                            }
                        }

                        //box_button
                        .wht_iF {
                            display: block;

                            @media (max-width: 510px) {
                                display: none;
                            }
                        }

                        .wht_iFNot {
                            display: none;

                            @media (max-width: 510px) {
                                display: block;
                                position: relative;
                                margin: 30px 0 15px;
                            }

                            @media (max-width: 420px) {
                                margin: 20px 0 15px;
                            }
                        }
                    }

                    //home_plate_001
                    .home_plate_002,
                    .home_plate_003,
                    .home_plate_004,
                    .home_plate_005 {
                        position: relative;
                        z-index: 4;

                        .plateTwo_anim {
                            padding: 20px 0px 20px 0px;

                            @media (max-width: 1200px) {
                                padding: 20px 0px
                            }

                            .top_pad_Lil {
                                padding-bottom: 13px;
                            }

                            //top_pad_Lil
                            .lil_hgt {
                                line-height: 130%;
                            }

                            .sub_ble {
                                font-size: 20px;
                                color: #004d8b;

                                @media (max-width: 510px) {
                                    text-align: center;
                                    font-size: 18px;
                                }
                            }

                            //sub_ble
                            .sub_bl2Xw {
                                font-size: 18px;
                                font-weight: 500;
                                color: #7b621b;
                                line-height: 140%;

                                @media (max-width: 510px) {
                                    text-align: center;
                                    font-size: 15px;
                                    font-weight: 500;
                                }
                            }

                            //sub_bl2Xw
                            h2 {
                                font-size: 20px;
                                font-weight: 700;

                                @media (max-width: 780px) {
                                    // text-align: center;
                                }

                                @media (max-width: 510px) {
                                    text-align: center;
                                    font-size: 20px;
                                }
                            }

                            h3 {
                                font-size: 34px;
                                font-weight: 600;

                                @media (max-width: 780px) {
                                    // text-align: center;
                                }

                                @media (max-width: 510px) {
                                    text-align: center;
                                    font-size: 25px;
                                }

                                span {
                                    color: #4285f4;
                                    font-weight: 700;
                                }

                                em {
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: 19px;
                                    margin: 0 10px;
                                    color: #ff3434;

                                    @media (max-width: 510px) {
                                        text-align: center;
                                        font-size: 17px;
                                    }
                                }
                            }

                            //h3
                            .tiny_note {
                                margin: 0;
                                margin-top: 7px;
                                width: 80%;
                                font-size: 11px;
                                display: flex;
                                align-items: flex-start;

                                @media (max-width: 510px) {
                                    width: 100%;
                                }

                                .img_tim {
                                    width: 30px;
                                }
                            }

                            .form_drop {
                                box-shadow: unset;
                                background: none;
                                padding: 0;
                                border-radius: 0px;

                                @media(max-width: 510px) {
                                    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                                    background: #ffffffbd;
                                    padding: 5px 15px 30px;
                                    border-radius: 15px;
                                    -webkit-border-radius: 15px;
                                    -moz-border-radius: 15px;
                                    -ms-border-radius: 15px;
                                    -o-border-radius: 15px;
                                }
                            }

                            //form_drop
                            .material-textfield {
                                position: relative;
                                margin-top: 20px;
                                margin-bottom: 7px;

                                @media (max-width: 780px) {
                                    // text-align: center;
                                }

                                @media (max-width: 510px) {
                                    margin-top: 29px;
                                }

                                &:before {
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    left: 8px;
                                    color: #0A0A0A;
                                    background: #0267C1 url(../img/gb.svg) no-repeat center center;
                                    -webkit-background-size: 15px 25px;
                                    -moz-background-size: 15px 25px;
                                    -o-background-size: 15px 25px;
                                    background-size: 15px 25px;
                                    border: 2px solid #FFFFFF;
                                    -webkit-box-shadow: 0px 0px 0px 2px #0267C1;
                                    -moz-box-shadow: 0px 0px 0px 2px #0267C1;
                                    box-shadow: 0px 0px 0px 2px #0267C1;
                                    width: 30px;
                                    height: 40px;
                                    content: '';
                                    z-index: 3;
                                    -webkit-border-radius: 5px;
                                    -moz-border-radius: 5px;
                                    border-radius: 5px;
                                }

                                label {
                                    position: absolute;
                                    font-size: 16px;
                                    font-weight: 800;
                                    left: 0;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    // background-color: white;
                                    color: #6e6e6e;
                                    padding: 3px 9px 3px 44px;
                                    margin: 0 0.5rem;
                                    transition: .1s ease-out;
                                    transform-origin: left top;
                                    pointer-events: none;
                                    border-radius: 5px;
                                }

                                //label
                                input {
                                    font-size: 19px;
                                    outline: none;
                                    border: 2px solid #afafaf;
                                    border-radius: 5px;
                                    width: 80%;
                                    height: 58px;
                                    transition: 0.1s ease-out;
                                    padding: 0 45px;
                                    text-transform: uppercase;
                                    font-weight: 700;
                                    background: #fff41f;

                                    &:focus {
                                        border-color: #afafaf;
                                    }

                                    &:focus+label {
                                        color: #6e6e6e;
                                        top: -11px;
                                        transform: translateY(-50%) scale(0.9);
                                        padding: 11px 9px 13px 9px;
                                        margin-left: 45px;
                                        font-weight: 700;
                                        font-size: 15px;
                                    }

                                    &:not(:placeholder-shown)+label {
                                        top: -11px;
                                        transform: translateY(-50%) scale(.9);
                                        padding: 3px 9px 3px 9px;
                                        margin-left: 45px;
                                    }

                                    @media (max-width: 510px) {
                                        width: 100%;
                                    }
                                }

                                //input
                            }

                            //material-textfield
                            .sect_bt {
                                @media (max-width: 510px) {
                                    padding-top: 20px;
                                }

                                .png_t {
                                    font-size: 19px;
                                    font-weight: 600;
                                }

                                .box_vehiInfo {
                                    padding: 17px 25px;
                                    border-radius: 10px;
                                    -webkit-border-radius: 10px;
                                    -moz-border-radius: 10px;
                                    -ms-border-radius: 10px;
                                    -o-border-radius: 10px;
                                    background: #d7ecffb0;
                                    width: 80%;
                                    margin: 20px 0;
                                    color: #004d8b;

                                    @media (max-width: 510px) {
                                        width: 100%;
                                    }

                                    .lil_headTxt {
                                        font-size: 15px;
                                        font-weight: 500;
                                        padding-bottom: 5px;
                                        border-bottom: 1px solid #a3a3a3;
                                    }

                                    p {
                                        font-size: 16px;
                                        font-weight: 400;
                                    }
                                }
                            }

                            .enter_postcode_add {
                                padding-top: 10px;
                                text-align: start;

                                label {
                                    font-size: 12px;
                                    font-weight: 700;
                                }

                                //label
                                .postcode_wrap {
                                    display: flex;
                                    width: 100%;

                                    .form-control {
                                        border-radius: 0;
                                        border-top-left-radius: 5px;
                                        border-bottom-left-radius: 5px;
                                        padding: 15px;
                                        width: 70%;
                                        font-size: 13px;

                                        @media (max-width: 510px) {
                                            border: 1px solid #a3a3a3;
                                            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                                        }

                                        &::placeholder {
                                            color: $color_5;
                                        }

                                        &:focus {
                                            border-color: $background_color_3;
                                            outline: 0;
                                            box-shadow: $box-shadow1;
                                        }

                                        //form-control
                                    }

                                    //form-control
                                    .button {
                                        width: 30%;
                                        outline: none;
                                        border: 0;
                                        background: none;
                                        padding: 13px 0;
                                        font-size: 15px;
                                        font-weight: 700;
                                        position: relative;
                                        display: block;
                                        border-top-right-radius: 5px;
                                        border-bottom-right-radius: 5px;
                                    }

                                    //button
                                    .bg_add_click {
                                        background: #333333;
                                        color: $background_color_1;
                                        transition: cubic-bezier(0.075, 0.82, 0.165, 1);

                                        &:hover {
                                            background: $background_color_5;
                                        }
                                    }

                                    //bg_add_click
                                }

                                //postcode_wrap
                                .tiny_note {
                                    font-size: 11px;
                                    color: #979797;
                                    margin-top: 3px;
                                }

                                //tiny_note
                                #selcetAdd {
                                    display: none;

                                    #addSelect {
                                        position: relative;
                                        top: 0;
                                    }

                                    //addSelect
                                    .form-select {
                                        border-radius: 5px;
                                        font-size: 13px;
                                        padding: 13px 10px;
                                        width: 100%;
                                        color: $color_2;
                                        border: 1px solid #a3a3a3;
                                        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                                        &:focus {
                                            border-color: $background_color_3;
                                            outline: 0;
                                            box-shadow: $box-shadow1;
                                        }

                                        &place_dummi {
                                            color: $color_5;
                                        }
                                    }

                                    //form-select
                                    .ADD_detail {
                                        text-transform: uppercase;
                                        background: #d7ecffb0;
                                        color: $color_2;
                                        display: none;
                                        margin: 20px 0;
                                        text-align: left;
                                        line-height: 140%;

                                        .ADD_na_top {
                                            font-size: 13px;
                                            font-weight: 700;
                                            margin-bottom: 5px;
                                        }

                                        //ADD_na_top
                                        .ADD_clr_typ {
                                            font-size: 12px;
                                            margin-bottom: 0;
                                            font-weight: 700;
                                        }

                                        //ADD_clr_typ
                                    }

                                    //ADD_detail
                                    .return_back {
                                        font-size: 10px;
                                        font-weight: 700;

                                        p {
                                            display: flex;
                                            display: flex;
                                            justify-content: space-between;
                                        }

                                        span {
                                            text-decoration: underline;
                                        }
                                    }

                                    ///return_back
                                }

                                //selcetAdd
                            }

                            //enter_postcode_add
                            @media (max-width: 510px) {
                                .enter_postcode_add {
                                    .postcode_wrap {
                                        display: flex !important;
                                        flex-wrap: wrap;
                                        width: 100% !important;

                                        .form-control {
                                            border-radius: 5px;
                                            margin-bottom: 15px;
                                            width: 100% !important;
                                        }

                                        //form-control
                                        .button {
                                            width: 100% !important;
                                            border-radius: 5px;
                                        }

                                        //button
                                    }

                                    //postcode_wrap
                                }

                                //enter_postcode_add
                            }
                        }

                        //plateTwo_anim
                    }

                    //.home_plate_002,.home_plate_003,.home_plate_004,.home_plate_005
                    //////////////////////////////////////
                    ///
                    /// SIGNATURE PAGE DESIGN GOES HERE
                    ///
                    ///////////////////////////////////////
                    .sign_dis_paGe {
                        .home_plate_006 {
                            font-family: "Inter", sans-serif;

                            .plateThree_anim {
                                padding: 20px 0px 20px 0px;

                                @media (max-width: 1200px) {
                                    padding: 0px 0px
                                }

                                @media (max-width: 510px) {
                                    padding: 30px 0px
                                }

                                .lts_add_sm {
                                    font-weight: 800;
                                    text-align: center;
                                    font-size: 38px;
                                    line-height: 145%;
                                    color: #515151;
                                    margin-bottom: 30px;

                                    @media (max-width: 1200px) {
                                        font-size: 36px;
                                    }

                                    @media (max-width: 980px) {
                                        font-size: 32px;
                                    }

                                    @media (max-width: 768px) {
                                        font-size: 28px;
                                    }

                                    @media (max-width: 510px) {
                                        font-size: 22px;
                                    }

                                    @media (max-width: 440px) {
                                        font-size: 19px;
                                    }

                                    span {
                                        display: inline-block;
                                        position: relative;
                                    }

                                    .clr-dif {
                                        color: #41beff;
                                        position: relative;
                                        padding: 0 10px;

                                        &::after {
                                            content: "";
                                            position: absolute;
                                            top: auto;
                                            left: 0;
                                            bottom: 15%;
                                            width: 100%;
                                            height: 32%;
                                            z-index: -1;
                                            background: #41beff3b;
                                        }
                                    }
                                }

                                .sign-here {
                                    border-radius: 5px;
                                    padding: 0 0;
                                    border: 0;
                                    height: auto;
                                    box-shadow: rgba(100, 100, 111, 0.38) 0px 6px 20px 0px;

                                    .sign-note {
                                        background: #e7e7e7;
                                        padding: 15px 25px;
                                        border-radius: 5px;

                                        img {
                                            // width: 10px;
                                        }

                                        p {
                                            padding: 0;
                                            margin: 0;
                                        }

                                        .sign-title {
                                            font-size: 16px;
                                        }

                                        .sml-note {
                                            font-size: 12px;
                                            padding: 0;
                                            margin: 0;
                                        }
                                    }

                                    .sign-pad {
                                        background: #fff;
                                        border: 1px solid #b9b9b9;
                                    }

                                    canvas#canvas_signature {
                                        width: 100%;
                                        height: 250px;
                                    }
                                }

                                // signature
                                .sign_Btn_paGe {
                                    @media (max-width: 510px) {
                                        position: fixed;
                                        bottom: 0;
                                        left: 0;
                                        right: 0;
                                        z-index: 4;
                                    }

                                    input {
                                        @media (max-width: 510px) {
                                            border-radius: 0 !important;
                                        }
                                    }
                                }

                                .clear-btn {
                                    background: rgb(231, 231, 231);
                                    border-radius: 5px;
                                    border-top-left-radius: 0;
                                    border-top-right-radius: 0;
                                    padding: 10px 25px;
                                    height: auto;

                                    /* The container */
                                    .non_check {
                                        position: relative;
                                        top: -15px;
                                        margin-left: 33px;
                                        font-size: 12px;

                                        .lil_edit {
                                            color: #4285f4;
                                            font-size: 12px;
                                            font-weight: 600;
                                            font-style: normal;
                                            text-decoration: underline;
                                            cursor: pointer;

                                            &:hover {
                                                color: #0f5edf;
                                            }

                                            img {
                                                width: 13px;
                                                margin-top: -8px;
                                            }
                                        }
                                    }

                                    .bt_points {
                                        position: relative;
                                        top: -23px;

                                        p {
                                            padding: 0;
                                            margin: 0;
                                            display: inline-flex;
                                            align-items: flex-start;
                                            gap: 16px;

                                            img {
                                                width: 12px;
                                                padding-top: 4px;
                                            }
                                        }

                                        .sml-note {
                                            font-size: 12px;
                                            padding: 0;
                                            margin: 0;
                                        }
                                    }

                                    .container-checkbox {
                                        display: block;
                                        position: relative;
                                        padding-left: 28px;
                                        margin-bottom: 12px;
                                        cursor: pointer;
                                        font-size: 12px;
                                        -webkit-user-select: none;
                                        -moz-user-select: none;
                                        -ms-user-select: none;
                                        user-select: none;
                                    }

                                    /* Hide the browser's default checkbox */
                                    .container-checkbox input {
                                        position: absolute;
                                        opacity: 0;
                                        cursor: pointer;
                                    }

                                    /* Create a custom checkbox */
                                    .container-checkbox .checkmark {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        height: 19px;
                                        width: 19px;
                                        background-color: #eee;
                                    }

                                    /* On mouse-over, add a grey background color */
                                    .container-checkbox:hover input~.checkmark {
                                        background-color: #ccc;
                                    }

                                    /* When the checkbox is checked, add a blue background */
                                    .container-checkbox input:checked~.checkmark {
                                        background-color: #41beff;
                                    }

                                    /* Create the checkmark/indicator (hidden when not checked) */
                                    .container-checkbox .checkmark:after {
                                        content: "";
                                        position: absolute;
                                        display: none;
                                    }

                                    /* Show the checkmark when checked */
                                    .container-checkbox input:checked~.checkmark:after {
                                        display: block;
                                    }

                                    /* Style the checkmark/indicator */
                                    .container-checkbox .checkmark:after {
                                        left: 7px;
                                        top: 3px;
                                        width: 6px;
                                        height: 11px;
                                        border: solid white;
                                        border-width: 0 2px 2px 0;
                                        -webkit-transform: rotate(45deg);
                                        -ms-transform: rotate(45deg);
                                        transform: rotate(45deg);
                                    }

                                    .msf-nav-button.clear {
                                        padding: 8px 14px;
                                        outline: none;
                                        border-radius: 5px;
                                        appearance: none;
                                        font-size: 18px;
                                        font-weight: 500;
                                        color: #082954;
                                        background: #c7c7c7;
                                        color: #082954;
                                        border: unset;
                                        font-size: 16px;
                                        font-weight: 600;
                                    }
                                }
                            }
                        }

                        .modal {
                            background: rgba(0, 0, 0, 0.86);

                            // padding-right: 0 !important;
                            .modalContent_sml {
                                min-height: initial;
                            }

                            .modal-content {
                                display: flex;
                                border-radius: 20px 20px 20px 20px;
                                box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
                                padding: 10%;
                                background-image: url(../img/bubble_bg.png);
                                background-position: center right;
                                background-repeat: no-repeat;
                                background-size: contain;
                                min-height: 60vh;

                                .modal-header {
                                    border: 0;
                                    justify-content: center;

                                    .modal-title {
                                        img {
                                            width: 220px;
                                        }
                                    }
                                }

                                .modal-body {
                                    padding: 10px 0;

                                    // display: flex;
                                    // align-items: flex-start;
                                    .form_drop {
                                        .png_t {
                                            font-size: 19px;
                                            font-weight: 600;
                                        }

                                        .choose_Name {
                                            display: flex;
                                            width: 100%;
                                            flex-direction: column;
                                            gap: 8px;

                                            // margin-bottom: 30px;
                                            @media (max-width: 510px) {
                                                flex-direction: column;
                                                gap: 15px;
                                            }

                                            /////////////////////////// form check /////////////////////////////////
                                            .form-check {

                                                // width: 50%;
                                                @media (max-width: 510px) {
                                                    width: 100% !important;
                                                }

                                                [type="radio"] {
                                                    display: none;
                                                }

                                                // & + .form-check {
                                                // 	width: 50%;
                                                // }
                                                label {
                                                    display: block;
                                                    padding: 13px 60px;
                                                    background: #fff;
                                                    border: 1px solid #a3a3a3;
                                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                                                    border-radius: 5px;
                                                    cursor: pointer;
                                                    font-size: 16px;
                                                    font-weight: 500;
                                                    width: 100%;
                                                    color: #000000;
                                                    white-space: nowrap;
                                                    position: relative;
                                                    transition: 0.4s ease-in-out 0s;

                                                    &:after {
                                                        height: 19px;
                                                        width: 19px;
                                                        border: 1px solid #18478b;
                                                        left: 19px;
                                                        top: calc(50% - 10px);
                                                    }

                                                    &:before {
                                                        background: #004d8b;
                                                        height: 11px;
                                                        width: 11px;
                                                        left: 23px;
                                                        top: calc(50% - 6px);
                                                        transform: scale(5);
                                                        opacity: 0;
                                                        visibility: hidden;
                                                        transition: 0.4s ease-in-out 0s;
                                                    }
                                                }

                                                [type="radio"]:checked {
                                                    &~label {
                                                        border-color: #d7a41d;
                                                        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                                                        background: #d7a41d;
                                                        color: #ffffff;

                                                        &::before {
                                                            background: #fff;
                                                            opacity: 1;
                                                            visibility: visible;
                                                            transform: scale(1);
                                                        }

                                                        &::after {
                                                            border: 2px solid #fff;
                                                        }
                                                    }
                                                }
                                            }

                                            .form-check label:after,
                                            .form-check label:before {
                                                content: "";
                                                position: absolute;
                                                border-radius: 50%;
                                            }

                                            ///////////////////////// form check /////////////////////////////////
                                        }

                                        //choose_bw
                                        .sty_pop {
                                            font-size: 18px;
                                            font-weight: 600;
                                        }

                                        label[for="format2"] {
                                            font-size: 12px;
                                            font-weight: 700;
                                        }

                                        .select_title {
                                            position: relative;
                                            display: flex;
                                            width: 100%;
                                            line-height: 3;
                                            overflow: hidden;
                                            border-radius: 5px;
                                            border: 1px solid #a3a3a3;
                                            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                                            @media(max-width: 510px) {
                                                width: 100%;
                                            }

                                            &::after {
                                                content: '\25BC';
                                                position: absolute;
                                                top: 0;
                                                right: 0;
                                                padding: 0 1em;
                                                background: #4285f4;
                                                color: #ffffff;
                                                cursor: pointer;
                                                height: 100%;
                                                pointer-events: none;
                                                transition: .25s all ease;
                                                display: flex;
                                                align-items: center;
                                            }

                                            &:hover::after {
                                                color: #ffffff;
                                            }

                                            select {
                                                -webkit-appearance: none;
                                                -moz-appearance: none;
                                                -ms-appearance: none;
                                                appearance: none;
                                                outline: 0;
                                                box-shadow: none;
                                                border: 0 !important;
                                                background: #ffffff;
                                                background-image: none;
                                                flex: 1;
                                                height: auto;
                                                padding: 15px 15px;
                                                color: #7f7f7f;
                                                cursor: pointer;
                                                font-size: 13px;

                                                &::-ms-expand {
                                                    display: none;
                                                }

                                                @media (max-width: 510px) {
                                                    font-size: 13px;
                                                }
                                            }

                                            //select
                                        }

                                        //select_title
                                        .name_detail {
                                            padding-top: 20px;
                                            text-align: left;

                                            @media (max-width: 510px) {
                                                padding-top: 10px;
                                            }

                                            .f_name {
                                                margin-bottom: 10px;

                                                label {
                                                    font-size: 12px;
                                                    font-weight: 700;
                                                }

                                                .form-control {
                                                    border-radius: 5px;
                                                    padding: 15px 15px;
                                                    font-size: 13px;
                                                    border: 1px solid #a3a3a3;
                                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                                                    &::placeholder {
                                                        color: $color_5;
                                                    }

                                                    &:focus {
                                                        border-color: #18478b;
                                                        outline: 0;
                                                        box-shadow: $box-shadow1;
                                                    }
                                                }
                                            }

                                            //f_name
                                            .m_name {
                                                margin-bottom: 10px;

                                                label {
                                                    font-size: 12px;
                                                    font-weight: 700;

                                                    span {
                                                        font-size: 6px;
                                                        color: #8b8b8b;

                                                        @media (max-width: 510px) {
                                                            font-size: 9px;
                                                        }
                                                    }
                                                }

                                                .form-control {
                                                    border-radius: 5px;
                                                    padding: 15px 15px;
                                                    font-size: 13px;
                                                    border: 1px solid #a3a3a3;
                                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                                                    &::placeholder {
                                                        color: $color_5;
                                                    }

                                                    &:focus {
                                                        border-color: #18478b;
                                                        outline: 0;
                                                        box-shadow: $box-shadow1;
                                                    }
                                                }

                                                //form-control
                                            }

                                            //m_name
                                            .l_name {
                                                margin-bottom: 10px;

                                                label {
                                                    font-size: 12px;
                                                    font-weight: 700;
                                                }

                                                .form-control {
                                                    border-radius: 5px;
                                                    padding: 15px 15px;
                                                    font-size: 13px;
                                                    border: 1px solid #a3a3a3;
                                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                                                    &::placeholder {
                                                        color: $color_5;
                                                    }

                                                    &:focus {
                                                        border-color: #18478b;
                                                        outline: 0;
                                                        box-shadow: $box-shadow1;
                                                    }
                                                }

                                                //form-control
                                            }

                                            //l_name
                                            .tiny_note {
                                                font-size: 11px;
                                                color: #979797;
                                                margin-top: 3px;
                                            }

                                            //tiny_note
                                        }

                                        // name_detail
                                        .Birth_detail {
                                            padding-top: 10px;
                                            text-align: left;

                                            label {
                                                font-size: 12px;
                                                font-weight: 700;
                                            }

                                            .date_here {
                                                margin-bottom: 10px;

                                                label {
                                                    font-size: 11px;
                                                    font-weight: 400;
                                                    color: $color_5;
                                                }

                                                .form-select {
                                                    border-radius: 5px;
                                                    padding: 15px;
                                                    font-size: 13px;
                                                    border: 1px solid #a3a3a3;
                                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                                                    &::placeholder {
                                                        color: $color_5;
                                                    }

                                                    &:focus {
                                                        border-color: #18478b;
                                                        outline: 0;
                                                        box-shadow: $box-shadow1;
                                                    }
                                                }
                                            }

                                            //date_here
                                            .month_here {
                                                margin-bottom: 10px;

                                                label {
                                                    font-size: 11px;
                                                    font-weight: 400;
                                                    color: $color_5;
                                                }

                                                .form-select {
                                                    border-radius: 5px;
                                                    padding: 15px;
                                                    font-size: 13px;
                                                    border: 1px solid #a3a3a3;
                                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                                                    &::placeholder {
                                                        color: $color_5;
                                                    }

                                                    &:focus {
                                                        border-color: #18478b;
                                                        outline: 0;
                                                        box-shadow: $box-shadow1;
                                                    }
                                                }
                                            }

                                            //month_here
                                            .year_here {
                                                margin-bottom: 10px;

                                                label {
                                                    font-size: 11px;
                                                    font-weight: 400;
                                                    color: $color_5;
                                                }

                                                .form-select {
                                                    border-radius: 5px;
                                                    padding: 15px;
                                                    font-size: 13px;
                                                    border: 1px solid #a3a3a3;
                                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                                                    &::placeholder {
                                                        color: $color_5;
                                                    }

                                                    &:focus {
                                                        border-color: #18478b;
                                                        outline: 0;
                                                        box-shadow: $box-shadow1;
                                                    }
                                                }
                                            }

                                            //year_here
                                            .tiny_note {
                                                font-size: 11px;
                                                color: #979797;
                                                margin-top: 3px;
                                            }

                                            //tiny_note
                                        }

                                        // Birth_detail
                                    }

                                    //form_drop
                                }

                                .modal-footer {
                                    display: block;
                                    flex-shrink: 0;
                                    flex-wrap: wrap;
                                    align-items: center;
                                    justify-content: start;
                                    padding: 0;
                                    border-top: 0;
                                }
                            }
                        }
                    }

                    //////////////////////////////////////
                    ///
                    /// PAGE BEFORE CHAT BOT
                    ///
                    ///////////////////////////////////////
                    .home_plate_final001 {
                        font-family: "Inter", sans-serif;

                        .plateFinal_anim {
                            padding: 20px 0px 20px 0px;

                            @media (max-width: 1200px) {
                                padding: 0px 0px
                            }

                            @media (max-width: 510px) {
                                padding: 30px 0px
                            }

                            .lts_add_sm {
                                font-weight: 800;
                                text-align: center;
                                font-size: 30px;
                                line-height: 145%;
                                color: #515151;
                                margin-bottom: 10px;

                                @media (max-width: 1200px) {
                                    font-size: 30px;
                                }

                                @media (max-width: 980px) {
                                    font-size: 28px;
                                }

                                @media (max-width: 768px) {
                                    font-size: 26px;
                                }

                                @media (max-width: 510px) {
                                    font-size: 24px;
                                }

                                @media (max-width: 440px) {
                                    font-size: 21px;
                                }

                                span {
                                    display: inline-block;
                                    position: relative;
                                }

                                .clr-dif {
                                    color: #4285f4;
                                    position: relative;
                                    padding: 0 10px;
                                }
                            }

                            .form_drop {
                                box-shadow: unset;
                                background: none;
                                padding: 0;
                                border-radius: 0px;

                                @media(max-width: 510px) {
                                    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                                    background: #ffffffbd;
                                    padding: 5px 15px 30px;
                                    border-radius: 15px;
                                    -webkit-border-radius: 15px;
                                    -moz-border-radius: 15px;
                                    -ms-border-radius: 15px;
                                    -o-border-radius: 15px;
                                }
                            }

                            //form_drop
                            .lil_sub_reg {
                                font-weight: 600;
                                text-align: center;
                                font-size: 18px;
                                line-height: 145%;
                                color: #3b3b3b;
                                margin: 15px 0 20px;
                            }

                            .btn-reg {
                                background: #fff41f url(../img/gb-icon.png) no-repeat left center;
                                color: $color_2;
                                padding: 11px 0;
                                border: 0px;
                                width: 100%;
                                border-radius: 5px;
                                font-size: 18px;
                                // max-width: 330px;
                                text-transform: uppercase;
                                font-weight: 600;
                                border: solid 2px #000000;
                                position: relative;
                                margin-bottom: 15px;
                                display: block;
                                text-decoration: none;

                                &:hover {
                                    background: #ffcb1f url(../img/gb-icon.png) no-repeat left center;
                                    border: solid 2px #1e4fb5;
                                    color: $color_1;
                                    // transform: scale(1.1);
                                }
                            }

                            .success_tick {
                                position: absolute;
                                height: 18px;
                                width: 19px;
                                background: url('../img/tick.png') no-repeat center center;
                                right: 15px;
                                top: 15px;
                                display: block;
                            }

                            .success_next {
                                position: absolute;
                                height: 18px;
                                width: 19px;
                                background: url('../img/arrow.png') no-repeat center center;
                                right: 15px;
                                top: 15px;
                                display: block;
                            }
                        }
                    }
                }

                //container
            }

            //cs_hero_wrap
        }

        //cs_hero.cs_style_1.section_design1
        .banner-wrap {
            width: 440px;
            height: 300px;
            background: $background_color_2;
            position: relative;
            margin-bottom: auto;
            margin-left: auto;
            border-radius: 133px 10px 10px 10px;

            .text-cicle {
                position: absolute;
                top: 0;
                width: 130px;
                height: 130px;
                left: -50px;
                top: -25px;
                z-index: 99;

                // display: flex;
                // align-items: center;
                // justify-content: center;
                @media (max-width: 560px) {
                    width: 100px;
                    height: 100px;
                    left: -27px;
                }

                .amount {
                    font-weight: 800;
                    transform: translate(4px, -77px);
                    font-size: 18px;
                    color: black;

                    @media (max-width: 560px) {
                        transform: translate(2px, -59px);
                        font-size: 13px;
                    }
                }

                img {
                    width: 130px;
                    height: 130px;
                    position: initial;
                    -webkit-animation: spin 10s infinite linear;
                    animation: spin 10s linear infinite;

                    @media (max-width: 560px) {
                        width: 100px;
                        height: 100px;
                    }


                }

                @-webkit-keyframes spin {
                    0% {
                        transform: rotate(0deg);
                    }

                    50% {
                        transform: rotate(-180deg);
                    }

                    100% {
                        transform: rotate(-360deg);
                    }
                }

                @keyframes spin {
                    0% {
                        transform: rotate(0deg);
                    }

                    50% {
                        transform: rotate(-180deg);
                    }

                    100% {
                        transform: rotate(-360deg);
                    }
                }
            }

            img {
                position: absolute;
                height: 390px;
                left: -69px;
            }

            .shape1 {
                background: #ffdb7d;
                height: 32px;
                width: 300px;
                position: absolute;
                right: 0;
                top: -42px;
                border-radius: 30px;
                animation: mymove 2s infinite forwards;
                // animation-iteration-count: 2;
            }

            @keyframes mymove {
                0% {
                    right: 0px;
                }

                50% {
                    right: 20px;
                }

                100% {
                    right: 0px;
                }
            }

            @-webkit-keyframes mymove {
                0% {
                    right: 0px;
                }

                50% {
                    right: 20px;
                }

                100% {
                    right: 0px;
                }
            }

            .shape2 {
                background: #646464;
                height: 171px;
                width: 32px;
                position: absolute;
                right: 0;
                left: -40px;
                border-radius: 30px;
                bottom: 0;
                animation: mymove2 3s infinite forwards;
            }

            @keyframes mymove2 {
                0% {
                    bottom: 0px;
                }

                50% {
                    bottom: 20px;
                }

                100% {
                    bottom: 0px;
                }
            }

            @-webkit-keyframes mymove2 {
                0% {
                    bottom: 0px;
                }

                50% {
                    bottom: 20px;
                }

                100% {
                    bottom: 0px;
                }
            }
        }

        @media (max-width: 960px) {
            .banner-wrap {
                width: 320px;
                height: 280px;
                margin: 0 auto;

                img {
                    height: 290px;
                    left: -30px;
                    bottom: -6px;
                }

                .shape1 {
                    height: 11px;
                    width: 125px;
                    top: -18px;
                }

                .shape2 {
                    height: 100px;
                    width: 8px;
                    left: -14px;
                }
            }
        }

        @media (max-width: 460px) {
            .banner-wrap {
                width: 230px;
                height: 200px;
                margin: 0 auto;

                img {
                    height: 234px;
                    left: -40px;
                    bottom: -29px;
                }

                .shape1 {
                    height: 11px;
                    width: 125px;
                    top: -18px;
                }

                .shape2 {
                    height: 100px;
                    width: 8px;
                    left: -14px;
                }
            }


        }

        //////////////////////////////////////
        ///
        /// QUESTIONNAIRE PAGE DESIGN GOES HERE
        ///
        ///////////////////////////////////////
        .bg_ques {
            background: url(../img/pattern-2-curve.png) #fff no-repeat center center;
            background-size: cover;
            transition: cubic-bezier(0.075, 0.82, 0.165, 1);
            position: relative;

            @media (max-width: 510px) {
                background: url(../img/pattern-3-curve.png) #fff no-repeat center;
                background-size: cover;
            }

            .sec_ques_bb {
                height: 100%;
                position: relative;

                .cs_hero_wrap {
                    height: 100%;
                    width: 100%;
                    padding: 150px 0 60px;
                    position: relative;

                    @media (max-width: 1300px) {
                        padding: 150px 0 60px;
                    }

                    @media (max-width: 1199px) {
                        padding: 150px 0;
                    }

                    @media (max-width: 980px) {
                        padding: 150px 0 60px;
                    }

                    @media (max-width: 780px) {
                        padding: 150px 0 60px;
                    }

                    @media (max-width: 510px) {
                        padding: 120px 0 70px;
                        width: 95%;
                        margin: 0 auto;
                    }

                    @media (min-width: 1730px) {
                        // height: 620px;
                        display: flex;
                        align-items: center;
                    }

                    @media only screen and (max-width: 1730px) and (min-width: 1198px) {
                        // height: 620px;
                        display: flex;
                        align-items: center;
                    }

                    @media only screen and (max-width: 1730px) and (min-width: 1398px) {
                        padding: 150px 0 20px;
                    }

                    @media (max-width: 1730px) {
                        // height: 620px;
                        display: flex;
                        align-items: center;
                    }

                    .tke_xfd {
                        .box_vehiInfo {
                            padding: 17px 20px;
                            border-radius: 10px;
                            -webkit-border-radius: 10px;
                            -moz-border-radius: 10px;
                            -ms-border-radius: 10px;
                            -o-border-radius: 10px;
                            background: #d7ecffb0;
                            width: 100%;
                            margin: 20px 0;
                            color: #004d8b;

                            @media (max-width: 510px) {
                                width: 100%;
                            }

                            .lil_headTxt {
                                font-size: 15px;
                                font-weight: 500;
                                padding-bottom: 5px;
                                border-bottom: 1px solid #a3a3a3;
                            }

                            p {
                                font-size: 16px;
                                font-weight: 400;
                            }

                            .grid_style {
                                display: grid;
                                grid-template-columns: repeat(2, 1fr);
                            }

                            .text12,
                            .text13 {

                                // padding: 20px 20px;
                                .txt {
                                    font-size: 17px;
                                    color: #b18924;
                                    font-weight: 700;
                                    display: flex;
                                    align-items: center;

                                    .count {
                                        background: #d9d9d9;
                                        border-radius: 50%;
                                        display: none;
                                        padding: 8px;
                                        width: 20px;
                                        height: 20px;
                                        font-size: 14px;
                                        text-align: center;
                                        margin-right: 7px;
                                        line-height: 3px;
                                        font-weight: 600;
                                    }

                                    .text-reg {
                                        background: #FFF41F;
                                        font-weight: 800;
                                        display: inline;
                                        padding: 2px 7px 2px 22px;
                                        -webkit-border-radius: 2.5px;
                                        -moz-border-radius: 2.5px;
                                        border-radius: 2.5px;
                                        border: 1px solid #0A0A0A;
                                        line-height: 100%;
                                        text-transform: uppercase;
                                        position: relative;

                                        &:before {
                                            position: absolute;
                                            left: 5px;
                                            top: 5px;
                                            color: #0A0A0A;
                                            background: #0267C1 url(../img/gb.svg) no-repeat center center;
                                            -webkit-background-size: 4px 7px;
                                            -moz-background-size: 4px 7px;
                                            -o-background-size: 4px 7px;
                                            background-size: 4px 7px;
                                            border: 1px solid #FFFFFF;
                                            -webkit-box-shadow: 0px 0px 0px 1px #0267C1;
                                            -moz-box-shadow: 0px 0px 0px 1px #0267C1;
                                            box-shadow: 0px 0px 0px 1px #0267C1;
                                            width: 10px;
                                            height: 17px;
                                            content: '';
                                            z-index: 6;
                                            -webkit-border-radius: 2.5px;
                                            -moz-border-radius: 2.5px;
                                            border-radius: 2.5px;
                                        }
                                    }
                                }
                            }

                            //text12
                        }
                    }

                    .home_plate_ques001,
                    .home_plate_ques002,
                    .home_plate_ques003,
                    .home_plate_ques004,
                    .home_plate_ques005,
                    .home_plate_ques006,
                    .home_plate_ques007,
                    .home_plate_ques008 {
                        position: relative;
                        z-index: 4;

                        .plateQues1_anim {
                            padding: 20px 0px 20px 70px;

                            @media (max-width: 1200px) {
                                padding: 20px 0px
                            }

                            .top_pad_Lil {
                                padding-bottom: 13px;
                            }

                            //top_pad_Lil
                            .lil_hgt {
                                line-height: 130%;
                            }

                            .sub_ble {
                                font-size: 20px;
                                color: #004d8b;

                                @media (max-width: 510px) {
                                    text-align: center;
                                    font-size: 18px;
                                }
                            }

                            //sub_ble
                            .sub_bl2Xw {
                                font-size: 18px;
                                font-weight: 500;
                                color: #2c2c2c;
                                line-height: 140%;

                                @media (max-width: 510px) {
                                    text-align: center;
                                    font-size: 15px;
                                    font-weight: 500;
                                }
                            }

                            //sub_bl2Xw
                            h2 {
                                font-size: 20px;
                                font-weight: 700;

                                @media (max-width: 780px) {
                                    // text-align: center;
                                }

                                @media (max-width: 510px) {
                                    text-align: center;
                                    font-size: 20px;
                                }
                            }

                            h3 {
                                font-size: 34px;
                                font-weight: 600;

                                @media (max-width: 780px) {
                                    // text-align: center;
                                }

                                @media (max-width: 510px) {
                                    text-align: center;
                                    font-size: 25px;
                                }

                                span {
                                    color: #4285f4;
                                    font-weight: 700;
                                }

                                em {
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: 19px;
                                    margin: 0 10px;
                                    color: #ff3434;

                                    @media (max-width: 510px) {
                                        text-align: center;
                                        font-size: 17px;
                                    }
                                }
                            }

                            //h3
                            .tiny_note {
                                margin: 0;
                                margin-top: 7px;
                                width: 100%;
                                font-size: 11px;
                                color: #004d8b;
                                display: flex;
                                align-items: flex-start;

                                @media (max-width: 510px) {
                                    width: 100%;
                                }

                                .img_tim {
                                    width: 30px;
                                }
                            }

                            .form_drop {
                                box-shadow: unset;
                                background: none;
                                padding: 0;
                                border-radius: 0px;

                                @media(max-width: 510px) {
                                    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                                    background: #ffffffbd;
                                    padding: 5px 15px 30px;
                                    border-radius: 15px;
                                    -webkit-border-radius: 15px;
                                    -moz-border-radius: 15px;
                                    -ms-border-radius: 15px;
                                    -o-border-radius: 15px;
                                }
                            }

                            //form_drop
                            .material-textfield {
                                position: relative;
                                margin-top: 20px;
                                margin-bottom: 7px;

                                @media (max-width: 780px) {
                                    // text-align: center;
                                }

                                @media (max-width: 510px) {
                                    margin-top: 29px;
                                }

                                &:before {
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    left: 8px;
                                    color: #0A0A0A;
                                    background: #0267C1 url(../img/gb.svg) no-repeat center center;
                                    -webkit-background-size: 15px 25px;
                                    -moz-background-size: 15px 25px;
                                    -o-background-size: 15px 25px;
                                    background-size: 15px 25px;
                                    border: 2px solid #FFFFFF;
                                    -webkit-box-shadow: 0px 0px 0px 2px #0267C1;
                                    -moz-box-shadow: 0px 0px 0px 2px #0267C1;
                                    box-shadow: 0px 0px 0px 2px #0267C1;
                                    width: 30px;
                                    height: 40px;
                                    content: '';
                                    z-index: 3;
                                    -webkit-border-radius: 5px;
                                    -moz-border-radius: 5px;
                                    border-radius: 5px;
                                }

                                label {
                                    position: absolute;
                                    font-size: 16px;
                                    font-weight: 800;
                                    left: 0;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    // background-color: white;
                                    color: #6e6e6e;
                                    padding: 3px 9px 3px 44px;
                                    margin: 0 0.5rem;
                                    transition: .1s ease-out;
                                    transform-origin: left top;
                                    pointer-events: none;
                                    border-radius: 5px;
                                }

                                //label
                                input {
                                    font-size: 19px;
                                    outline: none;
                                    border: 2px solid #afafaf;
                                    border-radius: 5px;
                                    width: 100%;
                                    height: 58px;
                                    transition: 0.1s ease-out;
                                    padding: 0 45px;
                                    text-transform: uppercase;
                                    font-weight: 700;
                                    background: #fff41f;

                                    &:focus {
                                        border-color: #afafaf;
                                    }

                                    &:focus+label {
                                        color: #6e6e6e;
                                        top: -11px;
                                        transform: translateY(-50%) scale(0.9);
                                        padding: 11px 9px 13px 9px;
                                        margin-left: 45px;
                                        font-weight: 700;
                                        font-size: 15px;
                                    }

                                    &:not(:placeholder-shown)+label {
                                        top: -11px;
                                        transform: translateY(-50%) scale(.9);
                                        padding: 3px 9px 3px 9px;
                                        margin-left: 45px;
                                    }

                                    @media (max-width: 510px) {
                                        width: 100%;
                                    }
                                }

                                //input
                            }

                            //material-textfield
                            .sect_bt {
                                @media (max-width: 510px) {
                                    padding-top: 20px;
                                }

                                .png_t {
                                    font-size: 21px;
                                    font-weight: 600;
                                    color: #004d8b;

                                    @media (max-width: 510px) {
                                        font-size: 19px;
                                    }
                                }

                                .box_vehiInfo {
                                    padding: 17px 25px;
                                    border-radius: 10px;
                                    -webkit-border-radius: 10px;
                                    -moz-border-radius: 10px;
                                    -ms-border-radius: 10px;
                                    -o-border-radius: 10px;
                                    background: #d7ecffb0;
                                    width: 80%;
                                    margin: 20px 0;
                                    color: #004d8b;

                                    @media (max-width: 510px) {
                                        width: 100%;
                                    }

                                    .lil_headTxt {
                                        font-size: 15px;
                                        font-weight: 500;
                                        padding-bottom: 5px;
                                        border-bottom: 1px solid #a3a3a3;
                                    }

                                    p {
                                        font-size: 16px;
                                        font-weight: 400;
                                    }
                                }
                            }

                            .select_financeProvider {
                                position: relative;
                                display: flex;
                                width: 100%;
                                height: 60px;
                                line-height: 3;
                                overflow: hidden;
                                border-radius: 5px;
                                border: 1px solid #ced4da;
                                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                                @media(max-width: 510px) {
                                    width: 100%;
                                }

                                &::after {
                                    content: '\25BC';
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    padding: 0 1em;
                                    background: #4285f4;
                                    color: #ffffff;
                                    cursor: pointer;
                                    height: inherit;
                                    pointer-events: none;
                                    transition: .25s all ease;
                                    display: flex;
                                    align-items: center;
                                }

                                &:hover::after {
                                    color: #ffffff;
                                }

                                select {
                                    -webkit-appearance: none;
                                    -moz-appearance: none;
                                    -ms-appearance: none;
                                    appearance: none;
                                    outline: 0;
                                    box-shadow: none;
                                    border: 0 !important;
                                    background: #ffffff;
                                    background-image: none;
                                    flex: 1;
                                    height: auto;
                                    padding: 0 0.5em;
                                    color: #7f7f7f;
                                    cursor: pointer;
                                    font-size: 17px;

                                    &::-ms-expand {
                                        display: none;
                                    }

                                    @media (max-width: 510px) {
                                        font-size: 15px;
                                    }
                                }

                                //select
                            }

                            //select_financeProvider
                            .choose_Yes_N {
                                display: flex;
                                width: 100%;
                                justify-content: center;
                                gap: 8px;
                                margin-bottom: 30px;
                                flex-direction: column;

                                @media (max-width: 510px) {
                                    flex-direction: column;
                                    gap: 15px;
                                }

                                /////////////////////////// form check /////////////////////////////////
                                .form-check {

                                    // width: 50%;
                                    @media (max-width: 510px) {
                                        width: 100% !important;
                                    }

                                    [type="radio"] {
                                        display: none;
                                    }

                                    // & + .form-check {
                                    // 	width: 50%;
                                    // }
                                    label {
                                        display: flex;
                                        padding: 13px 60px;
                                        background: #004d8b;
                                        border: 1px solid #a3a3a3;
                                        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                                        border-radius: 5px;
                                        cursor: pointer;
                                        font-size: 22px;
                                        font-weight: 600;
                                        min-width: 250px;
                                        min-height: 65px;
                                        color: #fff;
                                        white-space: nowrap;
                                        position: relative;
                                        transition: 0.4s ease-in-out 0s;
                                        align-items: center;
                                        justify-content: center;
                                    }

                                    [type="radio"]:checked {
                                        &~label {
                                            border-color: #004d8b;
                                            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                                            background: #004d8b;
                                            color: #ffffff;
                                        }
                                    }
                                }

                                .form-check label:after,
                                .form-check label:before {
                                    content: "";
                                    position: absolute;
                                    border-radius: 50%;
                                }

                                ///////////////////////// form check /////////////////////////////////
                            }

                            //choose_bw
                        }

                        //plateTwo_anim
                    }

                    //.home_plate_ques001
                }
            }

            .application-cta-get-started {
                // background-image: linear-gradient(225deg, #4fa3e461 0%, #1c86d869 49%, #70c1ff80 100%);
                width: 100%;
                position: relative;
                // text-align: center;
                display: flex;
                -webkit-font-smoothing: antialiased;
                align-items: center;
                // background: #fff;
                overflow: hidden;
            }

            .inner {
                position: relative;
                box-sizing: border-box;
                width: 100%;
                height: 100%;
            }

            .arUkdng_me,
            .arUkdng_mee {
                display: flex;
                align-items: center;
                justify-content: center;

                .arUkdng_slideOF {
                    background: transparent;
                    padding: 0px;

                    .cicle_loader {
                        padding: 20px 0 10px;
                        background: transparent;
                        margin: 0 auto;
                        border-radius: 12px;
                        text-align: center;

                        .loader_gif_ {
                            .lp {
                                width: 6em;
                                height: 10em;
                            }

                            .lp__drops,
                            .lp__fall-line,
                            .lp__worm {
                                animation-duration: 4s;
                                animation-iteration-count: infinite;
                                transform-origin: 64px 64px;
                            }

                            .lp__drops {
                                animation-name: drops;
                                animation-timing-function: ease-in-out;
                                stroke-dasharray: 22 307.85 22;
                                visibility: hidden;
                                transform: rotate(90deg);
                            }

                            .lp__fall-line {
                                animation-name: fallLine1;
                                animation-timing-function: ease-in;
                                stroke-dasharray: 1 112;
                                stroke-dashoffset: 114;
                            }

                            .lp__fall-line--delay1 {
                                animation-name: fallLine2;
                            }

                            .lp__fall-line--delay2 {
                                animation-name: fallLine3;
                            }

                            .lp__fall-line--delay3 {
                                animation-name: fallLine4;
                            }

                            .lp__fall-line--delay4 {
                                animation-name: fallLine5;
                            }

                            .lp__ring {
                                stroke: rgba(1, 8, 24, 0.1);
                                transition: stroke 0.3s;
                            }

                            .lp__worm {
                                animation-name: worm;
                                stroke-dasharray: 43.98 307.87;
                                stroke-dashoffset: -131.94;
                                transform: rotate(-90deg);
                            }

                            /* Animtions */
                            @keyframes drops {
                                from {
                                    animation-timing-function: steps(1, end);
                                    stroke-dasharray: 0 351.85 0;
                                    visibility: hidden;
                                }

                                25% {
                                    animation-timing-function: ease-in-out;
                                    stroke-dasharray: 0 351.85 0;
                                    visibility: visible;
                                }

                                26% {
                                    stroke-dasharray: 4 343.85 4;
                                }

                                27% {
                                    stroke-dasharray: 8 335.85 8
                                }

                                28% {
                                    stroke-dasharray: 12 327.85 12;
                                }

                                29% {
                                    animation-timing-function: ease-in;
                                    stroke-dasharray: 17 317.85 17;
                                }

                                32% {
                                    animation-timing-function: ease-out;
                                    stroke-dasharray: 25 301.85 25;
                                }

                                35%,
                                47.5% {
                                    animation-timing-function: linear;
                                    stroke-dasharray: 22 307.85 22;
                                    visibility: visible;
                                }

                                50% {
                                    animation-timing-function: steps(1, end);
                                    stroke-dasharray: 0 307.85 43.98;
                                    visibility: hidden;
                                }

                                75% {
                                    animation-timing-function: ease-in-out;
                                    stroke-dasharray: 0 351.85 0;
                                    visibility: visible;
                                }

                                76% {
                                    stroke-dasharray: 4 343.85 4;
                                }

                                77% {
                                    stroke-dasharray: 8 335.85 8
                                }

                                78% {
                                    stroke-dasharray: 12 327.85 12;
                                }

                                79% {
                                    animation-timing-function: ease-in;
                                    stroke-dasharray: 17 317.85 17;
                                }

                                82% {
                                    animation-timing-function: ease-out;
                                    stroke-dasharray: 25 301.85 25;
                                }

                                85%,
                                97.5% {
                                    animation-timing-function: linear;
                                    stroke-dasharray: 22 307.85 22;
                                    visibility: visible;
                                }

                                to {
                                    stroke-dasharray: 43.98 307.85 0;
                                    visibility: hidden;
                                }
                            }

                            @keyframes fallLine1 {

                                from,
                                15% {
                                    stroke-dashoffset: 114;
                                }

                                25%,
                                65% {
                                    stroke-dashoffset: 1;
                                }

                                75%,
                                to {
                                    stroke-dashoffset: -112;
                                }
                            }

                            @keyframes fallLine2 {

                                from,
                                16% {
                                    stroke-dashoffset: 114;
                                }

                                26%,
                                66% {
                                    stroke-dashoffset: 1;
                                }

                                76%,
                                to {
                                    stroke-dashoffset: -112;
                                }
                            }

                            @keyframes fallLine3 {

                                from,
                                17% {
                                    stroke-dashoffset: 114;
                                }

                                27%,
                                67% {
                                    stroke-dashoffset: 1;
                                }

                                77%,
                                to {
                                    stroke-dashoffset: -112;
                                }
                            }

                            @keyframes fallLine4 {

                                from,
                                18% {
                                    stroke-dashoffset: 114;
                                }

                                28%,
                                68% {
                                    stroke-dashoffset: 1;
                                }

                                78%,
                                to {
                                    stroke-dashoffset: -112;
                                }
                            }

                            @keyframes fallLine5 {

                                from,
                                19% {
                                    stroke-dashoffset: 114;
                                }

                                29%,
                                69% {
                                    stroke-dashoffset: 1;
                                }

                                79%,
                                to {
                                    stroke-dashoffset: -112;
                                }
                            }

                            @keyframes worm {
                                from {
                                    animation-timing-function: ease-out;
                                    stroke-dasharray: 87.96 307.87;
                                    stroke-dashoffset: -131.94;
                                }

                                25% {
                                    animation-timing-function: steps(1);
                                    stroke-dasharray: 87.96 307.87;
                                    stroke-dashoffset: -307.86;
                                }

                                25.01% {
                                    animation-timing-function: ease-in;
                                    stroke-dasharray: 43.98 307.87;
                                    stroke-dashoffset: -307.86;
                                }

                                50% {
                                    animation-timing-function: steps(1);
                                    stroke-dasharray: 43.98 307.87;
                                    stroke-dashoffset: -175.92;
                                }

                                50.01% {
                                    animation-timing-function: ease-out;
                                    stroke-dasharray: 87.96 307.87;
                                    stroke-dashoffset: -131.94;
                                }

                                75% {
                                    animation-timing-function: steps(1);
                                    stroke-dasharray: 87.96 307.87;
                                    stroke-dashoffset: 43.98;
                                }

                                75.01% {
                                    animation-timing-function: ease-in;
                                    stroke-dasharray: 43.98 307.87;
                                    stroke-dashoffset: 0;
                                }

                                to {
                                    stroke-dasharray: 43.98 307.87;
                                    stroke-dashoffset: -131.94;
                                }
                            }
                        }

                        //loader_gif_
                        .text12,
                        .text13 {
                            .txt {
                                font-size: 17px;
                                color: #b18924;
                                font-weight: 700;

                                .text-reg {
                                    background: #FFF41F;
                                    font-weight: 800;
                                    display: inline;
                                    padding: 2px 7px 2px 22px;
                                    -webkit-border-radius: 2.5px;
                                    -moz-border-radius: 2.5px;
                                    border-radius: 2.5px;
                                    border: 1px solid #0A0A0A;
                                    line-height: 100%;
                                    text-transform: uppercase;
                                    position: relative;

                                    &:before {
                                        position: absolute;
                                        left: 5px;
                                        top: 5px;
                                        color: #0A0A0A;
                                        background: #0267C1 url(../img/gb.svg) no-repeat center center;
                                        -webkit-background-size: 4px 7px;
                                        -moz-background-size: 4px 7px;
                                        -o-background-size: 4px 7px;
                                        background-size: 4px 7px;
                                        border: 1px solid #FFFFFF;
                                        -webkit-box-shadow: 0px 0px 0px 1px #0267C1;
                                        -moz-box-shadow: 0px 0px 0px 1px #0267C1;
                                        box-shadow: 0px 0px 0px 1px #0267C1;
                                        width: 10px;
                                        height: 17px;
                                        content: '';
                                        z-index: 6;
                                        -webkit-border-radius: 2.5px;
                                        -moz-border-radius: 2.5px;
                                        border-radius: 2.5px;
                                    }
                                }
                            }
                        }

                        //text12
                    }

                    //cicle_loader
                }

                //arUkdng_slideOF
            }

            //arUkdng_me
            .thank_drop {
                margin-top: 20px;
                background: transparent;

                .thank_tip_ {
                    width: 100%;

                    .note_sug_thank {
                        font-size: 16px;
                        font-weight: 700;
                        display: block;
                        margin-bottom: 0px;
                    }

                    //note_sug_thank
                    .note_tq_txt {
                        font-size: 12px;
                        color: $color_2;
                    }

                    //note_sub_txt
                }

                //thank_tip_
                @keyframes scaleAnimation {
                    0% {
                        opacity: 0;
                        transform: scale(1.5);
                    }

                    100% {
                        opacity: 1;
                        transform: scale(1);
                    }
                }

                @keyframes drawCircle {
                    0% {
                        stroke-dashoffset: 151px;
                    }

                    100% {
                        stroke-dashoffset: 0;
                    }
                }

                @keyframes drawCheck {
                    0% {
                        stroke-dashoffset: 36px;
                    }

                    100% {
                        stroke-dashoffset: 0;
                    }
                }

                @keyframes fadeOut {
                    0% {
                        opacity: 1;
                    }

                    100% {
                        opacity: 0;
                    }
                }

                @keyframes fadeIn {
                    0% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 1;
                    }
                }

                #successAnimationCircle {
                    stroke-dasharray: 151px 151px;
                    stroke: #00e243;
                }

                #successAnimationCheck {
                    stroke-dasharray: 36px 36px;
                    stroke: #00e243;
                }

                #successAnimationResult {
                    fill: #00e243;
                    opacity: 0;
                }

                #successAnimation.animated {
                    animation: 1s ease-out 0s 1 both scaleAnimation;

                    #successAnimationCircle {
                        animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle,
                            0.3s linear 0.9s 1 both fadeOut;
                    }

                    #successAnimationCheck {
                        animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck,
                            0.3s linear 0.9s 1 both fadeOut;
                    }

                    #successAnimationResult {
                        animation: 0.3s linear 0.9s both fadeIn;
                    }
                }

                .note_thank_ {
                    font-size: 22px;
                    font-weight: 600;
                    color: #00e243;
                }
            }

            //thank_drop
        }

        //bg_ques
        //////////////////////////////////////
        ///
        /// STICKY SCROLL ANIMATION DESIGN
        ///
        ///////////////////////////////////////

        .featured {


            .box-featured {
                background: $background_color_2;
                box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
                border: 3px solid white;
                height: 150px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;

                img {
                    height: 40px;
                }
            }
        }



        .news-ticker {
            background: #000000;
            color: #fff;
            overflow: hidden;
            height: 50px;
            display: flex;
            align-items: center;

            @media (max-width: 510px) {
                height: 45px;
            }
        }

        .ticker-content {
            display: flex;
        }

        .ligo {
            display: none;
            margin-right: 20px;
        }

        .ligo.active {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        .ligo .GuardianLogo {
            max-width: 130px;
            background: black;
            position: relative;
            z-index: 4;
            padding: 10px;

            @media (max-width: 510px) {
                max-width: 100px;
            }
        }

        .ligo .bbcLogo {
            max-width: 134px;
            background: #000000;
            position: relative;
            z-index: 4;
            padding: 10px;

            @media (max-width: 510px) {
                max-width: 120px;
            }
        }

        .ligo .telegraphLogo {
            max-width: 169px;
            background: #000000;
            position: relative;
            z-index: 4;
            padding: 10px;

            @media (max-width: 510px) {
                max-width: 120px;
            }
        }

        .contenty {
            white-space: nowrap;
            /* Ensures content stays in a single line */
            overflow: hidden;
            /* Hides any overflowing content */
            text-overflow: ellipsis;
            /* Adds ellipsis (...) if content is too long */
            animation: ticker-animation 8s linear infinite;
        }

        @keyframes ticker-animation {
            from {
                transform: translateX(100%);
            }

            to {
                transform: translateX(-100%);
            }
        }

        .pos_stick {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 4;
            transition: transform 0.5s ease-in-out;
            /* Updated transition */
        }

        .smooth_bu {
            position: relative;
            transform: translateY(0);
            transition: transform 0.5s ease-in-out;
            /* Updated transition */
        }

        /* ==================================
    SECTION 2 DESIGN
================================== */
        .section_design2 {
            background: #fff;
            position: relative;
            z-index: 3;

            .statsShadow {
                // box-shadow: -3px -3px 6px 0 rgba(191,195,207,.29);
                padding: 50px 0;
                background: $background_color_2;

                @media (max-width: 510px) {
                    justify-content: center;
                    gap: 0px;
                    padding: 30px 18px;
                }

                .icon_blip {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .sml_img {
                        width: 47px;

                        @media (max-width: 510px) {
                            // width: 37px;
                            display: none;
                        }
                    }

                    .sml_img_mob {
                        display: none;

                        @media (max-width: 510px) {
                            width: 37px;
                            display: block;
                        }
                    }

                    p {
                        font-size: 15px;
                        margin: 0;
                        padding-left: 10px;
                        font-weight: 600;
                        color: $color_1;

                        @media (max-width: 510px) {
                            font-size: 11px;
                            padding-left: 0px;
                        }
                    }
                }
            }

            //statsShadow
        }

        //section_design2
        /*--------------------------------------------------------------
10. accordion
----------------------------------------------------------------*/
        .faq_area {
            padding: 50px 0;
            background: #262626;
            color: black;

            h2 {
                font-weight: 600;
                color: $color_2;
                text-align: center;
                margin-bottom: 15px;
            }

            .accordion {
                width: 100%;

                h2 {
                    font-size: 26px;

                    @media screen and (max-width: 768px) {
                        font-size: 22px;
                    }
                }

                .accordion-item {
                    margin-bottom: 10px;
                    border-radius: 7px;

                    input[type="checkbox"] {
                        display: none;
                    }

                    h2 {
                        color: $color_1;
                        font-weight: 600;
                        margin: 0;
                        font-size: 16px !important;

                        .accordion-button {
                            background: $background_color_2;
                            border-radius: 7px;
                            font-weight: 600;
                            font-size: 16px !important;
                            color: #1f1f1f;
                            outline: none;
                            box-shadow: none;

                            &::after {
                                filter: brightness(0) invert(0);
                            }
                        }
                    }
                }

                .accordion-body {
                    background: #262626;
                    border-radius: 7px;
                    color: rgb(255, 255, 255);
                    border: 1px solid #eee;
                    font-size: 14px;

                }

                .content {
                    width: 100%;
                    transition: all 0.3s ease;
                    opacity: 0;
                    pointer-events: none;
                    padding: 8px 16px;
                    position: relative;
                    z-index: 1;
                    padding-left: 20px;
                    max-height: 0;
                    margin-top: -38px;
                    color: #717171;
                    padding: 25px 25px 25px 25px;
                    border: 1px solid #eee;
                    font-size: 16px !important;

                    &.show {
                        margin: 0px 0 13px;
                        max-height: fit-content;
                        opacity: 1;
                        pointer-events: all;
                        background: #fff;
                    }

                    p {
                        margin-bottom: 8px;
                        color: gray;
                    }
                }
            }
        }

        ////////////////////// TOP STEPS /////////////////////
        .haveyouever {
            padding: 60px 0;
            background-color: #e5eaf1;
            background-image: url(../img/bg_texture.png);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;

            h3 {
                color: $background_color_2;
                font-weight: bold;
                font-size: 24px;
                margin-bottom: 15px;
            }

            a {
                text-decoration: none !important;
            }

            h4 {
                color: $background_color_2;
                font-weight: bold;
                font-size: 18px;
                margin-bottom: 15px;
            }

            .experience {
                position: absolute;
                left: 50px;
                bottom: 100px;
                min-width: 220px;
                background: $background_color_2;
                padding: 15px 30px 25px;
                border-radius: 10px 10px 0 10px;
                -webkit-box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
                box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
                color: #fff;

                &::before {
                    position: absolute;
                    right: -20px;
                    bottom: 0;
                    border-left: 20px solid $background_color_2;
                    ;
                    border-top: 20px solid transparent;
                    content: "";
                }
            }

            .bounce-y {
                -webkit-animation: bounce-y 10s infinite linear;
                animation: bounce-y 10s infinite linear;
            }

            @keyframes bounce-y {
                0% {
                    -webkit-transform: translateY(0);
                    transform: translateY(0)
                }

                50% {
                    -webkit-transform: translateY(-30px);
                    transform: translateY(-30px)
                }

                100% {
                    -webkit-transform: translateY(0);
                    transform: translateY(0)
                }
            }
        }

        // have you ever
        .steps {
            position: absolute;
            top: 0;
            right: 7.5%;
            width: 150px;

            @media only screen and (min-width: 960px) and (max-width: 1200px) {
                width: auto;
                top: 20px;
                background: #FFFFFF;
                padding: 2.5px 10px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 5px;
                border: 2px solid #0A0A0A;
            }

            @media only screen and (min-width: 768px) and (max-width: 960px) {
                width: auto;
                top: 20px;
                background: #FFFFFF;
                padding: 2.5px 10px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 5px;
                border: 2px solid #0A0A0A;
            }

            @media only screen and (min-width: 480px) and (max-width: 768px) {
                width: auto;
                top: 20px;
                background: #FFFFFF;
                padding: 2.5px 10px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 5px;
                border: 2px solid #0A0A0A;
            }

            @media only screen and (max-width: 510px) {
                width: auto;
                top: -55px;
                background: #FFFFFF;
                padding: 2.5px 10px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 5px;
                border: 2px solid #0A0A0A;
            }

            img {
                width: 100%;
                display: block;

                @media only screen and (min-width: 280px) and (max-width: 1200px) {
                    display: none;
                }
            }

            // steps img
            .step-number {
                position: absolute;
                font-size: 22px;
                top: 47px;
                left: 70px;
                transform: translate(-50%, -50%);
                font-weight: 700;
                padding: 5px 0 0 0;

                @media only screen and (min-width: 960px) and (max-width: 1200px) {
                    position: relative;
                    left: 0;
                    top: 0;
                    transform: none;
                    padding: 0;
                }

                @media only screen and (min-width: 768px) and (max-width: 960px) {
                    position: relative;
                    left: 0;
                    top: 0;
                    transform: none;
                    padding: 0;
                }

                @media only screen and (min-width: 480px) and (max-width: 768px) {
                    position: relative;
                    left: 0;
                    top: 0;
                    transform: none;
                    padding: 0;
                }

                @media only screen and (max-width: 480px) {
                    position: relative;
                    left: 0;
                    top: 0;
                    transform: none;
                    padding: 0;
                }

                .figure-large {
                    display: inline-block;
                    vertical-align: middle;
                }

                //figure-large
                .figure-small {
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 60%;
                    letter-spacing: 0;
                }

                //figure-small
            }

            //step-number
        }

        //steps
        /* ==================================
    FOOTER
================================== */
        .footer {
            padding: 25px 0;
            background: #1B1B1B;
            color: #999;

            .ftr_cntnt {
                padding: 30px 0 15px;
                border-bottom: 1px solid #343434 !important;
            }

            p {
                color: #999999 !important;
                font-size: 14px;
                text-align: left;

                @media screen and (max-width: 767px) {
                    margin: 7px 0 20px;
                    padding: 0;
                    font-size: 13px;
                    color: #fff;
                    font-weight: 300;
                    text-align: center;
                }
            }

            ul {
                padding: 0;
                margin-top: 15px;

                @media screen and (max-width: 767px) {

                    padding: 0;
                    text-align: center;
                }

                li {
                    list-style: none;
                    border-right: 1px solid #343434;
                    padding: 0 10px;
                    display: inline;
                    color: #999999 !important;

                    @media screen and (max-width: 767px) {
                        display: block;
                        border-right: 0;

                    }

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        border: 0px;
                    }

                    a {
                        font-size: 14px;
                        text-decoration: none;
                        color: #999999 !important;
                        cursor: pointer;

                        @media screen and (max-width: 767px) {
                            font-size: 13px;
                        }
                    }
                }
            }
        }

        //footer
        ///////////////////////////////// coustom button /////////////////
        /* --------------------------------------------
BUTTON DESIGN
------------------------------------------ */
        .box_button {
            @media (max-width: 510px) {
                // padding: 10px 30px;
            }

            .wb_5 {
                width: 50% !important;

                @media (max-width: 510px) {
                    width: 100% !important;
                }
            }

            .yes_btn {
                width: 70% !important;

                @media (max-width: 510px) {
                    width: 100% !important;
                }
            }

            .button {
                background-image: url(../img/right-chevron.svg) !important;
                background-size: 30% 40px !important;
                background-repeat: no-repeat !important;
                background-position: right !important;
                display: inline-block;
                background: $background_color_2;
                color: black;
                padding: 12px 30px;
                border-radius: 5px;
                border: 0;
                font-size: 20px;
                width: 50%;
                font-weight: 600;
                box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.4);
                cursor: pointer;
                transition: all ease-in-out 300ms;

                @media(min-width: 1200px) {
                    width: 45%;
                }

                @media (min-width: 980px) {
                    width: 50%;
                }

                @media (max-width: 780px) {
                    width: 60%;
                }

                @media (max-width: 510px) {
                    width: 100%;
                }

                &:hover {
                    box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
                    transform: translate(0px, -10px) scale(1.1);
                    -webkit-transform: translate(0px, -10px) scale(1.1);
                    -moz-transform: translate(0px, -10px) scale(1.1);
                    -ms-transform: translate(0px, -10px) scale(1.1);
                    -o-transform: translate(0px, -10px) scale(1.1);
                }
            }
        }

        //box_button
        .img_float {
            display: flex;
            align-items: flex-end;
            justify-content: center;

            .cs_hero_img {
                position: relative;
                z-index: 1;
                height: max-content;
                max-width: 100%;

                @media only screen and (max-width: 1400px) and (min-width: 993px) {
                    bottom: 0;
                }

                @media (max-width: 991px) {
                    position: relative;
                    margin-top: 20px;
                    max-width: 87%;
                }

                @media (max-width: 780px) {
                    max-width: 85%;
                }

                @media (max-width: 510px) {
                    // display: none;
                }
            }

            //cs_hero_img
        }

        //img_float
        .loading-step,
        .loading-page {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 7;
            display: none;

            .contents {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 5;
                width: 100%;
                padding: 0 30px;

                .loading-message {
                    margin: 0px 0 25px 0;
                    display: block;
                    clear: both;
                    float: left;
                    width: 100%;
                    text-align: center;

                    .text-break-all {
                        white-space: nowrap;

                        .text-reg {
                            background: #FFF41F;
                            font-weight: 700;
                            display: inline;
                            padding: 2px 7px 2px 22px;
                            -webkit-border-radius: 2.5px;
                            -moz-border-radius: 2.5px;
                            border-radius: 2.5px;
                            border: 1px solid #0A0A0A;
                            line-height: 100%;
                            text-transform: uppercase;
                        }

                        .margin-none {
                            margin: 0 !important;
                        }
                    }
                }

                .loading-spinner {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 6;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 75px;
                        height: 75px;
                        margin-top: -37.5px;
                        margin-left: -37.5px;
                        border-radius: 50%;
                        border-top: 4px solid #0267C1;
                        border-right: 4px solid transparent;
                        animation: animation-loading 0.50s linear infinite;
                        -webkit-animation: animation-loading 0.50s linear infinite;
                    }
                }

                @keyframes animation-loading {
                    0% {
                        transform: rotate(0deg);
                    }

                    100% {
                        transform: rotate(360deg);
                    }
                }

                .loading-spinner.inline {
                    position: relative;
                    top: 0;
                    left: 0;
                    transform: none;
                    clear: both;
                    display: block;
                    width: 75px;
                    height: 75px;
                    margin: 0 auto;

                    &:before {
                        width: 75px;
                        height: 75px;
                        margin-top: -37.5px;
                        margin-left: -37.5px;
                    }
                }
            }

            .overlay {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: #F5F5F5;
                opacity: 0.90;
            }
        }

        //  ========   blink animation   ==========
        @keyframes blink2 {
            0% {
                transform: scale3d(1.2, 1.2, 1.2);
                opacity: 0.8;
            }

            100% {
                transform: scale3d(1.8, 1.8, 1.8);
                opacity: 0.1;
            }
        }

        @keyframes blink {
            0% {
                transform: scale3d(0.8, 0.8, 0.8);
                opacity: 0.8;
            }

            100% {
                transform: scale3d(1.6, 1.6, 1.6);
                opacity: 0.1;
            }
        }

        @keyframes blink3 {
            0% {
                transform: scale3d(0.6, 0.6, 0.6);
                opacity: 0.8;
            }

            100% {
                transform: scale3d(1.6, 1.6, 1.6);
                opacity: 0.1;
            }
        }

        // =========================================
        // ************* MODAL DEISGN **************
        // =========================================
        .modal {
            background: rgba(0, 0, 0, 0.86);
            // padding-right: 0 !important;

            .modal-content {
                display: flex;
                border-radius: 20px 20px 20px 20px;
                box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
                padding: 10%;
                background-image: url(../img/bg8588.png);
                background-position: center bottom;

                background-size: contain;
                min-height: 60vh;

                .modal-header {
                    border: 0;
                    justify-content: center;

                    .modal-title {
                        img {
                            width: 220px;
                        }
                    }
                }

                .modal-body {
                    padding: 10px 0;

                    .return_back {
                        font-size: 10px;
                        font-weight: 700;

                        p {
                            display: flex;
                            display: flex;
                            justify-content: space-between;
                        }

                        span {
                            text-decoration: underline;
                        }
                    }

                    // display: flex;
                    // align-items: flex-start;
                    // justify-content: center;
                    .cont_flex {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;

                        .gif_img {
                            width: 130px
                        }

                        p {
                            font-size: 22px;
                            font-weight: 600;
                            margin: 0;

                            @media (max-width: 510px) {
                                font-size: 20px;
                            }
                        }

                        .mobi_cut {
                            color: #004d8b;
                        }
                    }

                    .enter_postcode_mod {
                        padding-top: 10px;
                        text-align: start;

                        .png_t {
                            font-size: 19px;
                            font-weight: 600;
                        }

                        label {
                            font-size: 12px;
                            font-weight: 700;
                        }

                        //label
                        .postcode_wrap {
                            display: flex;
                            flex-wrap: wrap;
                            width: 100%;

                            .form-control {
                                border-radius: 5px;
                                padding: 15px;
                                margin-bottom: 15px;
                                width: 100%;
                                font-size: 13px;
                                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                                @media(max-width: 510px) {
                                    border: 1px solid #a3a3a3;
                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                                }

                                &::placeholder {
                                    color: $color_5;
                                }

                                &:focus {
                                    border-color: $background_color_3;
                                    outline: 0;
                                    box-shadow: $box-shadow1;
                                }

                                //form-control
                            }

                            //form-control
                            .button {
                                width: 100%;
                                outline: none;
                                border: 0;
                                background: none;
                                padding: 13px 0;
                                font-size: 15px;
                                font-weight: 700;
                                position: relative;
                                display: block;
                                border-radius: 5px;
                            }

                            //button
                            .bg_mod_click {
                                background: #4285f4;
                                color: $background_color_1;
                                transition: cubic-bezier(0.075, 0.82, 0.165, 1);

                                &:hover {
                                    background: $background_color_5;
                                }
                            }

                            //bg_add_click
                        }

                        //postcode_wrap
                        .tiny_note {
                            font-size: 11px;
                            color: #979797;
                            margin-top: 3px;
                        }

                        //tiny_note
                        #selcetMod {
                            display: none;

                            #ModSelect {
                                position: relative;
                                top: 0;
                            }

                            //addSelect
                            .form-select {
                                border-radius: 5px;
                                font-size: 13px;
                                padding: 13px 10px;
                                width: 100%;
                                margin-bottom: 15px;
                                color: $color_2;
                                border: 1px solid #a3a3a3;
                                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                                &:focus {
                                    border-color: $background_color_3;
                                    outline: 0;
                                    box-shadow: $box-shadow1;
                                }

                                &place_dummi {
                                    color: $color_5;
                                }
                            }

                            //form-select
                            .Mod_detail {
                                text-transform: uppercase;
                                background: #d7ecffb0;
                                color: $color_2;
                                display: none;
                                margin: 20px 0;
                                text-align: left;
                                line-height: 140%;

                                .ADD_na_top {
                                    font-size: 13px;
                                    font-weight: 700;
                                    margin-bottom: 5px;
                                }

                                //ADD_na_top
                                .ADD_clr_typ {
                                    font-size: 12px;
                                    margin-bottom: 0;
                                    font-weight: 700;
                                }

                                //ADD_clr_typ
                            }

                            //ADD_detail
                            .return_back {
                                font-size: 10px;
                                font-weight: 700;

                                p {
                                    display: flex;
                                    display: flex;
                                    justify-content: space-between;
                                }

                                span {
                                    text-decoration: underline;
                                }
                            }

                            ///return_back
                        }

                        //selcetAdd
                    }

                    //enter_postcode_mod
                    @media (max-width: 510px) {
                        .enter_postcode_mod {
                            .postcode_wrap {
                                display: flex !important;
                                flex-wrap: wrap;
                                width: 100% !important;

                                .form-control {
                                    border-radius: 5px;
                                    margin-bottom: 15px;
                                    width: 100% !important;
                                }

                                //form-control
                                .button {
                                    width: 100% !important;
                                    border-radius: 5px;
                                }

                                //button
                            }

                            //postcode_wrap
                        }

                        //enter_postcode_add
                    }

                    .jst_adDf_lx {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .slide4 {
                            background: transparent;
                            padding: 0px;

                            .cicle_loader {
                                padding: 20px 0 10px;
                                background: transparent;
                                margin: 0 auto;
                                border-radius: 12px;
                                text-align: center;

                                .loader_gif_ {
                                    .lp {
                                        width: 4em;
                                        height: 6em;
                                    }

                                    .lp__drops,
                                    .lp__fall-line,
                                    .lp__worm {
                                        animation-duration: 4s;
                                        animation-iteration-count: infinite;
                                        transform-origin: 64px 64px;
                                    }

                                    .lp__drops {
                                        animation-name: drops;
                                        animation-timing-function: ease-in-out;
                                        stroke-dasharray: 22 307.85 22;
                                        visibility: hidden;
                                        transform: rotate(90deg);
                                    }

                                    .lp__fall-line {
                                        animation-name: fallLine1;
                                        animation-timing-function: ease-in;
                                        stroke-dasharray: 1 112;
                                        stroke-dashoffset: 114;
                                    }

                                    .lp__fall-line--delay1 {
                                        animation-name: fallLine2;
                                    }

                                    .lp__fall-line--delay2 {
                                        animation-name: fallLine3;
                                    }

                                    .lp__fall-line--delay3 {
                                        animation-name: fallLine4;
                                    }

                                    .lp__fall-line--delay4 {
                                        animation-name: fallLine5;
                                    }

                                    .lp__ring {
                                        stroke: rgba(1, 8, 24, 0.1);
                                        transition: stroke 0.3s;
                                    }

                                    .lp__worm {
                                        animation-name: worm;
                                        stroke-dasharray: 43.98 307.87;
                                        stroke-dashoffset: -131.94;
                                        transform: rotate(-90deg);
                                    }

                                    /* Animtions */
                                    @keyframes drops {
                                        from {
                                            animation-timing-function: steps(1, end);
                                            stroke-dasharray: 0 351.85 0;
                                            visibility: hidden;
                                        }

                                        25% {
                                            animation-timing-function: ease-in-out;
                                            stroke-dasharray: 0 351.85 0;
                                            visibility: visible;
                                        }

                                        26% {
                                            stroke-dasharray: 4 343.85 4;
                                        }

                                        27% {
                                            stroke-dasharray: 8 335.85 8
                                        }

                                        28% {
                                            stroke-dasharray: 12 327.85 12;
                                        }

                                        29% {
                                            animation-timing-function: ease-in;
                                            stroke-dasharray: 17 317.85 17;
                                        }

                                        32% {
                                            animation-timing-function: ease-out;
                                            stroke-dasharray: 25 301.85 25;
                                        }

                                        35%,
                                        47.5% {
                                            animation-timing-function: linear;
                                            stroke-dasharray: 22 307.85 22;
                                            visibility: visible;
                                        }

                                        50% {
                                            animation-timing-function: steps(1, end);
                                            stroke-dasharray: 0 307.85 43.98;
                                            visibility: hidden;
                                        }

                                        75% {
                                            animation-timing-function: ease-in-out;
                                            stroke-dasharray: 0 351.85 0;
                                            visibility: visible;
                                        }

                                        76% {
                                            stroke-dasharray: 4 343.85 4;
                                        }

                                        77% {
                                            stroke-dasharray: 8 335.85 8
                                        }

                                        78% {
                                            stroke-dasharray: 12 327.85 12;
                                        }

                                        79% {
                                            animation-timing-function: ease-in;
                                            stroke-dasharray: 17 317.85 17;
                                        }

                                        82% {
                                            animation-timing-function: ease-out;
                                            stroke-dasharray: 25 301.85 25;
                                        }

                                        85%,
                                        97.5% {
                                            animation-timing-function: linear;
                                            stroke-dasharray: 22 307.85 22;
                                            visibility: visible;
                                        }

                                        to {
                                            stroke-dasharray: 43.98 307.85 0;
                                            visibility: hidden;
                                        }
                                    }

                                    @keyframes fallLine1 {

                                        from,
                                        15% {
                                            stroke-dashoffset: 114;
                                        }

                                        25%,
                                        65% {
                                            stroke-dashoffset: 1;
                                        }

                                        75%,
                                        to {
                                            stroke-dashoffset: -112;
                                        }
                                    }

                                    @keyframes fallLine2 {

                                        from,
                                        16% {
                                            stroke-dashoffset: 114;
                                        }

                                        26%,
                                        66% {
                                            stroke-dashoffset: 1;
                                        }

                                        76%,
                                        to {
                                            stroke-dashoffset: -112;
                                        }
                                    }

                                    @keyframes fallLine3 {

                                        from,
                                        17% {
                                            stroke-dashoffset: 114;
                                        }

                                        27%,
                                        67% {
                                            stroke-dashoffset: 1;
                                        }

                                        77%,
                                        to {
                                            stroke-dashoffset: -112;
                                        }
                                    }

                                    @keyframes fallLine4 {

                                        from,
                                        18% {
                                            stroke-dashoffset: 114;
                                        }

                                        28%,
                                        68% {
                                            stroke-dashoffset: 1;
                                        }

                                        78%,
                                        to {
                                            stroke-dashoffset: -112;
                                        }
                                    }

                                    @keyframes fallLine5 {

                                        from,
                                        19% {
                                            stroke-dashoffset: 114;
                                        }

                                        29%,
                                        69% {
                                            stroke-dashoffset: 1;
                                        }

                                        79%,
                                        to {
                                            stroke-dashoffset: -112;
                                        }
                                    }

                                    @keyframes worm {
                                        from {
                                            animation-timing-function: ease-out;
                                            stroke-dasharray: 87.96 307.87;
                                            stroke-dashoffset: -131.94;
                                        }

                                        25% {
                                            animation-timing-function: steps(1);
                                            stroke-dasharray: 87.96 307.87;
                                            stroke-dashoffset: -307.86;
                                        }

                                        25.01% {
                                            animation-timing-function: ease-in;
                                            stroke-dasharray: 43.98 307.87;
                                            stroke-dashoffset: -307.86;
                                        }

                                        50% {
                                            animation-timing-function: steps(1);
                                            stroke-dasharray: 43.98 307.87;
                                            stroke-dashoffset: -175.92;
                                        }

                                        50.01% {
                                            animation-timing-function: ease-out;
                                            stroke-dasharray: 87.96 307.87;
                                            stroke-dashoffset: -131.94;
                                        }

                                        75% {
                                            animation-timing-function: steps(1);
                                            stroke-dasharray: 87.96 307.87;
                                            stroke-dashoffset: 43.98;
                                        }

                                        75.01% {
                                            animation-timing-function: ease-in;
                                            stroke-dasharray: 43.98 307.87;
                                            stroke-dashoffset: 0;
                                        }

                                        to {
                                            stroke-dasharray: 43.98 307.87;
                                            stroke-dashoffset: -131.94;
                                        }
                                    }
                                }

                                //loader_gif_
                                .text12 {
                                    .txt {
                                        font-size: 17px;
                                        color: #b18924;
                                        font-weight: 700;

                                        .text-reg {
                                            background: #FFF41F;
                                            font-weight: 800;
                                            display: inline;
                                            padding: 2px 7px 2px 22px;
                                            -webkit-border-radius: 2.5px;
                                            -moz-border-radius: 2.5px;
                                            border-radius: 2.5px;
                                            border: 1px solid #0A0A0A;
                                            line-height: 100%;
                                            text-transform: uppercase;
                                            position: relative;

                                            &:before {
                                                position: absolute;
                                                left: 5px;
                                                top: 5px;
                                                color: #0A0A0A;
                                                background: #0267C1 url(../img/gb.svg) no-repeat center center;
                                                -webkit-background-size: 4px 7px;
                                                -moz-background-size: 4px 7px;
                                                -o-background-size: 4px 7px;
                                                background-size: 4px 7px;
                                                border: 1px solid #FFFFFF;
                                                -webkit-box-shadow: 0px 0px 0px 1px #0267C1;
                                                -moz-box-shadow: 0px 0px 0px 1px #0267C1;
                                                box-shadow: 0px 0px 0px 1px #0267C1;
                                                width: 10px;
                                                height: 17px;
                                                content: '';
                                                z-index: 6;
                                                -webkit-border-radius: 2.5px;
                                                -moz-border-radius: 2.5px;
                                                border-radius: 2.5px;
                                            }
                                        }
                                    }
                                }

                                //text12
                            }

                            //cicle_loader
                        }

                        //slide4
                    }

                    //jst_adDf_lx
                    .form_drop {
                        .png_t {
                            font-size: 19px;
                            font-weight: 600;
                        }

                        .choose_Y_N {
                            display: flex;
                            width: 100%;
                            gap: 8px;

                            // margin-bottom: 30px;
                            @media (max-width: 510px) {
                                gap: 15px;
                            }

                            /////////////////////////// form check /////////////////////////////////
                            .form-check {

                                // width: 50%;
                                @media (max-width: 510px) {
                                    width: 100% !important;
                                }

                                [type="radio"] {
                                    display: none;
                                }

                                // & + .form-check {
                                // 	width: 50%;
                                // }
                                label {
                                    display: block;
                                    padding: 13px 60px;
                                    //background: #fff;
                                    border: 1px solid #a3a3a3;
                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                                    border-radius: 5px;
                                    cursor: pointer;
                                    font-size: 16px;
                                    font-weight: 500;
                                    //min-width: 195px;
                                    color: black;
                                    white-space: nowrap;
                                    position: relative;
                                    transition: 0.4s ease-in-out 0s;

                                    &:after {
                                        height: 19px;
                                        width: 19px;
                                        border: 1px solid #977419;
                                        left: 19px;
                                        top: calc(50% - 10px);
                                    }

                                    &:before {
                                        background: #004d8b;
                                        height: 11px;
                                        width: 11px;
                                        left: 23px;
                                        top: calc(50% - 6px);
                                        transform: scale(5);
                                        opacity: 0;
                                        visibility: hidden;
                                        transition: 0.4s ease-in-out 0s;
                                    }
                                }

                                [type="radio"]:checked {
                                    &~label {
                                        border-color: #004d8b;
                                        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                                        background: #4285f4;
                                        color: #ffffff;

                                        &::before {
                                            background: #fff;
                                            opacity: 1;
                                            visibility: visible;
                                            transform: scale(1);
                                        }

                                        &::after {
                                            border: 2px solid #fff;
                                        }
                                    }
                                }
                            }

                            .form-check label:after,
                            .form-check label:before {
                                content: "";
                                position: absolute;
                                border-radius: 50%;
                            }

                            ///////////////////////// form check /////////////////////////////////
                        }

                        //choose_bw
                        .sty_pop {
                            font-size: 18px;
                            font-weight: 600;
                        }

                        .check_email_add {
                            padding-top: 10px;
                            text-align: left;

                            label {
                                font-size: 12px;
                                font-weight: 700;
                            }

                            //label
                            .input-group-text {
                                border-right: 0;
                                border-radius: 0;
                                background: $background_color_1;
                            }

                            .secure:after {
                                position: absolute;
                                top: 50%;
                                right: 20px;
                                width: 61px;
                                height: 25px;
                                transform: translateY(-50%);
                                background: url(../img/secure-hybrid.png) no-repeat center center;
                                -webkit-background-size: contain;
                                -moz-background-size: contain;
                                -o-background-size: contain;
                                background-size: contain;
                                content: '';
                            }

                            .form-control {
                                border-radius: 5px;
                                padding: 15px 15px;
                                font-size: 13px;
                                border: 1px solid #a3a3a3;
                                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                                &::placeholder {
                                    color: $color_5;
                                }

                                &:focus {
                                    border-color: $background_color_3;
                                    outline: 0;
                                    box-shadow: $box-shadow1;
                                }

                                //form-control
                            }

                            //form-control
                            .tiny_note {
                                font-size: 11px;
                                color: #474747;
                                margin-top: 3px;
                            }

                            //tiny_note
                        }

                        //check_email_add
                        .phone_deatail {
                            text-align: start;

                            .user_no {
                                label {
                                    font-size: 12px;
                                    font-weight: 700;
                                }

                                //label
                                .input-group-text {
                                    border-right: 0;
                                    border-radius: 0;
                                    background: $background_color_1;
                                }

                                .secure:after {
                                    position: absolute;
                                    top: 50%;
                                    right: 20px;
                                    width: 61px;
                                    height: 25px;
                                    transform: translateY(-50%);
                                    background: url(../img/secure-hybrid.png) no-repeat center center;
                                    -webkit-background-size: contain;
                                    -moz-background-size: contain;
                                    -o-background-size: contain;
                                    background-size: contain;
                                    content: '';
                                }

                                .form-control {
                                    border-radius: 5px;
                                    padding: 15px 15px;
                                    font-size: 13px;
                                    border: 1px solid #a3a3a3;
                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                                    &::placeholder {
                                        color: $color_5;
                                    }

                                    &:focus {
                                        border-color: $background_color_3;
                                        outline: 0;
                                        box-shadow: $box-shadow1;
                                    }

                                    //form-control
                                }

                                //form-control
                            }

                            //user_no
                            .tiny_note {
                                font-size: 11px;
                                color: #474747;
                                margin-top: 3px;
                            }

                            //tiny_note
                        }

                        //phone_deatail
                        label[for="format2"] {
                            font-size: 12px;
                            font-weight: 700;
                        }

                        .select_title {
                            position: relative;
                            display: flex;
                            width: 100%;
                            line-height: 3;
                            overflow: hidden;
                            border-radius: 5px;
                            border: 1px solid #a3a3a3;
                            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                            @media(max-width: 510px) {
                                width: 100%;
                            }

                            &::after {
                                content: '\25BC';
                                position: absolute;
                                top: 0;
                                right: 0;
                                padding: 0 1em;
                                background: $background_color_2;
                                color: #ffffff;
                                cursor: pointer;
                                height: 100%;
                                pointer-events: none;
                                transition: .25s all ease;
                                display: flex;
                                align-items: center;
                            }

                            &:hover::after {
                                color: #ffffff;
                            }

                            select {
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                -ms-appearance: none;
                                appearance: none;
                                outline: 0;
                                box-shadow: none;
                                border: 0 !important;
                                background: #ffffff;
                                background-image: none;
                                flex: 1;
                                height: auto;
                                padding: 15px 15px;
                                color: #7f7f7f;
                                cursor: pointer;
                                font-size: 13px;

                                &::-ms-expand {
                                    display: none;
                                }

                                @media (max-width: 510px) {
                                    font-size: 13px;
                                }
                            }

                            //select
                        }

                        //select_title
                        .name_detail {
                            padding-top: 20px;
                            text-align: left;

                            @media (max-width: 510px) {
                                padding-top: 10px;
                            }

                            .f_name {
                                margin-bottom: 10px;

                                label {
                                    font-size: 12px;
                                    font-weight: 700;
                                }

                                .form-control {
                                    border-radius: 5px;
                                    padding: 15px 15px;
                                    font-size: 13px;
                                    border: 1px solid #a3a3a3;
                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                                    &::placeholder {
                                        color: $color_5;
                                    }

                                    &:focus {
                                        border-color: #18478b;
                                        outline: 0;
                                        box-shadow: $box-shadow1;
                                    }
                                }
                            }

                            //f_name
                            .m_name {
                                margin-bottom: 10px;

                                label {
                                    font-size: 12px;
                                    font-weight: 700;

                                    span {
                                        font-size: 6px;
                                        color: #8b8b8b;

                                        @media (max-width: 510px) {
                                            font-size: 9px;
                                        }
                                    }
                                }

                                .form-control {
                                    border-radius: 5px;
                                    padding: 15px 15px;
                                    font-size: 13px;
                                    border: 1px solid #a3a3a3;
                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                                    &::placeholder {
                                        color: $color_5;
                                    }

                                    &:focus {
                                        border-color: #18478b;
                                        outline: 0;
                                        box-shadow: $box-shadow1;
                                    }
                                }

                                //form-control
                            }

                            //m_name
                            .l_name {
                                margin-bottom: 10px;

                                label {
                                    font-size: 12px;
                                    font-weight: 700;
                                }

                                .form-control {
                                    border-radius: 5px;
                                    padding: 15px 15px;
                                    font-size: 13px;
                                    border: 1px solid #a3a3a3;
                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                                    &::placeholder {
                                        color: $color_5;
                                    }

                                    &:focus {
                                        border-color: #18478b;
                                        outline: 0;
                                        box-shadow: $box-shadow1;
                                    }
                                }

                                //form-control
                            }

                            //l_name
                            .tiny_note {
                                font-size: 11px;
                                color: #979797;
                                margin-top: 3px;
                            }

                            //tiny_note
                        }

                        // name_detail
                        .Birth_detail {
                            padding-top: 10px;
                            text-align: left;

                            label {
                                font-size: 12px;
                                font-weight: 700;
                            }

                            .date_here {
                                margin-bottom: 10px;

                                label {
                                    font-size: 11px;
                                    font-weight: 400;
                                    color: $color_5;
                                }

                                .form-select {
                                    border-radius: 5px;
                                    padding: 15px;
                                    font-size: 13px;
                                    border: 1px solid #a3a3a3;
                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                                    &::placeholder {
                                        color: $color_5;
                                    }

                                    &:focus {
                                        border-color: #18478b;
                                        outline: 0;
                                        box-shadow: $box-shadow1;
                                    }
                                }
                            }

                            //date_here
                            .month_here {
                                margin-bottom: 10px;

                                label {
                                    font-size: 11px;
                                    font-weight: 400;
                                    color: $color_5;
                                }

                                .form-select {
                                    border-radius: 5px;
                                    padding: 15px;
                                    font-size: 13px;
                                    border: 1px solid #a3a3a3;
                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                                    &::placeholder {
                                        color: $color_5;
                                    }

                                    &:focus {
                                        border-color: #18478b;
                                        outline: 0;
                                        box-shadow: $box-shadow1;
                                    }
                                }
                            }

                            //month_here
                            .year_here {
                                margin-bottom: 10px;

                                label {
                                    font-size: 11px;
                                    font-weight: 400;
                                    color: $color_5;
                                }

                                .form-select {
                                    border-radius: 5px;
                                    padding: 15px;
                                    font-size: 13px;
                                    border: 1px solid #a3a3a3;
                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                                    &::placeholder {
                                        color: $color_5;
                                    }

                                    &:focus {
                                        border-color: #18478b;
                                        outline: 0;
                                        box-shadow: $box-shadow1;
                                    }
                                }
                            }

                            //year_here
                            .tiny_note {
                                font-size: 11px;
                                color: #979797;
                                margin-top: 3px;
                            }

                            //tiny_note
                        }

                        // Birth_detail
                    }

                    //form_drop
                }

                .modal-footer {
                    display: block;
                    flex-shrink: 0;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: start;
                    padding: 0;
                    border-top: 0;
                }
            }

            .modalContent_sml {
                min-height: initial;
            }
        }
    }

    // ================= keep it outside ==========
    .modal-backdrop {
        z-index: 0 !important;
    }

    .footer-modal {
        .modal-body{
            margin: 0px 10px;
        }
        .footermodal{
            background: #fff !important;
            padding: 0px !important;
            border-bottom: 7px solid #f5cb5c;

            p{
                font-size: 14px;
            }
            h4{
                font-size: 16px;
                font-weight: bold;
            }
            ul{
                li{
                    font-size: 14px;
                    padding-bottom: 10px;
                }
            }
            ol{
                li{
                    font-size: 14px;
                    padding-bottom: 10px;
                    ol{
                        @media screen and (max-width: 768px) {
                            padding-left: 15px;
                        }
                    }
                }
                @media screen and (max-width: 768px) {
                    padding-left: 15px;
                }
            }
            table{
                td{
                    font-size: 14px;
                }
            }
            .modal-header{
                padding: 10px 20px;
                border-bottom: 1px solid #ddd !important;
                margin-bottom: 15px;
                background-color: #f5cb5c;
            }
            .modal-footer{
                display: flex !important;
                justify-content: end !important;
                border-top: 1px #ddd solid  !important;
                padding-top: 15px !important;
            }
        }
    }
}

// =================  media Query ==========