
.hendersons_idupload{
	font-family: 'Poppins', sans-serif;

/*
*******************************************
Template Name: Hendersons ID Upload
Author: Abhilash
Date:16-05-2024
Template By: Vandalay Designs
Copyright 2021-2022 Vandalay Business Solution

* This file contains the styling for the Website, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - Questionnaie 

08 - FOOTER 
09 - MODAL 
10 - MEDIA QUERY
11 - ANIMATION
============================================
============================================
*/

$background_color_1:#ffffff;
$background_color_2:#F5CB5C;
$background_color_3:#F5CB5C;
$background_color_4:#F5CB5C;
$background_color_5:#F5CB5C;
$background_color_6:#f9fbfc;
$background_color_7:#efefef;
$background_color_8:#f2f4f5;
$background_color_9:#cfcfcf;

$color_1:#F5CB5C;
$color_2:#F5CB5C;
$color_3: #ffff;
$color_4:#F5CB5C;
$color_5:#272727;
$font-normal:normal;


// ======border===========
$box-shadow1: 0 0 0 0.2rem #ffe95c9d;


$border-style2:4px solid #23df6a;
$border-style3:2px solid #d5d5d5; 
$border-style4:1px solid #b4b4b4;
$border-style5:1px solid #2f3996;


/*================================================
	BODY STYLES
==================================================*/
*, ::after, ::before {
    box-sizing: border-box;
}

*{
	outline: none;
}

html {
	scroll-behavior: smooth;
}
a{
	cursor: pointer;
	text-decoration: none !important;
	color: unset !important;
}

em {
    font-style: normal; 
}


body {
	margin: 0;
	padding: 0;
	min-height: 100vh;
	background:#2e2d2d ;
	background-size: cover;
}
	//header design
	header{
		// position: absolute;
		// width: 100%;
		// z-index: 2;
		// transition: .4s all;
		// border-bottom: 1px solid rgba(255, 255, 255, .2);
		@media screen and (max-width: 992px) {
			padding-top: 15px;

		}
		.topnav {
			overflow: hidden;
			background-color: #000;
			width: 100%;
			margin: auto;
			box-shadow: 0 6px 16px -6px rgba(1, 1, 1, .7);
			transition: .5s;
			padding: 5px 10px;

			.logo {
				width: 199px;
				border-radius: 5px;
				padding: 14px 16px;
			}
		}
	
	}//header


	// @media (min-width: 576px) {
	// 	.container{
	// 		max-width: 100% !important;
	// 	}
	// }

	// pre loader design 
	#preloader {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgb(31, 31, 31);
		z-index: 999999;
		#loader {
			display: block;
			position: relative;
			left: 50%;
			top: 50%;
			width: 150px;
			height: 150px;
			margin: -75px 0 0 -75px;
			border-radius: 50%;
			border: 3px solid transparent;
			border-top-color: #F5CB5C;
			-webkit-animation: spin 2s linear infinite;
			animation: spin 2s linear infinite;
			&:before {
				content: "";
				position: absolute;
				top: 5px;
				left: 5px;
				right: 5px;
				bottom: 5px;
				border-radius: 50%;
				border: 3px solid transparent;
				border-top-color: #505050;
				-webkit-animation: spin 3s linear infinite;
				animation: spin 3s linear infinite;
			}
			&:after {
				content: "";
				position: absolute;
				top: 15px;
				left: 15px;
				right: 15px;
				bottom: 15px;
				border-radius: 50%;
				border: 3px solid transparent;
				border-top-color: #F5CB5C;
				-webkit-animation: spin 1.5s linear infinite;
				animation: spin 1.5s linear infinite;
			}
		}//loader
		@keyframes spin {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}//@keyframes spin
	}//preloader


.upload_sec{
	position: relative;
    padding-top: 70px;
    background: url(../../PCP_HDSC_V1/img/pattern-2.jpg) no-repeat 50%;
    background-size: cover;
	@media screen and (max-width: 768px) {
		padding-top: 70px !important; 
	}
	h2{
		font-weight: 600;
		text-align: center;
		font-size: 26px;
		line-height: 145%;
		color: #fff;
		margin: 30px 10px;
	}
	h3{
		font-size: 16px;
		font-weight: 300;
		line-height: 24px;
		color: #fff;
		margin-bottom: 25px;
		padding: 0 5px;
		text-align: center;
	}
	h4{
		font-size: 18px;
		font-weight: 600;
		line-height: 24px;
		color: #fff;
		margin-bottom:5px;
		padding: 0 5px;
		text-align: center;
	}
	h5{
		font-size: 14px;
		font-weight: 300;
		line-height: 24px;
		color: #fff;
		margin-bottom: 25px;
		padding: 0 5px;
		text-align: center;
	}

	.upload_but_sec{
		border: 2px solid #f5cb5c;
		border-radius: 10px;
		padding: 15px;
		.uploadbut{
			background:#f5cb5c url(../../PCP_HDSC_V1/img/right-chevron.svg) no-repeat ;
			background-size: 17% 42px !important;
			background-position: 100% !important;
			display: inline-block;
			color: #fff;
			padding: 16px 11px;
			border-radius: 76px;
			border: 0;
			font-size: 20px;
			width: 98%;
			font-weight: 600;
			box-shadow: 0 17px 10px -10px rgba(0, 0, 0, .4);
			margin:0 2% 15px;
			cursor: pointer;
			transition: all .3s ease-in-out;
		}
		}
		.id_view{
				.img-thumbnail{
					background:none;
					border: 1px solid #f5cb5c;
				}
		}
.button-uploadsec{
	position: relative;
    overflow: hidden;
	input[type=file] {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		min-width: 100%;
		min-height: 100%;
		font-size: 100px;
		text-align: right;
		filter: alpha(opacity=0);
		opacity: 0;
		outline: none;   
		display: block;
		cursor: pointer;
	}
}
		.browsebut{
			background:#000;
			cursor: pointer;
			padding: 10px 20px;
			color:#f5cb5c !important ;
			border: solid 2px #f5cb5c;
			border-radius: 7px;
			width: 82%;
			margin: 10px 5px;
			overflow: hidden;
			display: inline-block;
			&:hover{
				// background:#f5cb5c!important;
			}
			@media (max-width: 576px) {
				width: 100%;
			}
		}
		.btn_submit{
			background-color: #23df6a !important;
			width: 82% !important;
			&:hover{
				background-color: #f5cb5c !important;
			}
			@media (max-width: 576px) {
				width: 100% !important;
			}
		}
		
	}




/* ==================================
    FOOTER
================================== */

footer{
    padding: 25px 0;
    background: #262626 !important;
	position: relative;
    .ftr_cntnt {
        padding: 30px 0 15px;
        // border-bottom: 1px solid #343434!important;
    }
    p{
        color: #ffffff!important;
        font-size: 14px;
    
        @media screen and (max-width: 767px) {
            margin: 7px 0 20px;
            padding: 0;
            font-size: 13px;
            color: #fff;
            font-weight: 300;
            text-align: center;
        }
    }
    ul{
        padding: 0;
        margin-top: 15px;
        @media screen and (max-width: 767px){
            text-align: center;
            padding: 0;
        }
        li {
        list-style: none;
        border-right: 1px solid #ffffff!important;
        padding: 0 10px;
        display: inline;
        color: #ffffff!important;
            &:first-child {
                padding-left: 0;
            }
			&:last-child {
				border-right: none !important;
            }
            a {
                font-size: 14px;
                text-decoration: none;
                color: #ffffff!important;
                cursor: pointer;
                @media screen and (max-width: 767px){
                    font-size: 13px;
                }
            }
        }
    }
}//footer


@keyframes pulse-animationhome {
    0% {
      box-shadow: 0 0 0 0px rgba(238, 255, 7, 0.644);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }
  .pulsehome {
    animation: pulse-animationhome 1.5s infinite;
  }
  
.needidproof{
	h4{
		color: #fff;
		font-weight: bold;
		font-size: 16px !important;
		border-top: dashed 1px #f5cb5c;
		padding-top: 16px !important;
		margin-top: 10px;
	}
	p{
		color: #fff;
		font-size: 13px;
		margin-bottom: 5px;
	}
	ul{
		margin-bottom: 5px;
		li{
			color: #fff;
			font-size: 13px;
			padding-bottom: 5px;
		}
	}
}


}//hendersons id upload

